@import 'import.pcss';

:local {
  .singleFeature {
    background: $c-black;
    border-radius: rem(1);
    display: grid;
    width: 100%;
    position: relative;
    @media $toDesktop {
      max-width: 100vw;
    }
    @media $tablet {
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1200px) {
      grid-template-columns: 50% 50%;
    }
    :global {
      .swiper-container {
        overflow: visible;
      }
      .swiperButtonContainerNext,
      .swiperButtonContainerPrevious {
        display: none;
      }
    }
  }
  .featureImageWrap {
    padding: rem(5);
    position: relative;
    @media $tablet {
      padding: rem(8) rem(4) 0 rem(8);
    }
    @media $toDesktop {
      max-width: 100vw;
    }
    @media (min-width: 1200px) {
      padding: rem(8) rem(4) rem(3) rem(8);
      grid-row: span 2;
    }
  }
  .contentWrap {
    position: relative;
    padding: rem(5) rem(5) 0 rem(5);
    display: flex;
    flex-direction: column;
    @media $toDesktop {
      max-width: 100vw;
    }
    @media $tablet {
      padding: rem(8) rem(8) 0 rem(4);
    }
  }
  .button {
    z-index: 2;
    position: absolute;
    right: rem(14);
    top: rem(14);
    height: rem(36);
    width: rem(41);
    clip-path: polygon(47% 0, 100% 0, 100% 100%, 0 100%);
    background: #171717;
    border: none;
    cursor: pointer;

    &.buttonDisappeard {
      display: none;
      -webkit-animation: fadeOut 1s;
      animation: fadeOut 1s;
    }

    .remove {
      position: absolute;
      width: rem(10);

      @media $toMobile {
        width: rem(8);
      }
    }

    &:hover {
      .remove {
        path {
          opacity: 1;
        }
      }
    }

    @media $toTablet {
      display: none;
    }
  }
  .mainContent {
    padding: rem(10) rem(15) rem(20);
    @media $tablet {
      max-width: rem(370);
      padding: 0;
      margin: rem(15) auto rem(10) rem(17);
      grid-row: 1;
      grid-column: 2;
    }
    @media $fromLargeTablet {
      margin: rem(24) auto 0 rem(17);
    }
    @media (min-width: 1200px) {
      margin: rem(48) auto 0 rem(48);
    }

    .mainContentWithLozenge {
      @media $tablet {
        margin: rem(32) auto auto rem(17);
      }
    }
    &.mainContentWatchlist {
      @media (min-width: 1200px) {
        margin: rem(78) auto 0 rem(48);
      }
    }
  }
  .featureChildren {
    padding: rem(5);

    @media $toDesktop {
      max-width: 100vw;
    }

    @media $tablet {
      padding: rem(5) rem(8) rem(8) rem(8);
      grid-row: 2;
      grid-column: span 2;
    }
    @media (min-width: 1200px) {
      grid-row: 2;
      grid-column: 2;
      padding: rem(5) rem(8) rem(8) rem(4);
    }
    .singleVodChild {
      width: calc(100vw - rem(10));

      @media (min-width: 1200px) {
        width: 100%;
      }
    }
    .twinVodChild {
      width: rem(318);
      @media $desktop {
        max-width: none;
        width: calc((100vw - 3*8px - 6px) / 4 - 3px);
      }
      @media (min-width: 1920px) {
        width: rem(474);
      }
    }
    .singleVodChild.twinVodChild {
      width: 100%;
      max-width: calc(100vw - rem(10));
      @media $tablet {
        max-width: 100%;
      }
      @media $desktop {
        width: 100%;
      }
      @media (min-width: 1920px) {
        width: 100%;
      }
    }
  }
  .lozengeMobile {
    position: absolute;
    top: rem(5);
    left: rem(5);
    z-index: 1;
    @media $tablet {
      display: none;
    }
  }
  .lozenge {
    display: none;
    @media $tablet {
      display: block;
      position: absolute;
      top: rem(8);
      left: rem(8);
    }
  }
  .carousel {
    position: relative;
    padding: 0;
    overflow: hidden;
  }

  .singleFeature.twinFeature {
    display: flex;
    width: 100%;
    flex-direction: column;

    .imageBackground {
      display: none;
    }

    @media $tablet {
      width: 50%;

      &:first-child {
        .featureImageWrap {
          padding-right: rem(4);
          .lozengeMobile {
            top: rem(8);
            left: rem(8);
          }
        }
        .featureChildren {
          padding-right: rem(4);
        }
      }
      &:nth-child(2) {
        .featureImageWrap {
          padding: rem(8) rem(8) 0 rem(4);
          .lozengeMobile {
            top: rem(8);
            left: rem(4);
          }
        }
        .featureChildren {
          padding-left: rem(4);
        }
      }

      .mainContent {
        margin: 0 auto;
      }

      .contentWrap {
        flex: auto;
      }
    }

    @media $tablet and (max-width: 1199px) {
      .contentWrap {
        padding: rem(8) rem(33) rem(12);
        &.contentWrapTwinChildren0 {
          padding-bottom: rem(24);
        }
      }
    }

    @media $toDesktop {
      .lozengeMobile {
        display: block;
      }
      .lozenge {
        display: none;
      }
    }

    @media $desktop {
      .contentWrap {
        position: absolute;
        width: 100%;
        height: calc(100% - 117px);
        padding-bottom: 0;
      }
      .mainContent {
        margin: auto auto auto rem(24);
      }
      .featureImageWrap {
        flex: auto;
      }
      &:nth-child(2) {
        .lozenge {
          left: rem(4);
        }
      }
      .imageBackground {
        display: block;
        background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
      .featureChildren {
        padding-top: rem(3);
      }
    }

    @media $fromLargeTablet and (max-width: 1199px) {
      .contentWrapTwinChildren0 {
        height: 100%;
      }
      .contentWrapTwinChildren1 {
        height: calc(100% - 108px);
      }
      .contentWrapTwinChildren2 {
        height: calc(100% - 195px);
      }
    }

    @media (min-width: 1200px) {
      .contentWrap {
        height: calc(100% - 108px);
      }
      &:nth-child(2) {
        .featureImageWrap {
          padding: rem(8) rem(8) 0 rem(4);
        }
        .featureChildren {
          padding-left: rem(4);
        }
      }
      .mainContent {
        margin: auto auto auto rem(48);
      }
      .featureChildren {
        padding-top: rem(0);
        padding-left: rem(8);
      }
      .contentWrapTwinChildren0 {
        height: 100%;
      }
      .contentWrapTwinChildren1,
      .contentWrapTwinChildren2 {
        height: calc(100% - 108px);
      }
    }
  }
}

@-webkit-keyframes fadeOut {
  from { opacity: 1; }
    to { opacity: 0; }
}
@keyframes fadeOut {
  from { opacity: 1; }
    to { opacity: 0; }
}
