@import 'import.pcss';

:local {
  .link {
    text-decoration: none;
    margin-right: rem(22);
    &:last-child {
      margin-right: 0;
    }
  }
 .buttonWrapper {
    @include gradientAnimation(270deg, #FF0000, 6.32% , #EC011F, 95.82%, 0.2s, #BE001E);
    display: flex;
    overflow: hidden;
    padding: 0 rem(20) 0 0;
    border: 0;
    border-radius: 1px;
    max-width: rem(250);

    &:focus {
      outline: none;
    }

    &.wideButton {
      @media $toMobile {
        margin-right: 0;
        margin-bottom: rem(16);
      }
    }
  }

  .leftSide {
    min-width: rem(39);
    height: rem(36);
    position: relative;
    padding-top: rem(10);
    z-index: 2;
  }
  .rightSide {
    padding-top: rem(7);
    margin-left: rem(-39);
    min-width: rem(82);
    height: rem(36);
    position: relative;
    z-index: 1;
    border-radius: 1px;
    overflow: hidden;
  }
  .buttonLabel {
    padding-left: rem(43);
    z-index: 3;
    line-height: rem(17);
    font-size: rem(17);
    white-space: nowrap;
  }
  .buttonIcon {
    position: absolute;
    left: rem(12);
    z-index: 3;
  }
  .vectorIcon {
    z-index: 8;
    position: absolute;
    right: 0;
    top: 0;
  }
  .rectangle {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
  }
  .linearGradient {
    width: rem(73);
    position: absolute;
    left: rem(29);
    top: 0;
    z-index: 10;
    transform: skew(-30deg);
    opacity: 0.3;
    height: rem(36);
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 72.22%, rgba(0, 0, 0, 0.3) 84.54%);
  }
}
