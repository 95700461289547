@import 'import.pcss';

:local {
  .signIn {
    width: 100%;
    height: 100%;
    padding-bottom: rem(138);
    display: flex;
    flex-direction: column;
  }

  /* FIXME: fix this after converting BEM in FormInput to pcss */
  :global(.FormInput__label) {
    margin: 0 0 rem(16);
  }

  .title {
    font-size: rem(16);
    font-weight: $fw-600;
    color: $c-white;
  }

  .description {
    display: block;
    font-size: rem(14);
    line-height: rem(18);
    color: $c-silver;
    margin-top: rem(53);
    font-weight: $fw-600;

    @media $fromMobile {
      padding: 0 rem(60);
    }
  }

  .createAccountContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .createAccountText {
      font-size: rem(14);
      margin-right: rem(9);
    }
  }

  .formContainer {
    margin-top: rem(43);
  }

  .createProfileLink {
    @extend %link;

    font-size: rem(13);
    font-weight: $fw-600;
    line-height: rem(24);
    transition: color 0.1s ease-in-out;

    &:hover {
      color: $c-white;
    }
  }

  .socialRemovedMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(100);
    font-size: rem(14);
    line-height: rem(22);
    color: $c-dt-lightgrey;
    text-align: center;
  }

  .socialRemovedLink {
    @extend %link;

    font-size: rem(14);
    font-weight: $fw-600;
  }
}
