
@mixin resetInput {
  font-size: inherit;
  text-decoration: none;
  border: 0;
  outline: 0;
  //reset default autofill//
  input,
  &:-webkit-autofill,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    -webkit-text-fill-color: $c-dt-lightestgrey;
  }
}
