@import 'import.pcss';

:local {
  .downloadAppContainer {
    display: flex;
    width: 100%;
    position: relative;
    background: #171717;
    min-height: rem(39);
    max-height: rem(100);
    align-items: center;
    padding: rem(5) rem(15);
    justify-content: flex-end;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    border-bottom: 1px solid $c-darkgrey-2;

    @media (max-width: 375px) {
      justify-content: space-between;
    }
    @media $tablet {
      padding: rem(11) rem(24);
    }
    @media $desktop {
      padding: 0;
      display: none;
    }
    @media (min-width: 1025px) and (max-width: 1600px) and (orientation: landscape) {
      padding: rem(11) rem(24);
      display: flex;
    }

    &:not(.visible) {
      min-height: 0;
      max-height: 0;
      padding: 0 rem(14);
      border-bottom: none;
      @media $tablet {
        padding: 0 rem(24);
      }
    }
    .message {
      display: flex;
      flex-direction: column;
      color: $c-white;
      text-align: right;
      font-size: rem(10);
      line-height: rem(13);
      margin-right: rem(13);
    }
    .messageBold {
      font-weight: 500;
    }
    .messageRegular {
      opacity: 0.7;
    }
    .link {
      border-radius: rem(2);
      color: $c-white;
      font-weight: $fw-600;
      min-height: rem(23);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-size: rem(12);
      line-height: rem(15);
      margin-right: rem(5);
      background-color: #333;

      &.ios {
        min-width: rem(62);
        margin-right: rem(11);
      }

      &.android {
        min-width: rem(91);
        margin-right: rem(14);
      }

      &.disabled {
        background-color: #141414;
        span,
        img {
          opacity: 0.3;
        }
      }

      img {
        height: rem(12);
        margin-right: rem(12);
        margin-bottom: rem(3);
      }
    }

    .close {
      background: transparent;
      border: none;
      box-shadow: none;
      width: rem(22);
      height: rem(22);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      img {
        width: rem(22);
        height: rem(22);
        max-width: rem(22);
      }
    }
  }
}

.vjs-sd-fullscreen-notice {
  :local {
    .downloadAppContainer {
      background: #171717;
      &:not(.visible) {
        min-height: rem(58);
        max-height: rem(100);
        padding: rem(11) rem(14);
        opacity: 0;
        @media $tablet {
          padding: rem(11) rem(24);
        }
      }
      @media $desktop {
        padding: 0;
        display: none;
      }
      @media (min-width: 1025px) and (max-width: 1600px) and (orientation: landscape) {
        padding: rem(11) rem(24);
        display: flex;
      }
    }
  }
}

.vjs-user-inactive .vjs-sd-fullscreen-notice {
  :local(.downloadAppContainer) {
    opacity: 0;
  }
}
