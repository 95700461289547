@import 'import.pcss';

:local {
  .genderWrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: rem(5);

    @media $toMobile {
    grid-template-columns: auto auto auto;
    }

    .buttonGender {

        margin-top: rem(5);
        font-size: rem($fz-body2);
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 255);
        border-radius: rem(2);
        background-color: transparent;
        color: rgba(255, 255, 255, 255);
        opacity: 0.6;
        cursor: pointer;
        transition: all 0.15s ease-in-out;

        label {
          padding: rem(8) rem(12);
          display: block;
          width: 100%;
          height: 100%;
        }

        &:hover {
          opacity: 0.8;
        }
        
        &:active {
          cursor: default;
        }
        
        &.selectedButtonGender {
          opacity: 1;
        }
        
        @media $toMobile {
          margin-top: 0;
        }
      }
      .buttonGender input[type="radio"] {
        display: none;
      }
      .buttonGender label {
        cursor: inherit;
      }
  }
}
