@import 'import.pcss';
$slide-max-width: 891;
$slide-space-between: 10.5px;
:local {
  $carouselControlNegMargin: calc(
    -1 * ((100vw - #{$slide-max-width}px) / 2 + 3px)
  );
  .swiperContainer {
    &:hover {
      .shelfInnerButtonContainer.innerButtonContainer {
        opacity: 1;
      }
    }
  }
  .carousel {
    position: relative;
    overflow: hidden;
  }
  .swiperPaginationCenter {
    padding-top: em(10);
    line-height: 0;
    text-align: center;
    :global(.swiper-pagination-bullet) {
      margin: 0 rem(5);
      cursor: pointer;
      @media $tablet {
        width: em(9);
        height: em(9);
      }
    }
    :global(.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)) {
      background: $c-lightgrey;
    }
    @media $tablet {
      position: relative;
      bottom: em(31);
      z-index: 10;
      padding-top: 0;
      margin-bottom: em(-9);
    }
  }
  .swiperPaginationRight {
    position: absolute;
    top: em(-45);
    right: 0;
    display: none;
    width: auto;
    :global(.swiper-pagination-bullet + .swiper-pagination-bullet) {
      margin: 0 0 0 rem(7);
    }
    :global(.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)) {
      background: $c-dt-mediumgrey;
    }
    @media $desktop {
      display: block;
    }
  }
  .buttonContainer {
    @include v-align(absolute);
    z-index: 50;
    display: none;
    height: 105.5%;
    border: none;
    @media (pointer: fine) {
      display: flex;
    }
  }
  :global(.swiper-pagination-lock) {
    display: none;
  }
  .carouselButtonContainer {
    display: none;
    outline: none;
    @media $desktop {
      $nextPrevSlidePreviewWidth: calc(
        ((100vw - #{$slide-max-width}px) / 2) - #{$slide-space-between}
      );
      position: absolute;
      z-index: 20;
      display: block;
      width: $nextPrevSlidePreviewWidth;
      height: 100%;
      padding: 0;
      cursor: pointer;
      background: none;
      border: none;
    }
  }
  .carouselButtonContainerNext {
    right: 0;
    justify-content: flex-end;
  }
  .carouselButtonContainerPrevious {
    left: 0;
    justify-content: flex-start;
  }
  .shelfButtonContainerNext {
    right: -$mobileComponentsHorizontalSpace;
    justify-content: flex-end;
    @media $tablet {
      right: -$tabletComponentsLargeHorizontalSpace;
    }
    @media $desktop {
      right: -$desktopComponentsHorizontalSpace;
    }

    &.noPadding {
      right: rem(-52);

      @media $fromLargeTablet {
        right: rem(-52);
      }
      @media $desktopM {
        right: rem(-52);
      }

      .button {
        position: relative;
        right: rem(52);
      }
    }
  }
  .shelfButtonContainerPrevious {
    left: -$mobileComponentsHorizontalSpace;
    justify-content: flex-start;
    @media $tablet {
      left: -$tabletComponentsLargeHorizontalSpace;
    }
    @media $desktop {
      left: -$desktopComponentsHorizontalSpace;
    }

    &.noPadding {
      left: rem(-52);

      @media $tablet {
        left: rem(-52);
      }
      @media $desktop {
        left: rem(-52);
      }

      .button {
        position: relative;
        left: rem(52);
      }
    }

    &.hidePrevArrowContainer {
      display: none;
    }
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: em(35);
    height: em(95);
    cursor: pointer;
    border: none;
    &:focus {
      outline: none;
    }
    &:global(.swiper-button-disabled) {
      display: none;
    }
  }
  .carouselButton {
    @include v-align(absolute);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: em(32);
    height: em(95);
    cursor: pointer;
    user-select: none;
    background: rgba(26, 24, 29, 0.5);
    transition: background 0.2s ease-in-out;
    &:hover,
    &:focus {
      background: rgba(26, 24, 29, 0.8);
    }
  }
  .carouselButtonNext {
    right: 0;
    border-radius: em(3) 0 0 em(3);
  }
  .carouselButtonPrevious {
    border-radius: 0 em(3) em(3) 0;
  }
  .shelfButtonNext {
    border-radius: em(3) 0 0 em(3);
    box-shadow: em(-10) 0 em(20) 0 rgba(0, 0, 0, 0.2);
    background: $c-app-bg;
  }
  .shelfButtonPrevious {
    border-radius: 0 em(3) em(3) 0;
    box-shadow: em(10) 0 em(20) 0 rgba(0, 0, 0, 0.2);
    background: $c-app-bg;
  }
  .carouselIcon {
    width: em(11);
    height: em(19);
  }
  .carouselIconPrevious {
    transform: rotate(180deg);
  }
  .shelfIconPrevious {
    transform: rotate(180deg);
  }
  .shelfInnerButtonContainer {
    display: flex;
    align-items: center;
    margin-top: 9%;
  }
  .buttonContainer:not(.carouselButtonContainer) {
    height: 100%;
    top: 0;
    transform: none;
    @media (-webkit-min-device-pixel-ratio: 1.3) {
      height: calc(100% + 1.6px);
    }

    .innerButtonContainer {
      width: rem(52);
      position: relative;
      margin-top: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      .shelfButtonNext,
      .shelfButtonPrevious {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        z-index: 1;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
      }
      .shelfIcon {
        width: rem(10);
        z-index: 0;
      }
      .shelfIconPrevious {
        margin-right: rem(11);
      }
      .shelfIconNext {
        margin-left: rem(11);
      }
    }
    .linearContainer {
      background: linear-gradient(
        270deg,
        #101010 12.68%,
        rgba(16, 16, 16, 0) 100%
      );
      width: rem(12);
      height: 100%;
      position: absolute;
      right: rem(37);
      top: 0;
    }
    .radialContainer {
      height: 100%;
      width: rem(45);
      overflow: hidden;
      position: absolute;
      left: rem(7);
      top: 0;
      .radialSvg,
      .radialSvgHover {
        position: absolute;
        top: 0;
        left: 0;
      }
      .radialSvgHover {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
    }
    &:hover {
      .radialSvgHover {
        opacity: 1;
      }
    }
    :global(.swiper-button-disabled) .linearContainer,
    :global(.swiper-button-disabled) .radialContainer {
      display: none;
    }
    .shelfButtonPrevious .linearContainer {
      left: rem(37);
      right: auto;
      transform: rotate(180deg);
    }
    .shelfButtonPrevious .radialContainer {
      left: 0;
      right: auto;
      .radialSvg,
      .radialSvgHover {
        right: 0;
        left: auto;
      }
    }
    .shelfButtonPrevious.shelfButtonImage .linearContainer {
      left: rem(34);
      width: rem(18);
    }
    .shelfButtonNext.shelfButtonImage .linearContainer {
      right: rem(34);
      width: rem(18);
    }
    .shelfButtonPrevious.shelfButtonPromo .linearContainer {
      left: rem(37);

      @media $desktopM {
        left: rem(32);
        width: rem(20);
      }
    }
    .shelfButtonNext.shelfButtonPromo .linearContainer {
      right: rem(37);

      @media $desktopM {
        right: rem(32);
        width: rem(20);
      }
    }
  }
  .buttonContainer.carouselButtonContainer .carouselButton {
    width: rem(34);
    background-color: $c-black;
    opacity: 0.5;
    transition: opacity 0.2s ease-in;
    border-radius: 2px 0 0 2px;

    &:hover {
      opacity: 0.8;
    }
  }
  .noPadding {
  }
  .disabled {
  }
}
