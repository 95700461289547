@import 'import.pcss';

:local {
  .adProgressCircleWrap {
    position: relative;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    margin-right: rem(15);

    .adProgressCircle {
      width: rem(63);
      height: rem(63);
      fill: none;
      transform: rotate(-90deg);
      .adProgressCircleBack {
        stroke: rgba(250, 204, 35, 0.5);
        stroke-width: 4px;
      }
      .adProgressCircleProgress {
        stroke: $c-ad-yellow;
        stroke-width: rem(4);
        stroke-dashoffset: 0;
        transition: stroke-dasharray 0.7s linear 0s;
        stroke-linecap: round;
      }
    }

    .adProgressCircleText {
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: rem(15);
      line-height: rem(20);
      color: $c-ad-yellow;
      font-weight: $fw-700;
      display: flex;
      align-items: center;
      justify-content: center;
      top: rem(1);
    }
    @media ($smallerThanTablet and (orientation: portrait)) or ($toDesktop and (orientation: landscape)) {
      margin-right: rem(-1);
      width: rem(53);
      height: rem(53);
      .adProgressCircle {
        width: rem(53);
        height: rem(53);
      }
      .adProgressCircleText {
        font-size: rem(13);
      }
      @media $toDesktop and (orientation: landscape) {
        margin-right: rem(10);
      }
    }
  }
}
