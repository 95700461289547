@import 'import.pcss';

:local {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: rem(50);
  }
  .title {
    font-size: rem(16);
    font-weight: $fw-600;
    color: $c-white;
  }

  .subtitle {
    font-size: rem(14);
    margin-top: rem(56);
    color: $c-silver;
    font-weight: $fw-600;
  }

  .location {
    font-size: rem(16);
    font-weight: $fw-600;
    color: $c-white;
    margin-top: rem(16);
    display: flex;
    gap: rem(8);

    svg {
      width: rem(13);
      fill: $c-lightgrey;
    }
  }

  .radioButtonRow {
    display: flex;
    gap: rem(16);
    margin-top: rem(32);
    justify-content: center;
  }

  .radioButton {
    border: solid 1px $c-white;
    font-size: rem(14);
    opacity: 0.6;
    color: $c-white;
    background: none;
    padding: rem(9) rem(12);
    transition: opacity 0.2s;
    font-weight: $fw-600;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  .inputRow {
    display: flex;
    gap: rem(16);
    margin-top: rem(32);
  }

  .submitButton {
    background: $c-dt-red;
    color: $c-white;
    font-size: rem(14);
    font-weight: $fw-600;
    border: none;
    width: 100%;
    margin-top: rem(48);
    padding: rem(16) 0;

    &:disabled {
      background: $c-darkred;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .successMessage {
    font-size: rem(14);
    color: $c-white;
    margin-top: rem(32);
    text-align: center;
  }

  .errorMessage {
    font-size: rem(14);
    color: $c-dt-red;
    margin-top: rem(16);
    text-align: center;
  }

  .troubleshooterMessage {
    font-size: rem(12);
    margin-top: rem(16);
    color: $c-red;
    font-weight: $fw-600;
  }

  .pinIcon {
    width: em(13);
    fill: $c-lightgrey;
    transition: fill 0.2s ease-in-out;
  }
}
