@import 'import.pcss';

/*----------       Chromecast Styling      ----------*/
$c-ad-progress-color: #ffe400;

.Player__video {
  &.vjs-chromecast-initializing {
    pointer-events: none;
  }

  &.vjs-chromecast-initializing .vjs-big-play-button,
  &.vjs-chromecast-loading .vjs-big-play-button {
    display: none;
  }
}

.video-js.not-hover.vjs-chromecast-loaded .vjs-top-bar-gradient,
.video-js.not-hover.vjs-chromecast-loaded .vjs-control-bar-gradient,
.video-js.not-hover.vjs-chromecast-loaded .vjs-ad-info-circle-gradient {
  opacity: 0;

  &.vjs-title-wrap-visible {
    opacity: 1;
  }
}

.video-js .vjs-chromecast-button {
  display: flex;
  align-items: center;
  justify-content: center;
  order: 10;

  .vjs-icon-placeholder {
    width: em(23, 14);
    height: em(25, 14);
    display: block;
    background: url('../../assets/img/chromecast.svg') no-repeat;
    position: static;
  }

  &--connected {
    .vjs-icon-placeholder {
      background-image: url('../../assets/img/chromecast_connected.svg');
    }
  }
}

/* prevent screen black out in offer mode when ima3 attempts to load ad */
.vjs-ima3-html5.vjs-ad-loading .vjs-tech.vjs-tech-chromecast {
  opacity: inherit;
}

.vjs-chromecast-ad-playing .vjs-play-progress.vjs-slider-bar {
  background: $c-ad-progress-color;
}

.vjs-chromecast-loaded {
  .vjs-control-bar {
    z-index: 2;
    transform: translateY(0) !important;
    // Force the control bar to stay visible when casting opacity: 1 !important;
    // Force the control bar to stay visible when casting
  }

  .vjs-big-play-button {
    display: none;
  }

  // Modify ad countdown as it's position is slghtly different when we're casting
  .Player__ad-countdown {
    z-index: 5;
    bottom: em(62);
    transform: translate3d(0, em(-20), 0);
  }

  &.vjs-chromecast-ad-playing {
    .Player__ad-countdown {
      display: block;
    }
  }

  .vjs-top-bar-back-button-wrapper {
    display: none;
  }
  .vjs-top-bar {
    transform: translate3d(0, 0, 0) !important;
    .vjs-top-bar-news-upgrade-cc-cast-wrapper {
      justify-content: flex-end;
    }
  }

  .vjs-control-bar > .vjs-fullscreen-control.vjs-fullscreen-control,
  .vjs-control-bar > .vjs-volume-panel.vjs-volume-panel {
    display: none;
  }

  .skipRecapOverlayContainer.canBeHidden {
    opacity: 0;
  }
}

.chromecast-tech {
  position: relative;

  &__image {
    width: 105%;
    max-width: none;
    height: 105%;
    filter: blur(5px);
    position: absolute;
    top: -2%;
    left: -2%;
    z-index: 1;
    pointer-events: none;
  }

  &__overlay {
    background-color: rgba(0, 0, 0, 0.45);
    position: relative;
    z-index: 2;
    height: calc(100% - 42px);
    display: flex;
    justify-content: center;
    align-items: center;

    &--mode-offer {
      cursor: pointer;
    }
  }

  &__information {
    text-align: center;
    width: em(300);
    font-size: em(15, 14);
    font-weight: $fw-500;
    font-family: $ff-content;
  }

  &__cc-icon {
    font-size: em(16, 15);
    width: em(36);
    height: em(36);
    margin-bottom: em(10);
  }

  &__play {
    display: inline-block;
    position: relative;
    width: em(50);
    height: em(50);
  }

  &__play-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin-top: em(20);
    transition: 0.2s opacity ease;

    &--hover {
      opacity: 1;
    }

    .not-hover & {
      opacity: 1;

      &--hover {
        opacity: 0;
      }
    }
  }

  &--mode-offer {
    .chromecast-tech__overlay {
      height: 100%;
    }

    .vjs-control-bar {
      display: none;
    }
  }
}
