@import 'import.pcss';

$fz: 14;

.Button {
}

/*----------  Children  ----------*/
.Button {
  &__element {
    font-size: rem($fz);
    padding: em(14, $fz) em(26.5, $fz);
    line-height: 1.143; // = 16 / 14
    font-weight: $fw-600;
    border: none;
    outline: none;
  }
}

/*----------  Modifiers  ----------*/
.Button {
  &--small {
    .Button__element {
      $fz-small: 13;
      font-size: 13px;
      padding: em(6, $fz-small) em(12, $fz-small);
      font-weight: $fw-600;
      width: 95px;
    }
  }

  &--rounded {
    .Button__element {
      border-radius: em(6);
    }
  }

  &--redGradient {
    .Button__element {
      background: linear-gradient(180deg, $c-red 0%, $c-rosso-corsa 100%);
      border: 1px solid $c-rosso-corsa;

      &:hover {
        background: #df0000;
      }

      &:active {
        box-shadow: inset em(0, $fz) em(0, $fz) em(2, $fz) darken(#df0000, 20);
      }
    }
  }

  &--lightBlue {
    .Button__element {
      background: $c-lightblue;
    }
  }
  &--dtRed {
    .Button__element {
      background: $c-dt-red;
      color: $c-white;
    }
  }

  &--white {
    .Button__element {
      background: $c-white;
      border: 1px solid $c-grey;

      &:hover {
        background: darken($c-white, 10);
      }

      &:active {
        box-shadow: inset em(0, $fz) em(0, $fz) em(2, $fz) darken($c-grey, 20);
      }
    }
  }

  &--green {
    .Button__element {
      background: $c-green;

      &:hover {
        background: darken($c-green, 20);
      }

      &:active {
        box-shadow: inset em(0, $fz) em(0, $fz) em(2, $fz) darken($c-green, 20);
      }
    }
  }

  &--textWhite {
    color: #fff;

    :visited {
      color: #fff;
    }

    :link {
      color: #fff;
    }
  }

  &--textRed {
    color: #be001e;

    :visited {
      color: #be001e;
    }

    :link {
      color: #be001e;
    }
  }

  &--resetBtn {
    padding: 0;
    line-height: 0;
  }

  &--noBorder {
    .Button__element {
      padding: 0;
      border: none;
    }
  }

  &--noBackground {
    background: $c-transparent;
    .Button__element {
      background: $c-transparent;
      color: $c-dt-lightestgrey;

      &:hover {
        background-color: $c-transparent;
      }
    }
  }

  &--whiteBordered {
    .Button__element {
      border: 1px solid $c-ee-white-03;
      color: $c-ee-white-08;
    }
  }

  &--whiteBorderedOpacityText {
    .Button__element {
      border: 1px solid $c-ee-white-03;
      color: $c-ee-white-04;
    }
  }

  &--opacity7 {
    .Button__element {
      color: $c-ee-white-07;
    }
  }
  &--opacity8 {
    .Button__element {
      color: $c-ee-white-08;
    }
  }

  &--smallerFontSize {
    .Button__element {
      font-size: rem(12);
      padding: rem(11) rem(22);
    }
  }

  &--biggerFontSize {
    .Button__element {
      font-size: rem(14);
    }
  }

  &--borderRadius1 {
    .Button__element {
      border-radius: 1px;
    }
  }

  &--transparentBorder {
    .Button__element {
      border: 1px solid transparent;
    }
  }

  &--opacityBorder {
    .Button__element {
      border: 1px solid transparent;
      opacity: 0.4;
    }
  }

  &--violet {
    .Button__element {
      color: violet;
    }
  }

  &--spinner {
    .Button__element {
      background-color: transparent;

      &:not([disabled]):hover {
        background-color: transparent;
      }
    }
  }
}
