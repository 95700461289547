@import 'import.pcss';

.HR {
  background: $c-dt-darkgrey;
  margin: 0;
  display: block;
  height: 1px;
  border: 0;
  outline: 0;
}

.HR {
  &--red {
    background: $c-dt-red;
  }
  &--medium {
    height: 2px;
  }
  &--large {
    height: 3px;
  }
}
