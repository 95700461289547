@import 'import.pcss';

:local {
  .featureShelf {
    display: flex;
    @media $tablet {
      margin-right: $mobileComponentsHorizontalSpace;
    }
  }
  .twinFeatureShelf {
    flex-direction: column;
    @media $tablet {
      flex-direction: row;
    }
  }
}
