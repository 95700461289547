@import 'import.pcss';

:local {
  .dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: $fw-500;
  }

  .dialogHeader {
    font-size: rem(16);
    line-height: rem(28);
    font-weight: bold;
    color: $c-dt-lightgrey;
    margin: 0 0 rem(20);
  }

  .dialogText {
    font-size: rem(14);
    line-height: rem(22);
    color: $c-dt-lightgrey;
    margin: 0 0 rem(20);
    font-weight: $fw-400;
  }

  .dialogContinue {
    text-decoration: none;
    font-size: rem(14);
    font-weight: $fw-600;
    padding-left: rem(50);
    padding-right: rem(50);
    margin: 0 0 rem(27);
  }

  .dialogLink {
    font-size: rem(14);
    line-height: rem(22);
    text-decoration: none;
    color: $c-dt-lightestgrey;

    &:hover {
      text-decoration: underline;
    }
  }
}