@import 'import.pcss';

:local {
  .registrationForm {
    display: flex;
    flex-direction: column;

    @media $fromMobile {
      padding-right: rem(52);
      padding-left: rem(52);
    }
    .genderContainer {
      padding: rem(6) 0 rem(15) 0;
    }
    .genderTitle {
      text-align: start;
      margin: 0 0 rem(3) 0;
    }
    .formWrapper {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      gap: rem(9);
    }

    .registerFormInput {
      span {
        color: $c-silver;
        font-size: rem(14);
      }
    }

    .registerFormInput {
      &.hintTextInput input {
        border-bottom: rem(1) solid $c-silver;
        &:focus {
          border-bottom: rem(1) solid $c-white;
        }
      }
    }
    .errorInput {
      input {
        border-bottom: rem(1) solid $c-dt-border-error;
      }
    }
    .hintTextInput + span {
      color: $c-ee-white-08;
    }
  }

  /* FIXME: fix this after converting BEM in FormInput to pcss */
  :global(.FormInput__label) {
    margin: 0 0 rem(16);
  }

  .errorMessage {
    margin-top: rem(-20);
    margin-bottom: rem(20);
  }

  .button {
    @extend %submit-button;
    font-weight: $fw-600;
    height: rem(46);
    color: $c-white;
    border-radius: 1px;

    &[disabled] {
      background: $c-darkred;
      color: $c-ee-white-05;
    }

    &:not([disabled]):hover {
      background-color: $c-red-new;
      color: $c-white;
    }
  }

  .footer {
    margin-top: rem(30);
    color: $c-dt-lightgrey;

    @media $toMobile {
      margin-top: 0;
    }
  }
}
