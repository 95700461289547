@import 'import.pcss';

:local {
  .circle {
    position: relative;
  }

  .logoContainer {
    width: em(51);
    height: em(51);
    margin-top: em(1);
  }

  .logoInner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    transform: translate3d(0, 0, 0);
  }

  .logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .circleBanner {
    .logoContainer {
      width: em(24);
      height: em(24);

      @media $fromMobile {
        width: em(28);
        height: em(28);
      }

      @media $desktop {
        width: em(33);
        height: em(33);
      }
    }
  }
}
