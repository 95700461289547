@import 'import.pcss';

:local {
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 rem(20);

    @media $desktop {
      padding: 0;
    }
  }

  .title {
    color: $c-dt-red;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 rem(30);
  }

  .text {
    color: $c-darkgrey;
    margin: 0 0 rem(20);
    font-size: rem(14);
    line-height: rem(20);
  }

  .cookieText {
    display: block;
  }

  .option + .option {
    margin: rem(8) 0 0;
  }

  .save {
    display: flex;
    align-items: center;
    margin: rem(20) 0 0;
    padding: rem(10) rem(18);
    font-weight: 400;
    color: $c-white;
    background: $c-dt-red;
    border-radius: 4px;
  }
}