@import 'import.pcss';

:local {
  .channelCarousel {
    position: relative;
    margin: rem(1) 0 0;

    &.channelCarouselOverride {
      margin: rem(1) 0 0;
    }

    :global(.swiper-container) {
      overflow: visible;
    }
  }
}