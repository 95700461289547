@import 'import.pcss';

:local {
  .closeYourAccountContainer {
    @media $desktop {
      padding: 0 rem(70) rem(53) rem(70);
    }

    .text {
      font-size: rem(13);
      margin-top: rem(34);
      margin-bottom: rem(65);
    }

    .submitWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: rem(35);

      a {
        width: 100%;
      }

      @media $toDesktop {
        width: 100%;
      }
    }

    .submitingSubmitWrapper {
      width: 100%;
    }

    .closeYourAccountButton {
      width: 100%;
      font-weight: $fw-600;
      background-color: $c-dt-red;
      height: rem(46);
      border: none;
      border-radius: 1px;

      &:disabled {
        &:hover {
          background-color: $c-dt-red;
          color: $c-ee-white-07;
        }
      }

      &:not([disabled]):hover {
        background-color: $c-red-new;
        color: $c-white;
      }
    }

    .privacyPolicyTextWrapper {
      margin-top: rem(25);

      span {
        font-size: rem(10);
      }

      a {
        text-decoration: none;
      }

      .privacyPolicyText {
        color: $c-dt-lightestgrey;
        transition: color 0.1s ease-in-out;

        &:hover {
          color: $c-white;
        }
      }
    }
  }
}
