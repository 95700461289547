@import 'import.pcss';

:local {
  .link {
    text-decoration: none;
  }

  .infoIcon {
    transition: opacity 0.2 ease-in-out;
  }

  .infoButtonWrapper {
    display: flex;
    width: rem(32);
    height: rem(17);

    .infoButtonText {
      display: block;
      font-size: rem(14);

      &::first-letter {
        color: $c-white;
      }

      &:after {
        content: '';
        background: -webkit-linear-gradient(
          left,
          rgba(255, 255, 255, 0.65) 0%,
          rgba(255, 255, 255, 0.23) 100%
        );
        display: block;
        height: rem(2);
        width: rem(31);
        border-radius: 30px;
      }
    }
  }
}
