@import 'import.pcss';

:local {
  .backgroundVideoContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: visibility 0s linear 0.5s, opacity 0.5s linear;
    background-color: $c-black;
    overflow: hidden;

    &.visible {
      opacity: 1;
      .volumeButton {
        visibility: visible;
      }
    }

    .wrap {
      top: rem(44);
      height: 56.25vw;
      bottom: auto;
    }

    .volumeButton {
      position: absolute;
      top: calc((44px + 76.267vw) * 0.95 - 6.93vw - 10px);
      right: calc(5% - 10px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      z-index: 3;
      background: transparent;
      border: none;
      padding: rem(10);
      border-radius: 50%;

      .iconWrap {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.33);
        border-radius: 50%;
        width: 6.93vw;
        height: 6.93vw;
        max-width: rem(40);
        max-height: rem(40);
        min-width: rem(26);
        min-height: rem(26);
        svg {
          width: 60%;
        }
      }

      &:hover {
        .iconWrap {
          background: $c-ee-white-03;
          border: 1px solid rgba(0, 0, 0, 0.5);
        }
      }
    }

    @media $smallerThanTablet and (orientation: portrait) {
      .wrap {
        height: 76.267vw;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        width: 100%;
        overflow: hidden;
        :global(.Player__video) {
          width: 135%;
          left: -17.5%;
        }
      }
    }

    @media $tablet {
      .wrap {
        height: 100%;
        top: 0;
        bottom: 0;
      }
      .volumeButton {
        padding: 0;
        top: auto;
        right: 2.45vw;
        bottom: 2.45vw;

        &.volumeButtonWithBtyb {
          bottom: rem(99);
        }

        .iconWrap svg {
          width: 50%;
        }
      }
    }

    @media $desktop {
      .wrap {
        overflow: hidden;
        :global(.Player__video) {
          height: 123%;
          overflow: hidden;
        }
      }
      .volumeButton .iconWrap {
        max-width: rem(50);
        max-height: rem(50);
      }
    }
  }
}
