@import 'import.pcss';

:local {
  .changePasswordContainer {
    display: flex;
    flex-direction: column;
    width: rem(378);

    @media $toMobile {
      width: 100%;
      max-width: rem(567);
    }

    @media $toDesktop {
      align-items: start;
    }

    .title {
      margin-bottom: rem(30);
      text-align: center;
      font-size: rem(16);

      @media $toDesktop {
        padding-top: rem(30);
      }
    }

    .formWrapper {
      width: 100%;
    }

    .changePasswordInput,
    .redErrorPasswordInput {
      span {
        color: $c-silver;
        font-size: rem(14);
      }
    }

    .changePasswordInput {
      &.hintTextInput input {
        border-bottom: rem(1) solid $c-silver;
        &:focus {
          border-bottom: rem(1) solid $c-white;
        }
      }
    }

    .hintTextInput + span {
      color: $c-ee-white-08;
    }

    .redErrorPasswordInput {
      input {
        border-bottom: rem(1) solid $c-dt-border-error;
      }
    }

    .redErrorPasswordInput + span {
      color: $c-new-error;
    }

    .btnWrapper {
      display: flex;
      margin-top: rem(35);
      width: 100%;

      @media $toDesktop {
        align-items: center;
        flex-direction: row;
        justify-content: center;
      }

      .submitWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          width: 100%;
        }

        @media $toDesktop {
          width: 100%;
        }
      }

      .submitingSubmitWrapper {
        width: 100%;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(46);

        @media $toDesktop {
          width: 100%;
        }

        @media $desktop {
          min-width: rem(180);
        }
      }

      .cancelButtonWrapper {
        margin-right: rem(18);

        @media $toDesktop {
          width: 100%;
          margin-right: rem(15);
        }

        .cancelButton {
          &:hover {
            color: $c-red;
            transition: color 0.3s ease-in-out;
          }
        }
      }

      .changePasswordButton {
        transition: all 0.3s ease-in-out;
        &:not([disabled]):hover {
          background-color: $c-red-new;
          color: $c-white;
        }
      }
    }
  }
}
