@import 'import.pcss';

:local {
  .logoWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .logo {
    height: rem(18);
  }
}
