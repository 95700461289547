@import 'import.pcss';

// $fz: 11;

.ToTopLink {
  font-size: em(11);
  font-weight: $fw-700;
  color: $c-darkgrey;
  display: block;
  text-decoration: none;

  @media $tablet {
    color: $c-dt-lightgrey;
  }
}

/*----------  Children  ----------*/
.ToTopLink {
  &__icon {
    transform: rotate(-90deg);
    height: em(12, 11);
    width: em(11, 11);
    margin-left: em(6, 11);
    position: relative;
    top: em(2, 11);
  }
}
