@import 'import.pcss';

$easingCurve: cubic-bezier(0.25, 0.46, 0.45, 0.94);

:local {
  .cookieContainer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 50;
    width: 100%;
    max-width: 1024px;
    min-height: 191px;
    padding: rem(24) rem(45) rem(24) rem(21);
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    transform: translate3d(0, 10%, 0);
    animation: enterFrame 0.4s $easingCurve 3s forwards;

    @media $fromMobile {
      max-height: 320px;
      padding-top: rem(60);
      overflow-y: scroll;
    }

    @media $tablet {
      right: rem(26);
      bottom: rem(26);
      max-width: 704px;
      max-height: 100%;
      padding-top: rem(24);
      overflow: hidden;
    }
  }

  .cookieText {
    display: block;
    margin-bottom: rem(20);
    font-size: rem(14);
    line-height: rem(18);
    color: $c-dt-lightestgrey;

    a {
      color: $c-dt-lightestgrey;
      text-decoration: underline;
    }
  }

  .toggleSettingsButton {
    padding: 0;
    font-size: rem(14);
    font-weight: $fw-500;
    color: $c-dt-lightestgrey;
    text-decoration: underline;
    background: none;
    border: none;
  }

  .cookieAcceptanceText {
    display: block;
    margin-bottom: rem(20);
    font-size: rem(14);
    line-height: rem(18);
    color: $c-dt-lightestgrey;
  }

  .settingsHeading {
    display: block;
    font-size: rem(14);
    line-height: rem(18);
    color: $c-dt-lightestgrey;
    font-weight: $fw-700;
  }

  .settings {
    display: flex;
    flex-direction: column;
    margin: 0 0 rem(30);
    color: $c-dt-lightestgrey;

    @media $fromMobile {
        flex-direction: row;
    }
  }

  .settings > .option {
    margin:  rem(23) rem(23) 0 0;
  }

  .closeButton {
    position: absolute;
    right: 25px;
    background: transparent;
  }

  .acceptAndCloseButton {
    display: flex;
    align-items: center;
    padding: rem(10) rem(18);
    font-weight: 400;
    color: $c-white;
    background: $c-dt-red;
    border-radius: 4px;

    .tick {
      margin: 0 0 0 rem(10);
      transform: scale(0.8);
    }
  }
}

@keyframes :local(enterFrame) {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
