@import 'import.pcss';

:local {
  .ChannelShelf {
    padding: 0;

    margin-right: $mobileComponentsHorizontalSpace;

    @media $fromLargeTablet {
      margin-right: $tabletComponentsHorizontalSpace;
    }

    @media $desktopM {
      margin-right: $desktopComponentsHorizontalSpace;
    }

    // NOTE: required to increase specificity and overide Section.pcss
    @media $toTablet {
      &.channelShelfOverrides {
        margin-left: 0;
        margin-right: 0;
      }
    }

    :global {
      .swiper-container {
        overflow: visible;
      }
    }
  }

  .shelfItem {
    border-bottom: em(1) solid $c-dt-linegrey;

    &:last-child {
      border-bottom: none;
    }

    @media $fromMobile {
      border-bottom: none;
    }
  }

  .shelfGridItem {
    margin-bottom: rem(6);
  }

  .livePage {
    :global {
      .swiper-container {
        overflow: visible;
      }
      .swiperButtonContainerNext,
      .swiperButtonContainerPrevious {
        display: none;
      }
    }
  }

  .headingWrapper {
    &.liveFilteringHeading {
      padding-bottom: rem(17);

      @media $desktop {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
      }

      @media $toTablet {
        padding-bottom: rem(15);
      }
    }
  }

  .carousel {
    position: relative;
    padding: 0;

    &:first-of-type {
      @media $toTablet {
        margin-left: $mobileComponentsHorizontalSpace;
        padding-right: $mobileComponentsHorizontalSpace;
      }
    }
  }

  .carousel-pagination {
    position: absolute;
    top: em(-45);
    right: 0;
    display: none;
    width: auto;

    @media $desktop {
      display: block;
    }
  }

  .carousel-pagination-page {
    margin-right: em(7);
    background-color: $c-lightergrey;
    transition: background-color 0.3s ease-in-out;

    // we have to increase the specificity here since our production build changes the order of how
    // css files are applied (due to performance optimizations) which makes .swiper-pagination-bullet
    // overrides .MediaShelf__carousel-pagination-page--active in this case

    &--active:global(.swiper-pagination-bullet) {
      background-color: $c-dt-red;
    }
  }
}
