@import 'import.pcss';

:local {
    .card {
      position: relative;
      height: 100%;
      z-index: 0;
      box-sizing: border-box;
      * {
        box-sizing: border-box;
      }

      &:hover .playWrapper {
        opacity: 1;
      }
      &:hover .imageWrapper.imageWrapperContinueWatching img {
        transform: scale(1.08);
      }
    }

  .imageWrapper {
    position: relative;
    border-radius: 1px;
    overflow: hidden;

    .lozengeWrapper {
      position: absolute;
      bottom: 0;
      left: rem(5);
      z-index: 1;

      @media $toMobile {
        font-size: 0.9em;
        bottom: 0;
        left: rem(5);
      }
    }
  }

  .progressWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }

  .playWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: rem(-15) 0 0 rem(-15);
    z-index: 1;
    opacity: 0;
    transition: 0.2s;
  }


  .contentWrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: rem(4) 0 0 0;

    @media $fromMobile {
      display: flex;
      width: 100%;
      padding: rem(13) rem(14) 0 rem(15);
      
    }
    .titleWrapper {
      position: relative;
    }
    .subtitleWrapper {
      line-height: rem(11);
    }
  }
  .synopsisWrapper {
    padding: 0 rem(12) rem(14) rem(13);
    line-height: rem(14);
    position: relative;
    overflow: hidden;

    @media $tablet {
      padding: 0 rem(14) rem(14) rem(15);
    }
  }

  .link {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  .card-in-grid {
    padding: 0;
    height: 100%;
    min-width: 100%;

    .rowWrapper {
      align-items: stretch;

      @media $fromMobile {
        display: block;
      }
    }
  }

  .card-in-grid:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .episodeCardTitle {
    font-size: rem(13);
  }

  .title {
    font-size: rem(14);
    line-height: rem(18);
  }

  .subtitle {
    font-size: rem(12);
  }
}
