@import 'import.pcss';
:local {
  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(50);
    @media $fromMobile {
      margin-top: rem(50);
    }
  }
  .mailIcon,
  .email {
    margin-bottom: rem(60);
    font-weight: $fw-700;
    color: $c-white;
    font-size: rem(14);
  }
  .primaryMessage {
    display: block;
    margin-bottom: rem(12);
    font-size: rem(14);
    font-weight: $fw-600;
    line-height: rem(17);
    color: $c-silver;
    text-align: center;
  }
  .secondaryMessage {
    font-size: rem(14);
    font-weight: $fw-600;
    line-height: rem(22);
    color: $c-gray-62;
    text-align: center;
    margin-top: rem(15);
  }
  .emailSent {
    width: auto;
    height: rem(116);
    margin-bottom: rem(47);
  }
  .linksContainer {
    display: flex;
    min-height: rem(30);
    > span {
      color: $c-dt-lightgrey;
    }
  }
  .emailSentMessage {
    font-size: rem(13);
    font-weight: $fw-600;
    line-height: rem(22);
    color: $c-dt-lightgrey;
  }
  .link {
    @include resetBtn;
    padding: 0 rem(12);
    font-size: rem(11);
    font-weight: $fw-600;
    line-height: rem(22);
    color: $c-white;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 3px;
    color: $c-dt-lightestgrey;
    transition: color 0.1s ease-in-out;

    &:hover {
      color: $c-white;
    }
  }
  .getNewPasswordLink {
    @include resetBtn;
    border: 1px solid $c-ee-white-06;
    border-radius: rem(2);
    color: $c-ee-white-06;
    font-size: rem(14);
    font-weight: $fw-600;
    margin-top: rem(24);
    margin-bottom: rem(31);
    padding: rem(9) rem(12);
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $c-white;
      border: 1px solid $c-white;
    }
  }
  .link:focus {
    border: 2px solid $c-lightblue;
    box-sizing: border-box;
    box-shadow: 0 0 0 1.2px $c-white;
    border-radius: 5px;
  }
}
