@import 'import.pcss';

$fz: 14;

:local{
  .ButtonLink {
    border-radius: 1px;
    color: #fff;
    font-size: em($fz);
    padding: em(14, $fz) em(26.5, $fz);
    line-height: 1.143; //= 16 / 14
    font-weight: $fw-600;
    border: 1px solid transparent;
    text-align: center;
    text-decoration: none;

    :visited,
    :link {
      color: #fff;
    }
  }

  /*----------  Modifiers      ----------*/
  .ButtonLink {
    &--red-gradient {
      background: linear-gradient(180deg, $c-red 0%, $c-rosso-corsa 100%);

      &:hover {
        background: $c-rosso-corsa;
      }

      &:active {
        box-shadow: inset em(0, $fz) em(0, $fz) em(2, $fz)
          darken($c-rosso-corsa, 20);
      }
    }

    &--green {
      background: $c-green;

      &:hover {
        background: darken($c-green, 20);
      }

      &:active {
        box-shadow: inset em(0, $fz) em(0, $fz) em(2, $fz) darken($c-green, 20);
      }
    }

    &--blue {
      background: $c-lightblue;
    }

    &--white {
      background: white;
      border-color: $c-lightergrey;
      color: $c-red;
    }

    &--compact {
      padding: rem(6) rem(18);
    }

    &--dt-red {
      background: $c-dt-red;
      color: $c-white;
    }

    &--dt-grey {
      background: $c-dt-linegrey;
      color: $c-white;
    }

    &--transparent {
      background: transparent;
      border: 1px solid $c-dt-linegrey;
      color: $c-dt-lightestgrey;
    }
  }
}