@import 'import.pcss';

.globalVodPage {
  .PlayerContainer {
    position: absolute;
    width: 100%;
    // to fix issue with iPad Pro in landscape mode with DownloadApp notice
    @media (min-width: 1025px) and (max-width: 1600px) and (orientation: landscape) {
      position: relative;
      height: 56.25vw;
    }
    @media $toDesktop and (orientation: portrait),
      @media $tablet and $toDesktop and (orientation: landscape) {
      .Player__inner {
        padding-bottom: calc(56.25vw + rem(65) + rem(138));
      }
    }
    @media $toDesktop and (orientation: landscape) {
      height: 100vh;
      .Player__inner {
        padding-bottom: 100vh;
      }
    }

    @media $desktop {
      height: 100%;
    }
  }

  .Player__inner {
    @media $desktop {
      padding-bottom: 0;
      height: auto;
    }
  }
}

.globalVodPage .vjs-control.vjs-progress-control {
  visibility: visible;
}
