@import 'import.pcss';

:local {
  .NavigationShelf {
    :global {
      .swiper-container {
        overflow: visible;
      }
    }

    .carousel > div {
      
    }
  }

  .carousel {
    position: relative;
    padding: 0;

    @media $toTablet {
      &:first-of-type {
        padding-right: $mobileComponentsHorizontalSpace;
      }
    }
  }
}

:global {
  .tabUniqueLevel {
    :local {
      .NavigationShelf {
        margin-left: 0;
        padding-right: 0;
        :global {
          .swiper-container {
            overflow: hidden;
          }
        }
      }
    }
  }
}
