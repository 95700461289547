@import 'import.pcss';

:local {
  .imageCard {
    display: block;
    width: 100%;
    border-radius: 1px;
    color: $c-white;
  }
}
