@import 'import.pcss';
:local {
  .heading-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 em(16);
    &--underline {
      padding-right: 0;
      padding-bottom: em(5);
      padding-left: 0;
      margin: 0 0 em(32);
      border-bottom: em(1) solid $c-dt-linegrey;
      @media $toMobile {
        margin-bottom: $standardVerticalSpaceBetweenComponents;
      }
      @media $desktop {
        margin: 0 0 em(32);
      }
    }
    &.noPadding {
     padding: 0;
    }

    @media $fromMobile {
      justify-content: space-between;
    }

    &LiveChannelSwitch {
      .title-container {
        @media $fromMobile {
          padding-left: 0;
        }
      }
    }
  }
  .title-container {
    display: flex;
    align-items: center;

    @media $tablet {
      padding-left: 0;
    }
    &--liveChannelHeading {
      @media $toTablet {
        padding: 0 rem(13);
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    font-size: rem(15);
    line-height: rem(20);
    font-family: $ff-content;
    font-style: normal;
    font-weight: $fw-600;
    color: $c-white;
    opacity: 0.9;
    @media $fromTablet {
      font-size: rem(16);
      line-height: rem(18);
    }
  }
  .watchlistTitle {
    line-height: rem(20);
  }
  .subtitle {
    display: flex;
    align-items: center;
    margin-left: em(9);
    margin-right: em(18);
    color: $c-bodygrey;
  }
  .live-circle {
    height: rem(5);
    padding-left: rem(8);
    vertical-align: middle;
  }
  .title-icon {
    height: em(25);
    /* TODO: need designs to determine this value */
    margin: 0 0 0 rem(8);
  }
  .market-locator-container {
    @media $toMobile {
      margin-right: rem(13);
    }
  }
  .to-top-link-container {
    position: absolute;
    top: em(5);
    right: 0;
    padding-top: em(5);
    color: $c-dt-lightergrey;
  }

  .watchlistIconWrapper {
    display: flex;
    margin-right: rem(9);
  }
}
