@import 'import.pcss';

.vjs-thumbnail-img-border-div {
  bottom: rem(47);
  opacity: 0;
  background-color: $c-app-bg;
  filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.55));
}
.vjs-thumbnail-img-arrow-div {
  position: absolute;
  width: rem(11);
  height: rem(5);
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='5' viewBox='0 0 11 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.55138 2.78333L0 0H11L8.44862 2.78333C6.86327 4.5128 4.13673 4.51279 2.55138 2.78333Z' fill='white'/%3E%3C/svg%3E%0A");
  bottom: rem(42);
  left: rem(-5);
  opacity: 0;
}

.vjs-thumbnail {
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    height: rem(30);
    width: 100%;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.66) 100%
    );
  }
}

// Customize thumbnail
.vjs-progress-control .vjs-thumbnail-holder {
  overflow: hidden;
  @media $toMobile and (orientation: portrait) {
    display: none;
  }
}

.vjs-progress-control:active .vjs-thumbnail-holder,
.vjs-progress-control:hover .vjs-thumbnail-holder {
  overflow: visible;
}
