@import 'import.pcss';

:local {
  .verifyEmail {
    padding-bottom: 0;
  }

  .verifyEmailContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: 100%;

    @media $fromMobile {
      padding-bottom: rem(67);
    }
  }

  .title {
    margin-bottom: rem(50);
    font-size: rem(16);
    font-weight: 600;
    color: $c-white;
  }

  .mailIcon {
    width: auto;
    height: rem(113.5);
    margin-bottom: rem(70);

    @media $fromMobile {
      margin-bottom: rem(89);
    }
  }

  .primaryMessage {
    margin-bottom: rem(60);
    font-size: rem(14);
    line-height: rem(25);
    color: $c-silver;

    @media $fromMobile {
      margin-bottom: rem(68);
    }
  }

  .secondaryMessage {
    font-size: rem(13);
    line-height: rem(22);
    color: $c-white;
    font-weight: $fw-300;
    margin-bottom: rem(25);
  }

  .primaryMessage,
  .secondaryMessage {
    text-align: center;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;

    .buttonCounterWrap {
      position: relative;
      margin-bottom: rem(13);
      height: rem(36);
      min-width: rem(225);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buttonMessageLink {
      @include resetBtn;
      border: 1px solid $c-ee-white-06;
      padding: rem(9) rem(12);
      line-height: rem(16);
      height: rem(36);
      border-radius: rem(2);
      transition: border 0.3s ease-in-out;

      span {
        color: $c-dt-lightgrey;
        font-size: rem(14);
        transition: color 0.3s ease-in-out;
      }

      &:hover {
        border: 1px solid $c-white;
        span {
          color: $c-white;
        }
      }

      &.showButton {
        opacity: 1;
        transition: opacity 1s ease-in-out 0.5s, border 0.3s ease-in-out;
      }

      &.hideButton {
        opacity: 0;
        transition: opacity 0.5s ease-in-out, border 0.3s ease-in-out;
        pointer-events: none;
      }
    }

    .supportMessage {
      font-size: rem(11);
      color: $c-dt-lightestgrey;
      transition: all 0.1s ease-in-out;

      &:hover {
        color: $c-white;
      }
    }
  }

  .link,
  .buttonMessageLink {
    @include resetBtn;

    padding: 0 rem(12);

    span {
      font-size: rem(11);
      line-height: rem(14);
    }
  }

  .emailSentMessage {
    margin-top: rem(15);
  }

  .counterContainer {
    position: absolute;
    display: flex;
    height: rem(36);

    .notificationMessage {
      border: 1px solid rgba(255, 255, 255, 0.15);
      padding: rem(9);
      font-size: rem(14);
      border-radius: 2px;
    }

    .counter {
      min-width: rem(39);
      margin-left: rem(5);
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: rem(9);
      font-size: rem(14);
      border-radius: rem(2);
    }

    &.hideCounterContainer {
      opacity: 0;
      transition: all 0.5s ease-in-out;
      pointer-events: none;
    }

    &.showCounterContainer {
      transition: all 1s ease-in-out;
      transition-delay: 0.5s;
      opacity: 1;
    }
  }
}
