@import 'import.pcss';

:local {
  .resetPassword {
    font-family: $ff-content;

    @media $fromMobile {
      min-height: rem(564);
      padding: 0 rem(52);
    }

    &.resetLink {
      :global(.gigya-composite-control.gigya-composite-control-link.resetLink) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    form {
      :global(.contactSupportWrapper) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: rem(26);
      }
    }

    :global {
      .resetPasswordTitle,
      .resetPasswordMessage,
      .resetPasswordInput,
      .resetPasswordSubmitButton,
      .resetPasswordSuccessHeading,
      .resetPasswordSuccessMessage,
      .resetPasswordSuccessSigninLink,
      .resetPasswordFormError {
        font-family: $ff-content;
        font-weight: $fw-600;
        color: $c-silver;
        font-size: rem(14);
        line-height: rem(18);
      }

      .gigya-composite-control.gigya-composite-control-link.resetLink {
        display: none;
      }

      .gigya-error-display.gigya-composite-control.gigya-composite-control-form-error.invalidLinkError.hideError {
        display: none;
      }

      .gigya-layout-row.subscribe-wrapper {
        position: relative;
      }

      .resetPasswordInput {
        position: relative;

        input.gigya-input-password {
          height: rem(56);
          background-color: transparent;
          color: $c-silver;

          border: none;
          box-shadow: none;
          border-radius: 0;
          border-bottom: 1px solid $c-silver;
          padding-left: 0;
          padding-top: rem(25);

          font-family: $ff-content;
          font-weight: $fw-600;
          font-size: rem(14);
          line-height: rem(18);

          &:active,
          &:focus {
            border-bottom: 1px solid $c-silver;
            padding-left: 0;
          }
        }

        input.gigya-input-password.gigya-error {
          border: none;
          box-shadow: none;
          border-radius: 0;
          border-bottom: 1px solid $c-silver;
          padding-left: 0;
          padding-top: rem(25);

          &:active,
          &:focus {
            border-bottom: 1px solid $c-silver;
            padding-left: 0;
          }
        }

        input.gigya-input-password.gigya-valid {
          border: none;
          box-shadow: none;
          border-radius: 0;
          border-bottom: 1px solid $c-silver;
          padding-left: 0;
          padding-top: rem(25);

          &:active,
          &:focus {
            border-bottom: 1px solid $c-silver;
            padding-left: 0;
          }
        }

        span.gigya-error-msg.gigya-error-msg-active {
          color: $c-new-error;
          font-family: $ff-content;
          font-size: rem(10);
          font-weight: $fw-500;
          text-align: left;
        }
      }

      .eyeIconNewPassClass {
        position: absolute;
        cursor: pointer;
        top: rem(32);
        right: rem(4);
      }

      .eyeIconRetypePassClass {
        position: absolute;
        cursor: pointer;
        top: rem(32);
        right: rem(4);
      }

      h2.gigya-composite-control.gigya-composite-control-header.resetPasswordTitle {
        font-size: rem(16);
        line-height: rem(20);
        color: $c-white;
        padding-bottom: rem(12);
      }

      .gigya-composite-control.gigya-composite-control-password.resetPasswordInput
        .gigya-input-password {
        &.gigya-error {
          padding-top: rem(30);
        }
        &:focus ~ .newPassFakePlaceholder,
        &:focus ~ .retypePassFakePlaceholder {
          transform: translateY(rem(-18));
          font-size: rem(10);
        }
      }

      .gigya-composite-control.gigya-composite-control-password.resetPasswordInput {
        position: relative;
        @include resetInput;

        .newPassFakePlaceholder,
        .retypePassFakePlaceholder {
          font-family: $ff-content;
          transition: 0.3s;
          color: $c-silver;
          position: absolute;
          top: rem(31);
          left: rem(3);
          font-size: rem(14);
          pointer-events: none;

          &.newPassFakePlaceholderTop,
          &.retypePassFakePlaceholderTop {
            transform: translateY(rem(-18));
            font-size: rem(10);
          }
        }
        .retypePassFakePlaceholder {
        }
      }

      .gigya-show-checkmark.gigya-valid {
        background: none;
      }

      .resetPasswordSubmitButton {
        padding-top: rem(18);
        .gigya-input-submit {
          border-radius: 1px;
          font-size: rem(14);
          height: rem(46);
          font-weight: $fw-600;
          background: $c-darkred;
          opacity: 0.5;
          pointer-events: none;
          font-family: $ff-content;

          &.newValid.retypeValid {
            background-color: $c-dt-red;
            opacity: 1;
            pointer-events: auto;
          }

          &:hover,
          &:active,
          &:focus {
            background-color: $c-red-new;
            box-shadow: none;
          }
        }
      }
      .gigya-screen .gigya-composite-control.gigya-composite-control-submit {
        padding-bottom: rem(5);
      }

      .gigya-screen .gigya-composite-control.gigya-composite-control-header {
        padding-bottom: rem(35);
      }

      .gigya-screen .gigya-composite-control.gigya-composite-control-password {
        height: rem(70);
        input {
          margin-bottom: rem(6);
        }
      }

      .gigya-screen .gigya-layout-cell {
        padding: 0 rem(10);
        margin: 0;
        width: auto;
        line-height: rem(10);

        &:first-child {
          border-right: 1px inset $c-ee-white-05;
        }
      }

      .gigya-screen .gigya-composite-control.gigya-composite-control-link {
        font-family: $ff-content;
        color: $c-white;
        font-size: rem(11);
        font-weight: $fw-600;
        text-decoration: none;
        outline: none;

        &:focus {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }

      .gigya-screen
        .gigya-composite-control.gigya-composite-control-link.resetLink {
        transition: all 0.3s ease-in-out;
        background: transparent;
        color: rgba(255, 255, 255, 0.6);
        border: 1px solid rgba(255, 255, 255, 0.6);
        border-radius: 2px;
        font-size: rem(14);
        width: fit-content;
        height: rem(36);
        padding: 0 rem(12);

        &:focus {
          border: 1px solid rgba(255, 255, 255, 0.6) !important;
        }
        &:hover {
          color: $c-white;
          border: 1px solid $c-white;
        }
      }

      .gigya-screen-loader {
        max-width: rem(24);
        max-height: rem(24);
        border-radius: 50%;
        border: solid rem(3) rgba(255, 255, 255, 0.4);
        border-top-color: rgba(255, 255, 255, 1);
        animation: loader-spinner 0.8s linear infinite;
        background: none;
        position: absolute;
        left: calc(50% - 12px);
        top: calc(50% - 12px);
      }

      .gigya-screen-dimmer {
        display: none;
      }
      .gigya-screen.portrait.mobile input[type='password'] {
        height: rem(56);
      }

      .gigya-screen.portrait.mobile input[type='text'] {
        height: rem(56);
      }

      .gigya-screen
        form.gigya-form-error
        .gigya-composite-control.gigya-composite-control-form-error.gigya-error-display-active {
        div {
          font-family: $ff-content;
          font-weight: $fw-500;
          font-size: rem(10);
          color: $c-new-error;
        }
      }

      .gigya-screen.portrait {
        width: 100%;
        padding: 0;
      }

      .gigya-screen.portrait.mobile:before {
        display: none;
      }

      .resetPasswordSuccessHeading {
        color: $c-dt-lightestgrey;
      }

      .gigya-error-msg.gigya-form-error-msg.gigya-error-msg-active {
        color: $c-dt-error;
        font-size: rem(12);
        font-weight: $fw-500;
        border: none;
      }

      a.gigya-composite-control.gigya-composite-control-link.resetPasswordSuccessSigninLink {
        &:hover {
          text-decoration: none;
        }

        width: 100%;
        height: rem(56);
        font-size: rem(14);
        line-height: rem(56);
        font-weight: $fw-600;
        color: $c-white;
        background: $c-dt-red;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
