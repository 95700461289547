@import 'import.pcss';
:local {
  .errorMessage {
    padding-top: rem(10);
    color: $c-new-error;
    font-size: rem(10);
    line-height: rem(13);
    text-align: center;
    font-weight: $fw-500;
  }
}
