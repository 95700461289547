@import 'import.pcss';

//***STYLING CC OVERLAYS***
.vjs-subs-caps-button.vjs-menu-button-popup {
  .vjs-menu {
    display: none;
    position: absolute;
    background: $c-dt-darkgrey-complementary;
    opacity: 0.8;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    width: rem(155);
    height: fit-content;
    min-height: rem(100);
    max-height: rem(290);
    top: rem(63);
    right: rem(24);
    left: auto;
    z-index: 3;
    padding: 0 rem(25) 0 rem(28);
    margin-bottom: 0;

    @media $toMobile {
      right: rem(14);
    }

    > ul {
      display: flex;
      flex-direction: column;
    }

    ul li:nth-child(3) {
      order: 1;
    }

    ul li:first-child {
      order: 3;
    }

    .vjs-menu-content {
      left: 0;
      position: static;
      overflow: visible;
      width: 100%;
      height: 100%;
      max-height: 15rem;
      padding: rem(10) 0 0 rem(3);

      .vjs-menu-item {
        margin: 0;
        padding: rem(5) 0 0 0;
        background: transparent;
        text-align: left;

        .vjs-menu-item-text {
          font-family: $ff-content;
          font-size: rem($fz-body4);
          font-weight: $fw-600;
          color: $c-white;
          opacity: 0.7;
        }

        &.vjs-texttrack-settings {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-top: 1.5px solid rgba(255, 255, 255, 0.1);
          border-radius: 0;
          padding-top: rem(10);
          padding-bottom: rem(15);
          margin-top: rem(20);
        }

        &.vjs-selected {
          .vjs-menu-item-text {
            opacity: 1;
            color: $c-white;
            line-height: rem(16);
            border-bottom: 1px solid #e6001e;
            padding-bottom: rem(2);
          }
        }

        &:hover,
        &:active,
        &:focus {
          text-shadow: none;
          outline: none;
        }

        .vjs-menu-item-img {
          img {
            padding-left: rem(12);
            width: rem(26);
          }
        }

        &.vjs-captions-menu-item .vjs-menu-item-text .vjs-icon-placeholder {
          display: none;
        }
      }
    }

    .captions-el {
      color: $c-white;
      font-family: $ff-content;
      font-size: rem($fz-body2);
      font-weight: $fw-600;
      line-height: rem(16);
      text-align: left;
      margin-top: rem(20);
    }

    //hide scrollbar

    &::-webkit-scrollbar {
      display: none;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      top: rem(-33);
      height: rem(40);
    }
  }

  &.vjs-hover,
  &:hover {
    .vjs-menu {
      display: block;
    }
  }
  &:last-child {
    margin-right: rem(3);
    @media $fromMobile {
      margin-right: rem(4);
    }
  }
}

.vjs-modal-dialog.vjs-text-track-settings {
  z-index: 60;
  border-radius: rem(2);
  width: rem(200);
  max-height: rem(290);
  left: auto;
  top: rem(63);
  right: rem(24);

  @media $toMobile {
    right: rem(14);
  }

  .vjs-modal-dialog-content {
    background: #171717;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    padding-left: rem(29);
    padding-right: rem(29);
    width: 100%;
    height: auto;

    .captions-settings-div {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .captions-settings-el {
        position: relative;
        color: rgba(255, 255, 255, 1);
        font-size: rem($fz-body3);
        font-weight: 600;
        padding-top: rem(4);
        padding-bottom: rem(12);
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .linear-gradient {
    z-index: 60;
    pointer-events: none;
    width: 100%;
    height: rem(61);
    position: sticky;
    top: 79%;
    background: linear-gradient(
      180deg,
      rgba(23, 23, 23, 0) -18.03%,
      rgba(23, 23, 23, 0.7) 100%
    );
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .vjs-close-button {
    left: rem(36);
    top: rem(29);
    height: rem(10);

    .vjs-icon-placeholder {
      background: url('../../assets/img/arrow-back.svg') no-repeat;
      width: rem(13);
      height: rem(13);
      margin-left: rem(-5);
      content: '';

      &:before {
        content: '';
      }
    }
  }

  .vjs-done-button {
    display: none;
  }

  .vjs-default-button {
    display: none;
  }

  div {
    fieldset {
      display: flex;
      flex-direction: column;
      border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);
      width: rem(147);
      padding-bottom: rem(10);
      padding-top: rem(3);
      margin-left: 0;
      padding-left: 0;

      legend {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
      }

      div {
        padding-left: rem(4);
        pointer-events: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
          color: #ffffff;
          opacity: 0.8;
          background-color: transparent;
          border: none;
          font-weight: 600;
          font-size: 12px;
          outline: none;
          margin-right: 0;
        }

        div {
          background: url('../../assets/img/arrow-down.svg') no-repeat;
          height: rem(12);
          width: rem(12);
        }
      }

      span {
        order: 2;
        padding-top: rem(8);
      }

      span > div {
        width: rem(147);
        max-width: none;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.video-js .vjs-modal-dialog {
  background: none;
}

// .vjs-cast-captions {
//   .vjs-icon-placeholder {
//     font-family: VideoJS;
//     &::before {
//       content: '\f10d';
//     }
//   }
// }
