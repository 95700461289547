@import 'import.pcss';

:local {
  .subNavigationLiveItem {
    height: rem(52);
    display: flex;

    &:hover {
      .titleContainer {
        background: #2c2c2c;
      }
    }

    @media $toDesktop {
    }
  }

  .iconContainer {
    padding: rem(4) rem(8);
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(67);
    border-top-left-radius: 1px;
  }

  .titleContainer {
    position: relative;
    padding: rem(10);
    font-size: rem(11);
    font-weight: 600;
    line-height: rem(15);
    color: white;
    background: #171717;
    transition: background-color 0.2s;
    width: calc(100% - 67px);
    display: flex;
    align-items: center;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;

    > span {
      display: block;
      white-space: normal;
      z-index: 1;
      @include ellipsis(2);
    }

    @media $desktop {
      padding: rem(10) rem(40) rem(10) rem(20);
      font-size: rem(12);
    }
  }

  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
