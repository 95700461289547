@mixin gradientAnimation($deg, $start, $percentageStart, $end, $percentageEnd, $transTime, $hoverColor){
    background-size: 100%;
    background-image: linear-gradient($deg, $start $percentageStart, $end $percentageEnd);
    position: relative;
    z-index: 0;
    &:before {
      background-image: linear-gradient($deg, $hoverColor $percentageStart, $hoverColor $percentageEnd);
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        opacity: 0;
        width: 100%;
        z-index: -1;
        transition: opacity $transTime;
    }
    &:hover {
        &:before {
          opacity: 1;
        }
    }
  }