@import 'import.pcss';

$toSmallerMobile: (max-width: 334px);
$smallerThanTablet: (max-width: 767px);
$fromMMobile: (min-width: 415px);
$toMMobile: (max-width: 414px);

:local {
  .mediaInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media $smallerThanTablet and (orientation: portrait) {
      align-items: center;
    }
  }

  .wrapper {
    display: flex;
    align-items: flex-end;
  }

  .logo {
    max-width: 300px;
    max-height: 100px;

    @media $toMMobile {
      max-width: 260px;
    }
  }

  .castWrap {
    display: flex;
    margin-bottom: rem(-6);
    padding-top: rem(24);

    .cast {
      font-size: rem(11);
      line-height: rem(13.97);
    }
  }

  .arrivingDateWrapper {
    margin-top: rem(15);
  }

  .featuredTag {
    padding-top: rem(15);
    margin-bottom: rem(-8);
    font-weight: $fw-600;
    line-height: rem(15);
  }

  .description {
    padding-top: rem(18);
    font-weight: $fw-300;
    line-height: rem(17);

    @media $tablet {
      line-height: rem(17);
    }
    @media $smallerThanTablet and (orientation: portrait) {
      text-align: center;
    }
  }

  .title {
    max-width: rem(360);
    font-size: rem(28);
    line-height: rem(33);

    @media $tablet {
      font-size: rem(35);
      line-height: rem(43);
    }
    @media $smallerThanTablet and (orientation: portrait) {
     text-align: center;
    }
    @media $toSmallerMobile {
      max-width: rem(300);
    }
    &.singleLineTitle {
      display: block;
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    padding-bottom: rem(17);
    padding-top: rem(21);

    .viewInfoButtonWrapper {
      margin-left: rem(20);
    }

    &.liveButtonsContainer {
      padding-bottom: rem(21);
      padding-top: rem(21);
    }

    &.wideFeatureActionButton {
      @media $toMobile {
        flex-direction: column;
      }
    }

    .liveTextWrapper {

      .liveText {
        font-weight: $fw-600;
        color: $c-white;
        font-size: rem(14);
      }
      .liveBullet {
        height: rem(5);
        padding-left: rem(9);
        vertical-align: middle;
      }
    }
  }

  .mediaInfoSingleFeature {
    .logo {
      max-height: rem(75);
    }
    .buttonsContainer {
      &.liveButtonsContainer {
        padding-top: rem(25);
        padding-bottom: 0;
      }
      &.watchlistPage {
        padding-bottom: rem(17);
      }
    }
  }

  .mediaInfoTwinFeature {
    @media $tablet and $toDesktop {
      align-items: center;
      text-align: center;
      .wrapper {
        min-height: rem(86);
      }
      .featuredTag {
        padding-top: rem(10);
        margin-bottom: 0;
      }
      .description {
        padding-top: rem(12);
      }
    }
    @media $desktop {
      .featuredTag {
        margin-bottom: 0;
      }
      .description {
        padding-top: rem(10);
      }
    }
  }
}
