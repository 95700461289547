html,
body {
  margin: 0;
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  font-family: $ff-content;
  background: $c-black;
}

// Prevents img added to the body for tracking
// from adding unwanted space.
body > img {
  display: none;
}

.pure-g [class*='pure-u'] {
  font-family: $ff-content;
}

// https://github.com/yahoo/pure/issues/380
.pure-g {
  &.pure-gutter {
    @media $fromMobile {
      margin-right: -0.1875em;
      margin-left: -0.1875em;

      & > [class*='pure-u'] {
        padding-right: 0.1875em;
        padding-left: 0.1875em;
      }
    }

    @media $toMobile {
      margin-right: -0.1875em;
      margin-left: -0.1875em;

      & > [class*='pure-u'] {
        padding-right: 0.1875em;
        padding-left: 0.1875em;
      }
    }
  }

  &.pure-gutter-lg {
    @media $desktop {
      margin-right: -0.1875em;
      margin-left: -0.1875em;
    }

    & > [class*='pure-u'] {
      @media $desktop {
        padding-right: 0.1875em;
        padding-left: 0.1875em;
      }
    }
  }
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: em(10) em(10);
  color: #fff;
  background: #0060b6;
}

.wh100 {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.flex-center-sm {
  @media $toMobile {
    display: flex;
    justify-content: center;
  }
}

.flex-center-tablet {
  @media $fromMobile {
    display: flex;
    justify-content: center;
  }
}

.show-md {
  @media $toMobile {
    display: none;
  }
}

.hide-md {
  @media $fromMobile {
    display: none;
  }
}

.reset-link {
  color: inherit;
  text-decoration: none;
}

// Scrollbar
::-webkit-scrollbar {
  width: 11px;
}

::-webkit-scrollbar-track {
  background: $c-dt-darkgrey;
}

::-webkit-scrollbar-thumb {
  background: $c-dt-mediumgrey;
}

::-webkit-scrollbar-thumb:hover {
  background: $c-dt-lightgrey;
}
html {
  --scrollbarBG: $c-dt-darkgrey;
  --thumbBG: $c-dt-mediumgrey;
}