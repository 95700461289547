@import 'import.pcss';

$toSmallerMobile: (max-width: 334px);
$smallerThanTablet: (max-width: 767px);
$fromMMobile: (min-width: 415px);
$toMMobile: (max-width: 414px);

:local {
  .mediaInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media $smallerThanTablet and (orientation: portrait) {
      align-items: center;
    }
  }

  .logoWrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: rem(19);

    @media $smallerThanTablet and (orientation: portrait) {
      margin-bottom: rem(12);
    }
  }

  .logo {
    max-width: rem(300);
    max-height: rem(100);

    @media $toMMobile {
      max-width: rem(260);
    }
  }

  .title {
    max-width: rem(360);
    font-size: rem(28);
    line-height: rem(33);

    @media $tablet {
    font-size: rem(35);
    line-height: rem(43);
  }
    @media $smallerThanTablet and (orientation: portrait) {
    text-align: center;
  }
    @media $toSmallerMobile {
      max-width: rem(300);
    }
    &.singleLineTitle {
      display: block;
    }
  }

  .subtitle {
    max-width: rem(355);
    font-size: rem(16);
    font-weight: 600;
    margin-bottom: rem(9);

    @media $smallerThanTablet and (orientation: portrait) {
      text-align: center;
    }

    @media $toSmallerMobile {
      max-width: rem(322);
    }

    &.singleLineTitle {
      display: block;
    }
  }

  .castWrap {
    display: flex;
    margin-bottom: rem(7);

    .cast {
      font-size: rem(11);
      line-height: rem(13.97);
    }
  }

  .description {
    font-size: rem(13);
    font-weight: $fw-300;
    line-height: rem(17);
    margin-bottom: rem(10);

    @media $smallerThanTablet {
      text-align: center;
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    margin: rem(9) 0 rem(22);
  }

  .progress {
    margin: rem(5) 0 rem(12);
  }

  .timeLeft {
    font-size: rem(10);
    line-height: rem(13);
    color: $c-white;
    opacity: 0.7;
    margin-top: rem(3);
  }
}
