@import 'import.pcss';

.ErrorPage {
  @include wh100;
  @include align-center;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: $mobileComponentsHorizontalSpace;
  padding-left: $mobileComponentsHorizontalSpace;
  background-position: center;
  background-size: cover;

  @media $fromLargeTablet {
    padding-right: $tabletComponentsHorizontalSpace;
    padding-left: $tabletComponentsHorizontalSpace;
  }

  @media $desktop {
    padding-right: $desktopComponentsHorizontalSpace;
    padding-left: $desktopComponentsHorizontalSpace;
  }

  @media $desktop {
    flex-direction: row;
  }
}

/*----------  Children  ----------*/
.ErrorPage {
  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--gradient {
      background: linear-gradient(180deg, transparent 0%, black 100%);
    }

    .Lozenge__text {
      text-transform: uppercase;
    }

    @media $desktop {
      height: 100%;
      align-items: flex-start;
    }
  }

  &__link {
    font-size: em(14);
    font-weight: $fw-600;
    line-height: 17px;
    color: $c-dt-lightestgrey;
    text-align: center;

    &:visited {
      color: $c-dt-lightestgrey;
    }
  }

  &--client-error .Title {
    color: $c-dt-lightestgrey;
  }

  &--client-error .Text {
    color: $c-dt-lightestgrey;
    font-weight: $fw-500;
    display: block;
    text-align: center;

    @media $desktop {
      text-align: left;
    }
  }

  &--client-error .Lozenge .Text {
    color: $c-dt-lightestgrey;
    font-weight: $fw-600;
  }

  &--client-error .Button__element {
    border-radius: em(3);
    font-weight: normal;
    height: 30px;
    width: 150px;
    padding: 4px;
  }
}

.ErrorPage img {
  width: 162px;
  height: auto;

  @media $fromTablet {
    width: 244px;
  }
}
