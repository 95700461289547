@import 'import.pcss';

.Spacing {
}

/*----------  Variables  ----------*/
$Spacing--md-h: 15;
$Spacing--md: 15;

/*----------  Mixins  ----------*/

@mixin Spacing--md-h($fz: 16) {
  padding-left: em($Spacing--md-h, $fz);
  padding-right: em($Spacing--md-h, $fz);
}

/*----------  Children  ----------*/
.Spacing {
  &--sm {
    margin-top: em(8);
  }
  &--md {
    margin-top: em(18);
  }
  &--mdlg {
    margin-top: em(20);
  }
  &--lg {
    margin-top: em(24);
  }
  &--lg-mobile {
    @media $toMobile {
      margin-top: em(32);
    }
  }
  &--md-tablet {
    @media $fromMobile {
      margin-top: em(16);
    }
  }
  &--lg-tablet {
    @media $fromMobile {
      margin-top: em(32);
    }
  }
  &--md-desktop {
    @media $desktop {
      margin-top: em(16);
    }
  }
  &--md-b {
    margin-bottom: em(16);
  }
  &--md-h {
    @include Spacing--md-h();
  }
  &--sm-bottom {
    margin-bottom: em(8);
  }
  &--md-bottom {
    margin-bottom: em(16);
  }
  &--lg-bottom {
    margin-bottom: em(24);
  }
  &--md-bottom-tablet {
    @media $fromMobile {
      margin-bottom: em(16);
    }
  }
  &--xl-bottom-tablet {
    @media $fromMobile {
      margin-bottom: em(40);
    }
    @media $desktop {
      margin-bottom: em(60);
    }
  }
  &--lg-bottom-desktop {
    @media $desktop {
      margin-bottom: em(40);
    }
  }
}

/*----------  Media Queries  ----------*/
// @media$toMobile{
// 	.Spacing{
// 		&--shelf{
// 			display: block;
// 			width: pc($page-width-mobile, $site-width-mobile);
// 			margin-left: auto;
// 			margin-right: auto;
// 		}
// 	}
// }

// Old query
@media $toMobile {
  .Spacing {
    &--shelf {
      padding-left: em(14);
      padding-right: em(14);
    }
  }
}
@media $fromMobile {
  .Spacing {
    &--shelf {
      padding-left: em(16);
      padding-right: em(16);
    }
  }
}

// @TODO: handle this with a loop
.Spacing2 {
  $parent: &;
  &--inner {
    position: relative;
  }
  &--md {
    $margin: 13;
    &#{$parent}--left {
      margin-left: em($margin);
    }
    &#{$parent}--top {
      margin-top: em($margin);
    }
  }
  &--lg {
    $margin: 16;
    &#{$parent}--left {
      margin-left: em($margin);
    }
    &#{$parent}--top {
      margin-top: em($margin);
    }
  }
}
