@import 'import.pcss';

$fz: 14;

:local {
  .dialog {
    position: fixed;
    // we have to make the z-index higher than the one used by the Overlay.scss
    // otherwise the user will not see the Quit Dialog in this screensize
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: calc($z-index-10100 + 10);
    display: none;
    padding: 1rem; /* make sure there's some space around even on small screens */
    color: $c-dt-lightgrey;
    overflow: hidden;

    p {
      margin-bottom: em(23);
      font-weight: $fw-400;
      line-height: 1.6;
      font-size: rem($fz-body2);
      color: $c-dt-lightgrey;
    }

    :global {
      .Button {
        color: $c-dt-mediumgrey;
      }

      .Button__element {
        width: 100%;
        padding: em(9) em(30);
        font-size: rem(14);
        font-weight: $fw-600;
        border: 1px solid $c-dt-lightergrey;
        border-radius: $radius-small;

        @media $fromMobile {
          width: initial;
        }

        &:hover,
        &:focus,
        &:active {
          color: $c-darkgrey;
          background-color: $c-dt-lightergrey;
          border-color: $c-dt-lightergrey;
        }
      }

      .Button--noBorder .Button__element {
        border: none;
        padding: 0;
      }

      .Button--noBackground {
        background: $c-transparent;

        .Button__element {
          background: $c-transparent;
          color: $c-dt-lightestgrey;

          &:hover {
            background-color: $c-transparent;
          }
        }
      }
    }
  }

  .open {
    display: block;

    @media $fromMobile {
      display: flex;
    }
  }

  .box {
    position: relative;
    z-index: 2; /* Backdrop component is z-index 1 */
    display: flex;
    flex-direction: column;
    align-self: center;
    max-height: 80vh;
    padding: rem(32);
    margin: 0 auto;
    background: $c-app-bg;
    border-radius: rem(1);
    outline: none;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.4);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @media $desktopM {
      max-height: 96vh;
    }
  }

  .content {
    position: relative;
    height: 100%;

    /**
      * This adds a subtle "fade out" of overflowing dialog content to make it
      * more obvious to the user that there is more content.
      */
    &::before {
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
      height: em(32);
      content: '';
    }
  }

  .close {
    position: absolute;
    top: rem(15);
    right: rem(15);
    padding: 0;
    transition: transform 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(50);
    height: rem(50);
    background: transparent;
    border: none;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      border-style: solid;
      border-width: 0 rem(50) rem(50) 0;
      border-color: transparent rgba(230, 0, 30, 0.1);
      border-bottom-right-radius: rem(1);
      border-top-left-radius: rem(1);
    }
    &:hover::before {
      border-color: transparent rgba(230, 0, 30, 0.2);
    }
    img {
      position: absolute;
      top: rem(12);
      right: rem(11);
    }
  }

  .blackThemeClose {
    position: absolute;
    height: rem(50);
    top: rem(6);
    right: rem(6);
    background: transparent;
    border-style: solid;
    border-width: 0 rem(50) rem(50) 0;
    border-color: transparent rgba(230, 0, 30, 0.1);
    border-bottom-right-radius: rem(1);
    border-top-left-radius: rem(1);
    clip-path: polygon(100% 0, 30% 0, 100% 100%);
    color: $c-red-new;

    img,
    svg {
      position: relative;
      top: rem(6);
      left: rem(47);
    }
    &:hover {
      border-color: transparent rgba(230, 0, 30, 0.2);
    }
  }

  .title {
    margin-top: em(8);
    margin-bottom: em(20.8);
    font-weight: $fw-700;
    color: $c-dt-lightestgrey;
    font-size: rem(18);
  }

  .buttons {
    margin-top: rem(16);
    margin-bottom: rem(-8);
    text-align: center;

    & > :global(.Button) {
      @media $fromMobile {
        display: inline-block;
      }
    }

    & > :global(.Button + .Button) {
      @media $fromMobile {
        margin-left: 20px;
      }

      & > :global(.Button__element) {
        margin-top: 14px;

        @media $fromMobile {
          margin-top: 0;
        }
      }
    }
  }

  .center > .box {
    text-align: center;
  }

  .wide > .box {
    width: rem(544);
  }

  .narrow > .box {
    max-width: rem(384);
  }

  .black > .box {
    background: $c-black;
    padding: 0 rem(32);
    overflow-y: auto;
    max-height: 100%;
  }

  .black > .fixedPaddingBox {
    padding-bottom: rem(50);
  }

  .noFade .content::before {
    display: none;
  }

  .fullScreen {
    @media $toMobile {
      padding: 0rem;
    }
  }

  .fullScreen .content {
    margin-top: rem(49);

    @media $toMobile {
      display: block;
    }
  }

  .fullScreen > .box {
    @media $toMobile {
      max-height: 100%;
      max-width: 100%;
      padding: rem(20);
      width: 100%;
      height: 100%;
      border-radius: 0;
      margin: 0px;
    }
  }

  .buttonsStacked {
    .buttons {
      display: flex;
      flex-direction: column;

      :global {
        .Button,
        .Button__element {
          font-weight: $fw-600;
          font-size: rem(13);
          margin: rem(5) 0;
          color: inherit;
          border: inherit;
          &:hover,
          &:focus,
          &:active {
            color: inherit;
            background-color: inherit;
            border-color: inherit;
          }
        }

        .Button--dtRed .Button__element {
          border-radius: rem(4);
          color: #fff;
          font-size: rem(14);
          padding: em(14, $fz) em(26.5, $fz);
          line-height: 1.143; //= 16 / 14
          border: em(1) solid transparent;
          text-align: center;
          width: 100%;
          &:hover,
          &:focus,
          &:active {
            background-color: $c-dt-red;
          }
        }
      }
    }
  }

  .backdrop {
    // position: static;
  }

  .link {
    color: $c-dt-lightestgrey;
  }

  .lockWithoutScroll {
    overflow: hidden;
    @media $fromTablet {
      padding-right: 11px;
    }
  }
}
