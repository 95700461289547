/* TODO: Refactor into stand-alone classes */

/* TODO: Once we get rid of this then remove the `postcss-extend-rule` package
from `utils/postcss-swm/package.json` and `utils/postcss-swm/index.js` */

/* TODO: If for some reason we decide to keep this functionality it should at
least have a description about what the purpose is for each placeholder */

%link {
  font-size: rem(12);
  color: $c-dt-lightestgrey;
  text-decoration: none;

  @media $desktopM {
    font-size: rem(14);
  }
}

%submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: rem(56);
  padding: 0;
  font-size: rem(14);
  line-height: rem(56);
  color: $c-white;
  background: $c-dt-red;
  border: none;
  border-radius: rem(3);
  font-weight: $fw-600;

  &:hover,
  &:focus {
    background: darken($c-dt-red, 20);
  }

  &:active {
    box-shadow: inset em(0) em(0) em(2) darken($c-dt-red, 20);
  }

  &[disabled] {
    background: $c-dt-darkgrey;
  }
}

/* Center alignment */

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%link-as-button {
  @extend %flex-center;

  text-decoration: none;
  border-radius: rem(6);
}
