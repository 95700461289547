@import 'import.pcss';

:local {
  .locationShareContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: rem(85) 10% 0;

    .backLink {
      position: absolute;
      top: rem(24);
      left: rem(14);
    }

    .locationShareWrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      min-height: 100%;
      max-width: rem(300);

      .locationShareContentWrap {
        display: flex;
        flex-direction: column;
        flex: 1;

        .spinnerWrap span {
          display: block;
        }
      }

      .title {
        font-weight: $fw-900;
        font-size: rem(24);
        line-height: rem(28);
        color: $c-white;
        margin: rem(10) 0 rem(22);
      }

      .description {
        font-weight: $fw-500;
        font-size: rem(16);
        line-height: rem(20);
        color: $c-lightergrey;
        margin-bottom: rem(26);
      }

      .selectAllow {
        color: $c-white;
        font-weight: $fw-bold;
        font-size: rem(16);
        line-height: rem(20);
      }

      .locationShareContent,
      .locationPromptContent {
        display: flex;
        flex-direction: column;
        flex: 1;

        .buttonWrap {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .button {
            height: rem(46);
            border-radius: rem(2);
            text-align: center;
            text-decoration: none;
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: rem(10);
            font-size: rem(14);
            font-weight: $fw-500;
            outline: none;
            border: none;
          }

          .redButton {
            background-color: $c-dt-red;
            color: $c-white;
            margin-bottom: rem(8);
          }

          .whiteButton {
            background-color: $c-white;
            color: $c-dt-red;
          }
        }
      }

      .locationShareContent .buttonWrap {
        margin-bottom: 18vh;
      }
      .locationPromptContent {
        .promptWrap {
          display: flex;
          flex-direction: column;
          background-color: $c-ee-white-02;
          margin: rem(16) calc(150px - 50vw);
          padding: rem(27) calc(50vw - 160px) rem(24) calc(50vw - 150px);
          font-size: rem(15);
          line-height: rem(18);
          color: $c-white;
          font-weight: $fw-500;

          .dontSee {
            font-size: rem(17);
            line-height: rem(22);
            font-weight: $fw-600;
            color: $c-white;
            margin-bottom: rem(18);
          }

          .dontSeeDescription {
            margin-bottom: rem(22);
            display: flex;
            flex-direction: row;
          }
          .appWrap {
            .appIcon {
              margin-top: rem(16);
              height: rem(32);
            }
          }
        }

        .buttonWrap {
          margin-bottom: 8.8vh;
        }
      }
    }

    @media $fromMobile {
      padding: rem(165) 10% 0;
      .locationShareWrap {
        max-width: rem(427);
        .locationShareContent,
        .locationPromptContent {
          .buttonWrap {
            margin-top: rem(148);
            flex: none;
          }
          .promptWrap {
            margin: rem(16) calc(427px / 2 - 50vw);
            padding: rem(27) calc(50vw - 427px / 2 - 10px) rem(24)
              calc(50vw - 427px / 2);
          }
          .promptWrap ~ .buttonWrap {
            margin-top: rem(25);
          }
        }
      }
    }
  }
}
