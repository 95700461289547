@import 'import.pcss';

:local {
  .carousel {
    position: relative;
    padding: 0;
    overflow: hidden;
  }

  .singleVodChild {
    width: calc(100vw - rem(10));

    @media (min-width: 1200px) {
      width: 100%;
    }
  }

  .twinVodChild {
    width: rem(318);
    @media $desktop {
      max-width: none;
      width: calc((100vw - 3 * 8px - 6px) / 4 - 3px);
    }
    @media (min-width: 1920px) {
      width: rem(474);
    }
  }

  .singleVodChild.twinVodChild {
    width: 100%;
    max-width: calc(100vw - rem(10));
    @media $tablet {
      max-width: 100%;
    }
    @media $desktop {
      width: 100%;
    }
    @media (min-width: 1920px) {
      width: 100%;
    }
  }
}