@import 'import.pcss';

:local {
  .forgotPasswordContainer {
    padding-bottom: rem(66);

    .pageTitle {
      margin-bottom: rem(30);
      font-size: rem(16);

      @media $desktop {
        margin-bottom: rem(53);
      }
    }
  }
}
