/* FIXME: Split this out into multiple files, one file for each component */

@import 'import.pcss';

:local {
  .Section {
  }

  /*----------  Modifiers  ----------*/
  .Section--page {
    position: relative;
    max-width: em($page-width);
    width: 100%;

    + .Section--page > * {
      margin-top: $standardVerticalSpaceBetweenComponents;
      margin-left: $mobileComponentsHorizontalSpace;

      @media $tablet {
        margin-left: $tabletComponentsLargeHorizontalSpace;
        margin-right: $tabletComponentsLargeHorizontalSpace;
        padding-right: 0;
      }

      @media $desktop {
        margin-left: $desktopComponentsHorizontalSpace;
        margin-right: $desktopComponentsHorizontalSpace;
      }
    }

    > :global(.markdownPanelArticle) {
      margin-right: auto;
      margin-left: auto;
    }

    // IE11 position:fixed fix for Header element
    &.Section--navigation {
      position: static;
      margin-bottom: 0;
    }
  }

  .Section--borderBottom {
    @media $desktop {
      padding-bottom: $standardVerticalSpaceBetweenComponents;
      border-bottom: em(1) solid $c-dt-lightgrey;
    }
  }

  .Section--banner,
  .Section--heroBanner {
    width: 100%;
    max-width: none;
  }

  .Section--inputField {
    position: relative;
  }

  .Section--live {
    position: relative;
  }

  .Section--navigation {
    z-index: calc($z-index-100 - 1);
    height: em($height-header-mobile);

    @media $fromMobile {
      height: em($height-header);
    }
  }

  .Section--floatingNavigation {
    z-index: calc($z-index-100 - 1);
  }

  .Section--footer {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-top: auto;
  }

  .Section--shelfContainer {
    width: 100%;
    max-width: 100%;
  }

  .Section--carousel {
    width: 100%;
    max-width: none;
  }

  .Section--page + .Section--notificationPanel {
    margin-top: $standardVerticalSpaceBetweenComponents;
  }

  .Section--notificationPanel {
    // in mobile, we want to add a top/bottom spacing around the notification
    // we want to make it 100% so when we set background-color it'll take the whole line
    width: 100%;
    // we don't want to limit the max width,
    // otherwise it won't set the background-color for the whole line in big monitors
    max-width: none;
    // TODO: remove 'css block' below from here
    //       since it'll come from config service (as other css information),
    //       therefore it'll be added inline based on the theme (theme comes from capi)
    background-color: $c-black;
    color: $c-white;
    font-size: 14px;
  }

  .Section--iFrame {
    width: 100%;
    max-width: 100%;
  }

  .Section--page.Section--featureShelf {
    > * {
      margin-left: 0;
      margin-right: 0;
      margin-top: rem(45);
    }
    + .Section--page:not(.Section--featureShelf) > * {
      margin-top: rem(45);
    }
    + .Section--page.Section--featureShelf > * {
      margin-top: rem(3);
      margin-bottom: rem(2);
    }
  }

  .Section--vod {
    display: flex;
    flex-direction: column;

    + .Section--page + .Section--page.Section--shelfContainer > * {
      margin-top: rem(16);
    }
  }
}
