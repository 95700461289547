@import 'import.pcss';

:local {
  .forgotPasswordForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media $fromMobile {
      padding: 0 rem(51.5) rem(110) rem(51.5);
    }
  }

  .forgotPasswordContainer {
    margin-top: rem(-14);
    text-align: right;
  }

  .link {
    @extend %link;

    color: $c-dt-lightestgrey;
    margin-left: rem(5);
    font-weight: $fw-500;
  }

  :global(.FormInput__label) {
    margin: 0 0 1rem;
  }

  .errorMessage {
    max-width: rem(320);
    margin-top: rem(10);
    margin-bottom: rem(-27);
  }

  .formSection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .formDescription {
    margin-top: rem(50);
    margin-bottom: rem(106);
    font-size: rem(14);
    line-height: rem(17);
    color: $c-silver;
    text-align: center;

    @media $fromMobile {
      padding: 0 rem(46);
    }
  }

  .footer {
    margin-top: rem(51);
    color: $c-dt-lightgrey;
  }

  .forgotPasswordInput {
    input {
      border-bottom: rem(1) solid $c-silver;
    }

    span {
      color: $c-silver;
      font-size: rem(14);
    }
  }

  .submitingSubmitWrapper {
    width: 100%;
  }

  .submitWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(70);

    a {
      width: 100%;
    }

    @media $toDesktop {
      width: 100%;
    }
  }

  .button {
    border-radius: 1px;
    background-color: $c-dt-red;
    font-size: rem(14);
    color: $c-white;
    height: rem(46);
    font-weight: 600;
    width: 100%;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
    border-radius: 1px;

    &[disabled] {
      background-color: $c-darkred;
      color: $c-ee-white-05;
    }

    &:not([disabled]):hover {
      background-color: $c-red-new;
      color: $c-white;
    }
  }
}
