@import 'import.pcss';

:local {
  .card {
    position: relative;
    height: 100%;
    z-index: 0;
    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }

    &.blackBckgCard {
      background-color: $c-app-bg;
      min-height: rem(175);

      @media $desktopM {
        min-height: rem(205);
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $c-app-bg;
        transition: background-color 0.2s;
      }

      &:hover::before {
        background-color: $c-nero;
      }

      .title {
        font-size: rem(12);
        line-height: rem(15);
      }

      .subtitleVideoPlayerPage {
        font-size: rem(10);
        color: $c-ee-darkgrey-single;
      }

      .progressWrapper {
        padding: 0 rem(5);
      }

      .imageWrapper {
        padding: rem(5) rem(5) 0 rem(5);
        flex: 1;

        @media $fromMobile {
          width: 100%;
          padding: rem(5) rem(5) 0 rem(5);
        }
      }

      .contentWrapper {
        display: block;
        position: relative;
        padding: rem(10) rem(12) rem(9) rem(13);
        margin: 0;

        @media $fromMobile {
          display: flex;
          width: 100%;
          padding: rem(13) rem(14) rem(9) rem(15);
        }
        .titleWrapper {
          position: relative;
        }
        .subtitleWrapper {
          line-height: rem(11);
        }

        .durationExpirationWrapper {
          display: flex;
          margin-top: rem(6);

          @media $toMobile {
            margin-top: rem(4);
          }

          .expirationText {
            margin-left: rem(10);
            color: $c-red-new;
            font-size: rem(10);
            text-transform: uppercase;
          }
        }
      }

      .synopsisWrapper {
        padding: 0 rem(12) rem(14) rem(13);
        line-height: rem(14);
        position: relative;
        overflow: hidden;

        @media $tablet {
          padding: 0 rem(14) rem(14) rem(15);
        }
      }
    }

    &.lozenge {
      background-color: $rosewood;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: $rosewood;
        transition: background-color 0s;
      }

      &:hover::before {
         background-color: $rosewood;
      }
      &:hover .playWrapper {
        opacity: 0;
      }
    }

    &:hover .playWrapper {
      opacity: 1;
    }
    // start: latest episodes (home page) styling, short form cards styiling
    &.latestEpisodesShortFormCards {
      background-color: $c-ee-lightblack;
      border-radius: 1px;
      min-height: 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: black;
        transition: background-color 0.2s;
      }

      .subtitleTwoStylesWrapper {
        display: flex;

        span {
          &:first-child {
            color: $c-ee-white-08;
          }
        }
      }

      &:hover::before {
        background-color: $c-nero;
      }

      .title {
        font-size: rem(12);
        line-height: rem(15);
        margin-bottom: rem(4);
        opacity: 1;
      }

      .subtitle {
        font-size: rem(12);
        line-height: rem(16);
      }

      .progressWrapper {
        padding: 0 rem(5);
      }

      .imageWrapper {
        padding: 0;
        flex: 1;

        @media $fromMobile {
          width: 100%;
        }
      }

      .contentWrapper {
        display: block;
        position: relative;
        padding: rem(10) rem(11) rem(9) rem(11);
        height: rem(70);
        width: 100%;
        margin: 0;

        .textWrapper {
          height: 100% !important;
          padding-top: rem(11) !important;
          width: 100%;
        }

        .titleWrapper {
          position: relative;
        }

        .subtitleWrapper {
          line-height: rem(11);
          width: 100%;
        }
      }
      // end: latest episodes (home page) styling, short form cards styiling
    }
  }

  .imageWrapper {
    position: relative;
    border-radius: 1px;
    overflow: hidden;

    .lozengeWrapper {
      position: absolute;
      bottom: 0;
      left: rem(5);
      z-index: 1;

      @media $toMobile {
        font-size: 0.9em;
        bottom: 0;
        left: rem(5);
      }
    }
  }

  .progressWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
  }

  .playWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: rem(-15) 0 0 rem(-15);
    z-index: 1;
    opacity: 0;
    transition: 0.2s;
  }

  .contentWrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: rem(4) 0 0 0;
  }

  .link {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  .card-in-grid {
    padding: 0;
    height: 100%;
    min-width: 100%;

    .rowWrapper {
      align-items: stretch;

      @media $fromMobile {
        display: block;
      }
    }
  }

  .card-in-grid:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .episodeCardTitle {
    font-size: rem(13);
  }

  .title {
    font-size: rem(14);
    line-height: rem(18);
  }

  .subtitle {
    font-size: rem(12);
  }
}
