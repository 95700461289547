/* Text */

.text-center {
  text-align: center;
}

.darkgrey {
  color: $c-darkgrey;
}

/* Links & Buttons */

/* makes buttons look and behave like a link */
.button-link {
  display: inline-block;
  width: unset !important;
  padding: 0 !important;
  margin: 0;
  font-size: unset;
  font-weight: $fw-600;
  line-height: inherit;
  color: $c-white;
  background: transparent;
  border: 0 !important;

  &:hover,
  &:focus,
  &:active {
    color: $c-dt-red;
  }
}

/* Document */

.scroll-lock {
  /* stylelint-disable declaration-no-important */
  position: fixed !important;
  height: 100% !important;
  overflow-y: hidden !important;
  /* stylelint-enable declaration-no-important */
}
