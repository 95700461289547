@import 'import.pcss';

:local {
  .accountManagementPageSectionTitle {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media $toDesktop {
      margin-bottom: rem(26);
      align-items: start;
    }

    @media $desktop {
      align-items: center;
      max-width: fit-content;
    }

    .icon {
      width: rem(35.5);
      height: rem(41);
      margin-bottom: rem(14.75);

      @media $toDesktop {
        width: rem(27.45);
        height: rem(31.41);
        margin-bottom: rem(5);
      }
    }

    .title {
      font-size: rem(22);
      text-align: center;

      @media $toDesktop {
        font-size: rem(18);
      }
    }
  }
}
