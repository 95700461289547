@import 'import.pcss';

.Player__video.video-js.vjs-fullscreen {
  @media $toDesktop and (orientation: portrait) {
    .vjs-tech {
      height: calc(100% - rem(65)) !important;
    }
    .vjs-ima3-ad-container {
      height: calc(100% - rem(65) - rem(138)) !important;
      iframe {
        height: 100% !important;
      }
    }
    .vjs-ad-info-wrap {
      bottom: rem(69);
      top: auto;
    }
  }
}

.video-js.vjs-fullscreen > .vjs-control-bar > .vjs-fullscreen-control {
  .vjs-icon-placeholder {
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='23' viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.71731 1.95654H2.17383V7.38511' stroke='%23fff' stroke-width='2.5' stroke-linejoin='round'/%3E%3Cpath d='M2.17383 16.4327L2.17383 20.9565L6.71731 20.9565' stroke='%23fff' stroke-width='2.5' stroke-linejoin='round'/%3E%3Cpath d='M19.935 1.95654H24.4785V7.38511' stroke='%23fff' stroke-width='2.5' stroke-linejoin='round'/%3E%3Cpath d='M24.4785 15.9804L24.4785 20.9565L19.522 20.9565' stroke='%23fff' stroke-width='2.5' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.video-js.vjs-fullscreen {
  .vjs-sd-fullscreen-notice {
    width: 100%;
    display: block;
    background: transparent;
    position: absolute;
    bottom: rem(136);
    @media $desktop and (orientation: landscape) {
      bottom: rem(152);
    }
  }
}
