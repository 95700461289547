@import 'import.pcss';

:local {
  :global(.Overlay__portal) .centralisedPanel {
    // NOTE: this is required for all screens that there is an 'Overlay__portal'
    //       around 'centralisedPanel', example Sign In and Registration screens,
    //       specially on mobile iphone5
    padding-right: 0;
  }

  :global(.Overlay__portal) .contentWrapper {
    // NOTE: this is required for all screens that there is an 'Overlay__portal'
    //       around 'contentWrapper', example Sign In and Registration screens,
    //       specially on mobile iphone5
    margin-right: 0;
  }

  .centralisedPanel {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: 100%;

    // NOTE: this is required for all screens that 'centralisedPanel' is rendered
    //       as a direct child of 'Section--page' like the /connect pagee
    //       specially on mobile iphone5
    padding-right: $mobileComponentsHorizontalSpace;
    color: $c-dt-lightestgrey;
    outline: none;

    @media $fromLargeTablet {
      padding-right: $tabletComponentsHorizontalSpace;
    }

    @media $desktopM {
      padding-right: $desktopComponentsHorizontalSpace;
    }
  }

  .contentWrapper {
    width: 100%;
    min-width: rem(300);
    // NOTE: this is required for all screens that 'contentWrapper' is rendered
    //       as a direct child of 'Section--page' like the /connect pagee
    //       specially on mobile iphone5
    margin-right: $mobileComponentsHorizontalSpace;

    @media $tablet {
      margin-right: calc($tabletComponentsLargeHorizontalSpace * 2);
    }

    @media $desktop {
      margin-right: calc($desktopComponentsHorizontalSpace * 2);
    }

    @media $fromMobile {
      max-width: rem(546);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem(5) rem(30);

    @media $fromMobile {
      padding: rem(37) rem(84);
      padding-bottom: rem(111);
    }
  }

  .border {
    background: $c-black;

    @media $fromMobile {
      background: $c-black;
      border-radius: 1px;
    }

    @media $toMobile {
      padding-top: rem(37);
      padding-bottom: rem(243);
    }
  }

  .blackBckg {
    background: $c-black;
  }

  .transparentBckg {
    background: transparent;
  }

  .borderInTabletOnly {
    @media $fromMobile {
      background: $c-white;
      border: 1px solid $c-linegrey;
      border-radius: 1px;
    }

    @media $desktopM {
      background: none;
      border: none;
    }
  }

  .siteLogo {
    margin-bottom: rem(82);

    & > img {
      display: block;
      width: rem(71);
      min-width: rem(71);
      height: auto;

      @media $fromMobile {
        width: rem(80);
      }

      @media $tablet {
        width: rem(92);
      }
    }

    @media $fromMobile {
      margin-bottom: rem(34);
    }
  }

  .footer {
    padding-top: 0;
    margin-top: rem(30);
    margin-bottom: rem(10);
    color: $c-dt-lightgrey;
    a {
      color: $c-dt-lightestgrey;
    }
  }

  .footerAlignBottom {
    @media $toMobile {
      padding-top: rem(20);
      margin-top: auto;
    }

    @media $desktopM {
      margin-top: auto;
    }
  }
}
