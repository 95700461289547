@import 'import.pcss';

:local {
  .connectToWifiContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem(30);
    font-size: rem(16);
    line-height: rem(20);
    font-weight: $fw-500;
    color: $c-white;
    width: 100%;
    height: 100%;

    > div {
      max-width: rem(296);
    }

    .icon {
      margin-bottom: rem(33);
    }

    .title {
      margin-bottom: rem(41);
    }

    p {
      font-size: rem(16);
      line-height: rem(20);
      font-weight: $fw-500;
      color: $c-white;
      white-space: pre-line;
    }

    .buttons {
      margin: rem(26) 0 rem(37);
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;

      .button {
        width: 100%;
        margin-top: rem(16);
        text-decoration: none;
        border-radius: rem(2);
        min-height: rem(46);
        font-size: rem(14);
        line-height: rem(18);
        padding: rem(14) rem(32);

        &.buttonRed {
          background-color: $c-dt-red;
          color: $c-white;
        }
        &.buttonWhite {
          background-color: $c-white;
          color: $c-dt-red;
        }
      }
    }

    @media $fromMobile {
      padding-top: rem(86);

      > div,
      p {
        max-width: rem(427);
      }

      .buttons {
        flex: none;
        margin-top: rem(126);
      }
    }
  }
}
