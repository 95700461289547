@import 'import.pcss';

.globalLivePage {
  .lineSeparator {
    margin: 0;
  }
}

.globalLivePage {
  .PlayerContainer {
    width: 100%;

    @media $desktop {
      max-height: rem(650);
      overflow: hidden;
    }

    &.PlayerContainer--overrides {
      margin: 0;
    }

    @media $toDesktop and (orientation: portrait),
      @media $tablet and $toDesktop and (orientation: landscape) {
      .Player__inner {
        padding-bottom: calc(56.25vw + rem(65) + rem(76));
      }
      .vjs-title-wrap {
        display: none;
        @media $fromTablet {
          left: rem(11);
          bottom: rem(83);
        }
      }
      .vjs-control-bar {
        bottom: 0;

        .vjs-seek-to-live-control,
        .vjs-live-control {
          position: absolute;
          right: rem(53);
          bottom: rem(31);
          transition: opacity 0.2s linear, transform 0.4s ease 0s;
        }
      }
    }
    @media $toDesktop and (orientation: landscape) {
      height: 100vh;
      .Player__inner {
        padding-bottom: 100vh;
      }
    }
  }

  .Player__inner {
    @media $desktop {
      padding-bottom: 0;
      height: auto;
      max-height: rem(650);
    }
  }

  .downloadAppWrap.downloadAppWrap {
    margin: 0;
  }
  .vjs-top-bar .vjs-top-bar-news-upgrade-cc-cast-wrapper {
    justify-content: flex-end;
  }
}
