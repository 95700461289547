@import 'import.pcss';

:local {
  .logoLink {
    margin: rem(20);

    @media $fromLargeTablet {
      margin: rem(56);
    }

    & > img {
      display: block;
      width: rem(36);
      min-width: rem(36);
      height: auto;
      margin: 0 auto;

      @media $fromMobile {
        width: rem(45);
      }
    }
  }
}
