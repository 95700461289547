@import 'import.pcss';

.Spinner {
  $spinnerSize: rem(24);
  position: relative;
  width: $spinnerSize;
  height: $spinnerSize;
  border-radius: 50%;
  border: solid rem(3) rgba(255, 255, 255, 0.4);
  border-top-color: rgba(255, 255, 255, 1);
  animation: loader-spinner 0.8s linear infinite;
}

@keyframes loader-spinner {
  100% {
    transform: rotate(360deg);
  }
}
