@import 'import.pcss';

:local {
  .basicInformationContainer {
    display: flex;
    flex-direction: row;

    @media $toDesktop {
      margin-top: rem(29);
      justify-content: center;
      align-items: center;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: rem(15);
    }

    @media $toDesktop {
      flex-direction: column;
    }

    .label {
      min-width: rem(65);
      margin-right: rem(23);
      text-align: right;
      font-size: rem($fz-body2);

      @media $toDesktop {
        font-size: rem($fz-body4-mobile);
        text-align: left;
      }
    }

    .value {
      @include ellipsis(2);
      display: block;
      max-width: 38vw;

      @media $toDesktop {
        max-width: 92vw;
      }
    }
  }
}
