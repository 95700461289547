@import 'import.pcss';

:local {
  .accountManagementPage {
    display: grid;
    grid-template-rows: max-content 1fr;
    background: $c-black;
    min-height: 100vh;
    padding-top: rem(40);
    padding-bottom: rem(32);

    @media $toMobile {
      padding-top: rem(78);
    }

    .mainSection {
      background-color: $c-app-bg;
      margin: 0 rem(14);
      padding: rem(21);

      @media $toDesktop {
        margin: 0;
        padding: 0;
        padding-bottom: rem(15);
      }

      .personalInfoContainer {
        margin-bottom: rem(15);
      }

      .helpSection {
        padding-left: rem(111);

        @media $toDesktop {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
