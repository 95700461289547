@import 'import.pcss';

:local {
  .contentPromptWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $c-white;
    font-size: rem($fz-body2);
    font-weight: $fw-600;

    .firstRowText {
      padding-bottom: rem(3);
    }
    .passwordResetText {
      padding-bottom: rem(11);
    }
    &.textMobile {
      @media $toMobile {
        font-size: rem($fz-body3);
      }
    }
  }
  .whiteBorederedButtonWrapper {
    display: flex;
    margin-top: rem(11);

    .btnLink {
      font-size: rem(12); 
      transition: all 0.3s ease-in-out;
      border: 1px solid $c-white;
      border-radius: 1px;
      background: $c-transparent;
      color: $c-white;
      padding: rem(10) rem(14) rem(11) rem(14);
      margin-right: rem(7);

      &:hover {
        background: $c-white;
        color: $c-red-new;
      }
    }
    .btn {
      transition: all 0.3s ease-in-out;
      border: 1px solid $c-ee-white-04;
      border-radius: 1px;
      color: $c-ee-white-06;
      padding: rem(10) rem(14) rem(11) rem(14);
      &:hover {
        background: $c-white;
        border: 1px solid $c-white;
        color: $c-red-new;
      }

    }
  }
}
