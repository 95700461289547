@import 'import.pcss';

:local {
  .closeProgressRing {
    cursor: pointer;
    @extend %flex-center;
    position: absolute;
    top: rem(5);
    left: rem(24);

    > svg {
      position: relative;
    }
    transition: all 0.3s ease-in-out;

    &:hover {
      .closeIcon {
        svg {
          path {
            stroke: $c-white;
          }
        }
      }
    }
  }

  .trackCircle {
    width: rem(21);
    height: rem(21);
    position: absolute;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 100%;
  }

  .indicatorCircle {
    transition: stroke-dashoffset 1s linear;
    transform: rotate(-90deg);
  }

  .closeIcon {
    display: flex;
    @extend %flex-center;
    z-index: 100000;
    position: absolute;
  }
}
