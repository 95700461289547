@import 'import.pcss';

:local {
  .mandatorySignInOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}
