@import 'import.pcss';

:local {
    .IFrameContainer {
        border: none;
    }
    .iFrameOverrides {
        margin-right: $mobileComponentsHorizontalSpace;
        @media $fromLargeTablet {
            margin-right: $tabletComponentsHorizontalSpace;
        }
        @media $desktopM {
            margin-right: $desktopComponentsHorizontalSpace;
        }
    }
}