@import 'import.pcss';

:local {
  .livePageChannelCard {
    position: relative;
    width: 100%;
    height: rem(95);

    @media $toDesktop {
      height: rem(67);
    }

    .contentWrapper {
      cursor: default;
      display: flex;
      justify-content: space-between;
      border-radius: rem(1);
      height: 100%;
      background: $c-ee-lightblack;
      z-index: 1;

      .textWrapperBehind {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s, background 0.3s;
        z-index: 2;
      }

      .textWrapper {
        display: flex;
        flex-direction: column;
        padding: rem(17) rem(4) rem(14) rem(16);
        max-width: 85%;
        z-index: 3;

        @media $toDesktop {
          padding: rem(6) rem(4) 0 rem(15);
        }

        &.onNowAndSoonCard {
          max-width: 65%;

          @media screen and (max-width: 1440px) {
            max-width: 55%;
          }
          @media $toDesktop {
            max-width: 85%;
            padding: rem(6) rem(4) rem(14) rem(15);
          }
        }
        
        .title {
          line-height: rem(15);
          margin-bottom: rem(4);
        }
        .onSoonTitle {
          opacity: 0.6;
          color: $c-white;
        }
        .subtitleWrapper {
          display: flex;
          flex-direction: row;
        }
        .programEndedText {
          line-height: rem(14);
          padding-top: rem(14);
          color: $c-ee-white-05;

          @media $toDesktop {
            padding-top: rem(6);
          }
        }
        .subtitle {
          line-height: rem(13);
          padding-right: rem(7);
        }
        .classification {
          line-height: rem(13);
          padding-right: rem(7);
        }
        .subtitleOnSoon {
          opacity: 0.8;
        }
        .whenPlaying {
          line-height: rem(13);
          padding-right: rem(7);
          font-weight: 600;
          opacity: 0.8;
          font-size: rem($fz-ee-body4);
          &.whenPlayingProgramEnded {
            display: none;
          }
          &.whenPlayingOnNow {
            opacity: 1;
          }
          &.whenPlayingOnSoon {
            color: $c-silver !important;
            opacity: 1;
          }
        }
      }
      .imgWrapperLink {
        width: rem(145);
        height: 100%;
        display: flex;
        align-items: center;
        z-index: 3;
        .imageWrapper {
          width: 100%;
          padding: rem(0) rem(6) rem(6) rem(0);
          position: relative;

          img {
            max-height: rem(78);
          }
          
          .rectangleWrap {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .rectangle {
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: rem(0.3);
            width: rem(39);
            height: rem(30);
            transition: background-color 0.2s ease-in-out;
          }
        }
        .imageWrapperOnSoon img {
          filter: grayscale(100%);
          opacity: 0.5;
        }
        @media screen and (max-width: 1220px) {
          width: rem(138);
        }
        @media screen and (max-width: 1150px) {
          width: rem(122);
        }
      }

      &.programEndedWrapper,
      &.onSoonWrapper {
        background: $c-ee-darkergrey;
      }
      &.contentWrapperOnSoon {
        cursor: pointer;
      }
    }
    .contentWrapperOnNow {
      cursor: pointer;
    }
    .gradientBig {
      position: absolute;
      width: rem(122);
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0.15;
      border-radius: rem(1);
    }
    .gradientSmall {
      position: absolute;
      width: rem(28);
      height: 100%;
      left: 0;
      top: 0;
      background: linear-gradient(
        90deg,
        #000000 -75%,
        rgba(16, 16, 16, 0) 71.43%
      );
      border-radius: rem(0);
    }
  }
  .link {
    text-decoration: none;
  }
}
