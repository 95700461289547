@import 'import.pcss';

:local {
  .titleContainer {
    display: flex;
    align-items: center;
    color: $c-white;

    .title {
      font-size: rem(16);
      font-weight: $fw-600;
      opacity: 0.9;
      margin-right: rem(6);
    }

    .classification {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.8;
      font-size: rem(8.5);
      font-style: normal;
      font-weight: $fw-700;
      line-height: normal;
      border-radius: rem(1);
      padding: rem(4) rem(6) rem(3);
      text-align: center;
      min-width: rem(24);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.07) 100%
      );
      margin-top: rem(1);
    }
    .subtitleContainer {
      margin: 0 rem(13);
    }

    @media $toDesktop and (orientation: landscape) {
      height: rem(18);
      .classification {
        margin-top: 0;
        margin-bottom: 0;
      }
      .subtitleContainer {
        margin: 0 rem(13) rem(-2);
      }
    }
    @media $desktop {
      .classification {
        padding: rem(4) rem(7) rem(3) rem(6);
      }
    }
  }

  .subtitleContainer {
    margin-bottom: rem(3);
    .subtitle {
      font-size: rem(12);
      font-style: normal;
      font-weight: $fw-600;
      line-height: rem(17);
      opacity: 0.8;
    }

    @media $desktop {
      margin-bottom: rem(5);
    }
  }
}
