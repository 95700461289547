@import 'import.pcss';

/*
NOTE:
  'Player' is here (outside of 'local')
  since that class it not yet modified by postcss
  once that's modified, this needs to be updated
*/
.Player {
  .vjs-text-track-settings {
    /* z-index 9 required to be on top of the 'chromecast__overlay' element */
    z-index: 9;
    outline: none;
  }
}

:local {
  .chromecastButton {
    position: relative;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    width: rem(45);
    height: rem(36);
    padding: rem(6) rem(10.5);
    margin: 0 0 0 rem(6);
    cursor: pointer;
    --disconnected-color: $c-white;
    --connected-color: $c-white;
    opacity: 1;

    svg {
      display: none;
    }

    &:hover,
    &:focus {
      cursor: pointer;
      opacity: 1;
    }

    @media $toMobile {
      margin-left: rem(10);
    }

    @media $desktop {
      opacity: 0.9;
      padding: rem(4.68) rem(9.18);
    }
  }

  .chromecast {
    position: relative;

    &__overlay {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100% - 42px);
      background-position: center;
      background-size: cover;
      /* Add the blur effect */
      filter: blur(5px);
      -webkit-filter: blur(5px);

      z-index: 1;

      &--mode-offer {
        cursor: pointer;
      }
    }

    &__information {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate3d(50%, -50%, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: em(300);
      font-size: em(15, 14);
      font-weight: $fw-500;
      font-family: $ff-content;
      z-index: 2;
    }

    &__cc-icon {
      font-size: em(16, 15);
      width: em(36);
      height: em(36);
      margin-bottom: em(10);
    }

    &--mode-offer {
      .chromecast__overlay {
        height: 100%;
      }

      :global(.vjs-control-bar) {
        display: none;
      }
    }
  }

  :global(.video-js) button&.chromecast__play {
    @include resetBtn;
    margin-top: rem(20);
    background-color: transparent;
    border: none;
  }
}
