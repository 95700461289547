@import 'import.pcss';

:local {
  .card {
    position: relative;
    height: 100%;
    z-index: 0;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    &:hover > .contentWrapper {
      &::before {
      opacity: 0.6;
      background-color: $c-dt-darkergrey;
      }
      .addButton, .removeButton {
        .add {
          g {
            opacity: 1;
          }
        }
        .remove {
          path {
            opacity: 1;
          }
        }
      }
    }
  }

  .imageWrapper {
    position: relative;
    border-radius: 1px;
    overflow: hidden;
    pointer-events: auto;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $c-black;
      opacity: 0;

      &.overlayRemoved {
        opacity: 0.7;
      }
    }
  }
  .progressWrapper {
    width: 100%;

    :global(.Progress) {
      height: rem(3);
      &::before {
        background-color: #e6001e;
        opacity: 0.5;
      }
    }
  }

  .contentWrapper:has(.removeButton:hover, .addButton:hover) {
    &:hover::before {
      opacity: 1;
      background-color: $c-ee-lightblack;
    } 
  }


  .contentWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    height: rem(52);
    border-radius: rem(1);
 
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: $c-ee-lightblack;
      transition: opacity 0.2s, background-color 0.2s;
    }
    @media $toMobile {
      height: rem(45);
    }

    .addButton {
      z-index: 1;
      display: flex;
      align-items: start;
      justify-content: center;
      padding: 0;
      height: 100%;
      width: rem(40);
      background: transparent;
      border: none;
      pointer-events: auto;

      .add {
        margin-top: rem(12);

        @media $toMobile {
          width: rem(11);
          margin-top: rem(7);
        }
      }

      &:hover {
        .add {
          g {
            opacity: 1;
          }
        }
      }
    }

    .removeButton {
      z-index: 1;
      display: flex;
      align-items: start;
      justify-content: center;
      padding: 0;
      height: 100%;
      width: rem(40);
      background: transparent;
      border: none;
      pointer-events: auto;

      .remove {
        margin-top: rem(17);

        @media $toMobile {
        width: rem(11);
        margin-top: rem(13);
        }
      }

      &:hover {
        .remove {
          path {
            opacity: 1;
          }
        }
      }
    }


    .titleWrapper {
      z-index: 1;
      width: 85%;
      display: flex;
      align-items: center;
      padding: rem(11) 0 0 rem(18);

      @media $toMobile {
        padding: rem(7) 0 0 rem(10);
      }

      .title {
        font-size: rem(12);
        line-height: normal;
        font-weight: $fw-500;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
