@import 'import.pcss';

:local {
  .layout-container {
    display: flex;
    flex-direction: column;

    @media $desktop {
      flex-direction: row;
    }

    // NOTE: required to increase specificity and overide Section.pcss
    &.layout-container-overrides {
      @media $toDesktop {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  .horizontal {
    display: flex;
    flex-direction: column;

    @media $desktop {
      flex-direction: row;
    }
  }

}
