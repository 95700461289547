@import 'import.pcss';

:local {
  .card-in-grid {
    margin-bottom: 0;
    padding: 0;
  }

  .lozengeWrapper {
    position: absolute;
    z-index: 1;
  }

  .imageContainer {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
      background: rgba(0, 0, 0, 0.15);
      border-radius: 1px;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  .imageCardDetailsWrapper {
    display: flex;
    align-items: flex-end;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 70px;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.64) 52.08%,
      rgba(0, 0, 0, 0.83) 73.96%,
      rgba(0, 0, 0, 0.92) 100%
    );
  }
}
