@import 'import.pcss';

/* red tab styling */
:local {
  $minWidth: 2.1875rem;

  .scrollerContainerRedTab {
    display: flex;
    align-items: center;
    // the arrows in the 'Scroller' component is smaller when compared to
    // the arrows the arrows in the "normal" shelves
  }

  .scrollerRedTab {
    &.scrollerContainerWithPreceedingElementRedTab,
    &.scrollerWithPreceedingElementRedTab {
      padding-left: 0;
    }
  }

  // '.tabs li.tab:last-child' required to increase specificity
  // and then overide src/components/Shelf/Themes/RedTab.pcss
  .scrollerWithPreceedingElementRedTab :global(.tabs li.tab:last-child) {
    padding-right: 0;
    min-width: $minWidth;
  }
}

// specific styles for season selector
:global(.tabUniqueLevel) {
  :local {
    .scrollerWithPreceedingElementRedTab :global(.tabs li.tab:last-child) {
      padding-right: 0;
      padding-right: rem(10);
      margin-right: rem(10);
    }
  }
}
