@import 'import.pcss';

:local {
  .emptyWatchlistPageContentContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: rem(237);

    @media $toMobile {
      margin-top: rem(219);
      padding-bottom: rem(324);
    }

    .title {
      margin-bottom: rem(30);
      font-size: rem(33);

      @media $toMobile {
        margin-bottom: rem(19);
        font-size: rem(25);
        line-height: rem(28);
        text-align: center;
        padding: 0 rem(10);
      }
    }

    .text {
      font-size: rem(13);

       @media $toMobile {
        text-align: center;
        padding: 0 rem(10);
       }
    }
  }
}