@import 'import.pcss';
:local {
  .settingsWrapper {
    display: flex;
    flex-direction: column;
    z-index: 61;
    opacity: 1;
    background-color: #000000;
    width: 87%;
    height: 94%;
    margin: 11px 13px 7px 13px;
    position: absolute;
    border-radius: rem(2);
    overflow-y: overlay;
    //dont show scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    .titleWrapper {
      padding-top: rem(18);
      padding-bottom: rem(14);
      display: flex;
      flex-direction: row;
      justify-content: center;
      button {
        position: absolute;
        left: rem(12);
      }
      p {
        line-height: rem(15);
        font-weight: 600;
        font-family: $ff-content;
        font-size: rem($fz-body4);
      }
    }
    .optionsWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .option {
        color: white;
        opacity: 0.7;
        font-size: rem(12);
        font-weight: 600;
        margin: rem(5) 0;
        padding: 0;
        line-height: rem(15);
        &.optionSelected {
          border-bottom: 1px solid #e6001e;
          opacity: 1;
        }
      }
    }
  }
}
