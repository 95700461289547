/*----------  Component  ----------*/
.ShelfArrow {
  color: #fff;
  z-index: 10;
  height: 100%;
  @include tbma;
  width: em(30);
  cursor: pointer;
  margin-top: em(-28.5);
}

/*----------  Modifiers  ----------*/
.ShelfArrow {
  &--left {
    left: 0;
    transform: rotate(180deg);
  }
  &--right {
    right: 0;
  }
}

/*----------  Children  ----------*/
.ShelfArrow {
  &__icon {
    @include trblma;
    text-align: center;
    font-size: em(25);
  }
}
