@import 'import.pcss';

:local {
  .text {
    color: $c-darkgrey;
  }

  .subTitle {
    @include ellipsis($fz-h6);

    font-size: em($fz-h6);
    font-weight: $fw-700;
    color: $c-darkgrey;
  }

  .body1 {
    font-size: em($fz-body1-mobile);

    @media $fromMobile {
      font-size: em($fz-body1);
    }
  }

  .body2 {
    font-size: em($fz-body2-mobile);

    @media $fromMobile {
      font-size: em($fz-body2);
    }
  }

  .body3 {
    font-size: em($fz-body3-mobile);

    @media $fromMobile {
      font-size: em($fz-body3);
    }
  }

  .body4 {
    font-size: em($fz-body4-mobile);

    @media $fromMobile {
      font-size: em($fz-body4);
    }
  }

  .bodyTitle {
    font-size: em($fz-body3-mobile);

    @media $fromTablet {
      font-size: em($fz-body2-mobile);
    }
  }

  .lineHeight17 {
    line-height: rem(17);
  }

  .lineHeight15 {
    line-height: rem(15);
  }

  .lineHeight14 {
    line-height: rem(14);
  }

  .lineHeight13 {
    line-height: rem(13);
  }

  .eeBody3 {
    font-size: rem($fz-ee-body3);
  }

  .eeBody4 {
    font-size: rem($fz-ee-body4);
  }

  .eeBody3 {
    font-size: rem($fz-ee-body3);
  }

  .footer {
    font-size: em($fz-footer);
  }

  .lozenge1 {
    font-size: em($fz-lozenge1);
  }

  .lozenge2 {
    font-size: em($fz-lozenge2);
  }

  .h1 {
    font-size: em($fz-h1-mobile);

    @media $fromMobile {
      font-size: em($fz-h1);
    }
  }

  .h2 {
    font-size: em($fz-h2-mobile);

    @media $fromMobile {
      font-size: em($fz-h2);
    }
  }

  .h3 {
    font-size: em($fz-h3-mobile);

    @media $fromMobile {
      font-size: em($fz-h3);
    }
  }

  .h4 {
    font-size: em($fz-h4-mobile);

    @media $desktop {
      font-size: em($fz-h4);
    }
  }

  .h5 {
    font-size: em($fz-h5-mobile);

    @media $fromMobile {
      font-size: em($fz-h5);
    }
  }

  .h6 {
    font-size: em($fz-h6-mobile);

    @media $fromMobile {
      font-size: em($fz-h6);
    }
  }

  .h2Mobile {
    @media $toMobile {
      font-size: em($fz-h2-mobile);
    }
  }

  .h4Mobile {
    @media $toMobile {
      font-size: em($fz-h4-mobile);
    }
  }

  .h6Mobile {
    @media $toMobile {
      font-size: rem($fz-h6-mobile);
    }
  }

  .episodeH5 {
    font-size: rem($fz-ee-h5);
  }

  .eeH5 {
    font-size: rem($fz-ee-h5);
    line-height: rem(15);
  }
  .red {
    color: $c-dt-red;
  }

  .redNew {
    color: $c-red-new;
  }

  .lightBlue {
    color: $c-lightblue;
  }

  .dark {
    color: $c-dark;
  }

  .white {
    color: $c-white;
  }

  .darkGrey {
    color: $c-darkgrey;
  }

  .grey {
    color: $c-grey;
  }
  
  .silver {
    color: $c-silver;
  }

  .mediumGrey {
    color: $c-mediumgrey;
  }

  .lightGrey {
    color: $c-lightgrey;
  }

  .lightestGrey {
    color: $c-linegrey;
  }

  .whiteOpacity3 {
    color: $c-ee-white-03;
  }

  .episodeWhiteOpacity6 {
    color: $c-ee-white-06;
  }
  .episodeWhiteOpacity7 {
    color: $c-ee-white-07;
  }
  .whiteOpacity8 {
    color: $c-ee-white-08;
  }

  .dtLightestGrey {
    color: $c-dt-lightestgrey;
  }

  .dtLighterGrey {
    color: $c-dt-lightergrey;
  }

  .dtLightGrey {
    color: $c-dt-lightgrey;
  }

  .dtMediumGrey {
    color: $c-dt-mediumgrey;
  }

  .eeMediumGrey {
    color: $c-ee-mediumgrey;
  }

  .eeGrey {
    color: $c-ee-grey;
  }
  .eeGreyWhite {
    color: $c-ee-white-08;
  }
  .eeWhiteOpacity5 {
    color: $c-ee-white-05;
  }

  .eeMediumGreySingle {
    color: $c-ee-mediumgrey-single;
  }

  .eeDarkGreySingle {
    color: $c-ee-darkgrey-single;
  }
  
  .opacity04 {
    opacity: 0.4;
  }

  .inheritColor {
    color: inherit;
  }

  .fw300 {
    font-weight: $fw-300;
  }

  .fw400 {
    font-weight: $fw-400;
  }

  .fw500 {
    font-weight: $fw-500;
  }

  .fw600 {
    font-weight: $fw-600;
  }

  .fw700 {
    font-weight: $fw-700;
  }

  .shadow {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  }

  .truncate {
    @include ellipsis(1);
  }

  .truncate2 {
    @include ellipsis(2);
  }

  .truncate3 {
    @include ellipsis(3);
  }
  .truncate6 {
    @include ellipsis(6);
  }

  .truncateMobile {
    @media $toTablet {
      @include ellipsis(1);
    }
  }

  .truncateMobile2 {
    @media $toTablet {
      @include ellipsis(2);
    }
  }

  .underline {
    text-decoration: underline;
  }

  .block {
    display: block;
  }

  .card {
    line-height: 1.3;
  }

  .oversize {
    font-size: em($fz-h2);
  }

  .oversizeDesk {
    @media $desktop {
      font-size: em($fz-h2);
    }
  }

  .oversizedBody1 {
    font-size: em($fz-body1);
  }
}
