@import 'import.pcss';

:local {

  .PromoCard {
    $desktopImageSize: rem(257);
    $desktopMImageSize: rem(323);
    display: flex;
    flex-direction: column;
    border-radius: 1px;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
    background-color: $c-black;

    @media $desktop {
      flex-direction: row;
    }

    &__image-container {

      @media $desktop {
        width: $desktopImageSize;
      }
      @media $desktopM {
        width: $desktopMImageSize;
      }
    }

    &__image {
      width: 100%;
      overflow: hidden;

      &--mobile {
        display: block;

        @media $desktop {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @media $desktop {
          display: block;
          width: $desktopImageSize;
          height: $desktopImageSize;
        }
        @media $desktopM {
          width: $desktopMImageSize;
          height: $desktopMImageSize;
        }
      }
    }

    &__content {
      padding: rem(24);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;

      @media $desktop {
        padding: rem(14) rem(18);
        height: $desktopImageSize;
        overflow: hidden;
      }

      @media $desktopM {
        padding: rem(20) rem(24);
        height: $desktopMImageSize;
      }
    }

    &__tag {
      $fontSizeInPx: 13;
      $lineHeightInPx: 16;

      $fontSizeInPxDeskTop: 10;
      $lineHeightInPxDesktop: 13;

      font-size: rem($fontSizeInPx);
      line-height: calc($lineHeightInPx / $fontSizeInPx);
      margin: 0 0 rem(14);


      @media $desktop {
        margin: 0 0 rem(10);
        font-size: rem($fontSizeInPxDeskTop);
        line-height: calc($lineHeightInPxDesktop / $fontSizeInPxDeskTop);
      }

      @media $desktopM {
        font-size: rem($fontSizeInPx);
        line-height: calc($fontSizeInPx / $lineHeightInPx);
      }
    }

    &__title {
      $fontSizeInPx: 24;
      $lineHeightInPx: 30;

      font-size: rem($fontSizeInPx);
      line-height: rem($lineHeightInPx);
      margin: 0 0 rem(16);
      padding: 0 0 rem(16);
      border-bottom: solid 1px $c-dt-darkgrey;
      display: block;
      width: 100%;

      @media $desktop {
        margin: 0 0 rem(13);
        padding: 0 0 rem(13);
      }
    }

    &__description {
      $fontSizeInPx: 14;
      $lineHeightInPx: 18;

      $lineHeightInPxTablet: 22;

      font-size: rem($fontSizeInPx);
      line-height: rem($lineHeightInPx);
      margin: 0 0 rem(30);
      flex: 1;
      width: 100%;

      @media $tablet {
        line-height: rem($lineHeightInPxTablet);
      }

      @media $desktop {
        border-bottom: solid 1px $c-dt-darkgrey;
        padding: 0 0 rem(13);
        margin: 0 0 rem(13);
      }

      @media $desktopM {
        padding: 0 0 rem(24);
        margin: 0 0 rem(24);
      }
    }

    &__cta {
      $fontSizeInPx: 13;
      $lineHeightInPx: 16;

      $fontSizeInPxTable: 14;
      $lineHeightInPxTablet: 18;

      padding: rem(6) rem(13);
      background: $c-dt-red;
      color: $c-white;
      font-size: rem($fontSizeInPx);
      line-height: rem($lineHeightInPx);
      font-weight: $fw-500;
      border-radius: rem(4);
      margin: 0 0 0 auto;

      @media $tablet {
        font-size: rem($fontSizeInPxTable);
        line-height: rem($lineHeightInPxTablet);
      }
    }
  }
}