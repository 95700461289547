@import 'import.pcss';

$ratioTablet: 56.25%;
$ratioDesktop: 45.97%;
/* prettier-ignore */
$toSmallerMobile: (max-width: 334px);
/* prettier-ignore */
$fromMMobile: (min-width: 415px);
/* prettier-ignore */
$toMMobile: (max-width: 414px);

:global {
  .globalVodPage {
    :local {
      .showPageTopFeature {
        margin: 0;
        &.showFeature {
          @media $toMobile {
            margin-top: rem(-$height-header-mobile);
          }
          @media $fromMobile {
            margin-top: rem(-$height-header);
          }
        }
      }
    }
  }
}

:local {
  .showPageTopFeature {
    z-index: 0;
    .topFeature {
      opacity: 0;
      transition: visibility 0s linear 0.5s, opacity 0.5s linear;
    }
    .player {
      opacity: 0;
      transition: visibility 0s linear 0.5s, opacity 0.5s linear;
    }

    &.showFeature {
      .topFeature {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
      .player {
        opacity: 0;
        visibility: hidden;
      }
    }
    &.showPlayer {
      .topFeature {
        opacity: 0;
        visibility: hidden;

        .backgroundImage {
          @media $toDesktop and (orientation: portrait),
            @media $tablet and $toDesktop and (orientation: landscape) {
            height: calc(56.25vw + rem(65) + rem(139));
          }
          @media $toDesktop and (orientation: landscape) {
            padding-top: 100vh;
          }
        }
      }
      .player {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
    }
    @media (min-width: 1025px) and (max-width: 1600px) and (orientation: landscape) {
      .topFeature {
        margin-top: rem(-60);
        position: absolute;
        top: 0;
      }
      .backgroundImage {
        padding-top: $ratioTablet;
      }
      &.showPlayer .topFeature {
        margin-top: 0;
      }
    }
  }

  .topFeature {
    position: relative;
    width: 100%;
    background: $c-black;
    margin-right: 0;
    margin-left: 0;
  }

  .backgroundImage {
    width: 100%;
    background-size: cover;
    background-color: $c-black;
    transition: height 0.3s ease-in-out;

    @media $toDesktop and (orientation: landscape) {
      padding-top: 100vh;
    }

    @media $smallerThanTablet and (orientation: portrait) {
      height: calc(76.267vw + 44px + 300px);
    }

    @media $tablet {
      padding-top: $ratioTablet;
    }

    @media $desktop {
      padding-top: $ratioDesktop;
    }
  }
  .overlayLeft {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 60%;
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9), transparent);

    @media $tablet {
      opacity: 1;
    }

    @media $smallerThanTablet and (orientation: landscape) {
      opacity: 1;
    }
  }

  .contentContainer {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    transition: height 0.3s ease-in-out;

    @media $tablet {
      padding: 0 $tabletComponentsLargeHorizontalSpace;
    }

    @media $desktop {
      padding: 0 rem(75);
    }

    @media $toTablet and (orientation: landscape) {
      padding: 0 $tabletComponentsHorizontalSpace;
    }

    @media $smallerThanTablet and (orientation: portrait) {
      align-items: center;
      justify-content: flex-end;
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: rem(355);
    max-width: 100%;
    padding: rem(15) rem(15) 0 0;

    @media $desktop {
      margin-bottom: 0;
    }
    @media $tablet {
      align-items: flex-start;
      margin-bottom: 0;
    }

    @media $smallerThanTablet and (orientation: portrait) {
      margin-bottom: rem(15);
      padding: 0;
    }

    @media $toSmallerMobile {
      width: rem(300);
      margin-bottom: rem(84);
    }
  }

  .showPageBtybContainer {
    @media $tablet {
      margin-bottom: rem(23);
    }
  }

  .overlayBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    pointer-events: none;
    z-index: 1;
    height: 72.549%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.71) 66.67%,
      rgba(0, 0, 0, 1) 100%
    );

    @media $tablet {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.67) 100%
      );
      height: 19.7885%;
    }
  }
}
