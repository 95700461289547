@import 'import.pcss';

:local {
  .InteractiveMediaShelf {
    padding: 0;

    :global {
      .swiper-container {
        overflow: visible;
      }
    }
  }

  .carousel {
    position: relative;
    padding: 0;

    @media $toTablet {
      &:first-of-type {
        padding-right: $mobileComponentsHorizontalSpace;
      }
    }
  }

  .carousel-button-container {
    @include v-align(absolute);

    position: absolute;
    z-index: 50;
    display: none;
    width: em(79);
    height: 105.5%;
    background: rgba(250, 250, 250, 0.7);
    border: none;

    @media $desktop {
      display: flex;
    }
  }

  .carousel-button-inner {
    display: flex;
    align-items: center;
    min-height: em(188);
    margin-top: 9%;
  }

  .carousel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: em(35);
    height: em(95);
    cursor: pointer;
    background: $c-white;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .shelfItem--ImageCard {
    &:last-child {
      margin-bottom: em(10);
    }

    @media $toMobile {
      &:nth-child(2n-1) {
        padding: 0 em(7) 0 em(14);
      }

      &:nth-child(2n) {
        padding: 0 em(14) 0 em(7);
      }
    }

    @media $fromMobile {
      .pure-g.pure-gutter & {
        /* Using this selector to override pure grids weight */
        padding: 0;
      }
    }
  }

  .shelfItem--ImageCard-carousel {
    &:nth-child(1n) {
      padding: 0;
    }

    .ImageCard {
      padding: 0;
    }

    // Overriding the pure grid widths at this size so we will have the 8 column width a custom breakpoint
    @media (min-width: 1528px) {
      width: auto;
    }
  }

  .carousel-button-container--next {
    right: -$mobileComponentsHorizontalSpace;
    justify-content: flex-end;
    background: linear-gradient(
      90deg,
      rgba(250, 250, 250, 0) 0%,
      rgba(250, 250, 250, 0.7) 100%
    );

    @media $fromLargeTablet {
      right: -$tabletComponentsHorizontalSpace;
    }

    @media $desktopM {
      right: -$desktopComponentsHorizontalSpace;
    }
  }

  .carousel-button-container--previous {
    left: -$mobileComponentsHorizontalSpace;
    justify-content: flex-start;
    background: linear-gradient(
      270deg,
      rgba(250, 250, 250, 0) 0%,
      rgba(250, 250, 250, 0.7) 100%
    );

    @media $fromLargeTablet {
      left: -$tabletComponentsHorizontalSpace;
    }

    @media $desktopM {
      left: -$desktopComponentsHorizontalSpace;
    }
  }

  .carousel-button--next {
    box-shadow: em(-10) 0 em(20) 0 rgba(0, 0, 0, 0.2);
    border-radius: em(3) 0 0 em(3);
  }

  .carousel-button--previous {
    box-shadow: em(10) 0 em(20) 0 rgba(0, 0, 0, 0.2);
    border-radius: 0 em(3) em(3) 0;
  }
  .carousel-button-icon--previous {
    transform: rotate(180deg);
  }
}
