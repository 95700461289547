// Some font files are not loaded as we don't use them, will be rechecked with font-weight and size update
@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/HeyWow-Light.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/HeyWow-Light.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/HeyWow-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/HeyWow-Light.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/HeyWow-Light.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/HeyWow-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/HeyWow-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/HeyWow-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/HeyWow-Regular.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/HeyWow-Regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/HeyWow-Medium.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/HeyWow-Medium.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/HeyWow-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/HeyWow-Medium.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/HeyWow-Medium.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../assets/fonts/HeyWow-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/HeyWow-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/HeyWow-SemiBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/HeyWow-SemiBold.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/HeyWow-SemiBold.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/HeyWow-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/HeyWow-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/HeyWow-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/HeyWow-Bold.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/HeyWow-Bold.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'HeyWow';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../assets/fonts/HeyWow-Heavy.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/HeyWow-Heavy.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/HeyWow-Heavy.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/HeyWow-Heavy.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/HeyWow-Heavy.ttf')
      format('truetype'); /* Safari, Android, iOS */
}
