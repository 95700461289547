@import 'import.pcss';

:local {
  .uniqueLevel.componentContainer {
    margin-left: $mobileComponentsHorizontalSpace;

   

    @media $fromTablet and $toDesktop {
      margin-left: $tabletComponentsLargeHorizontalSpace;
      > section {
        margin-right: $tabletComponentsLargeHorizontalSpace;
      }
    }

    @media $desktop {
      margin-left: $desktopComponentsHorizontalSpace;
      margin-right: $desktopComponentsHorizontalSpace;
    }
  }

  .uniqueLevel.gridLayoutUniqueLevel {
 @media $toMobile {
      margin-left: $mobileComponentEpisodeCardSpace;
      > section {
        margin-right: $mobileComponentEpisodeCardSpace;
      }
    }
  }

  .uniqueLevel.componentContainer + .uniqueLevel.componentContainer {
    margin-top: $standardVerticalSpaceBetweenComponents;
  }

  .componentContainer .componentContainer {
    margin-left: 0;
  }
}
