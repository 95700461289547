@import 'import.pcss';

:local {
  .card {
    position: relative;
    max-height: rem(150);
    background: linear-gradient(180deg, $c-ee-lightblack 0%, $c-black 100%);
    border-radius: 1px;
    z-index: 0;

    &:hover {
      .iconWrap {
        opacity: 1;
        svg path {
          opacity: 1;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #1f1f1f;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover::before {
      opacity: 1;
    }

    @media $toMobile {
      width: 100%;
    }
    @media $fromMobile {
      width: 100%;
      max-width: rem(348);
    }
  }

  .link {
    text-decoration: none;
  }

  .contentWrapper {
    height: rem(92);
    display: flex;
    overflow: hidden;
    justify-content: space-between;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    width: rem(175);
    padding: rem(11) rem(15) 0;
  }

  .logoWrapper {
    max-height: rem(16);
    margin-bottom: rem(7);
  }

  .logo {
    max-height: rem(16);
    max-width: rem(160);
  }

  .title {
    line-height: rem(15);
    margin-bottom: rem(3);
  }

  .subTitle2 {
    line-height: rem(13);
  }

  .imageCutWrapper {
    position: absolute;
    height: 100%;
    top: 0;
    width: rem(143.5);
  }

  .imageWrapper {
    min-width: rem(129);
    width: rem(143.5);
    overflow: hidden;
    position: relative;
    margin: rem(8) 0;
  }

  .iconWrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s;
  }

  .upNextBlankWrapper {
    width: 100%;
    height: rem(51);
  }

  .upNextWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(51);
  }

  .upNextTimeWrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    padding: rem(10) rem(10) rem(12) rem(15);
  }

  .upNextTimeWrapperEmpty {
    padding: 0 rem(10) 0 rem(15);
  }

  .upNextText {
    font-size: #{$fz-ee-body3}px;
    line-height: rem(14);
    font-weight: $fw-600;
    text-transform: uppercase;
    margin-bottom: rem(2);
    min-width: rem(78);
  }

  .upNextTextEmpty {
    margin-bottom: 0;
  }

  .upNextLineWrap {
    min-width: rem(28);
    height: rem(48);
    margin-top: rem(2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .upNextTitleWrap {
    display: flex;
    align-items: center;
    opacity: 0.6;
    line-height: rem(15);
    padding: 0 rem(15) 0 rem(11);
    max-width: rem(182);
  }

  .upNextTitle {
    line-height: rem(15);
  }

  .upNextTitleEmpty {
    margin-bottom: 0;
  }

  .imageElement {
    width: 95%;
  }
}
