@import 'import.pcss';

:local {
  .accountManagementPageHeader {
    margin: 0 rem(82) rem(26) rem(82);

    @media $toDesktop {
      margin:  0 rem(19) rem(18) rem(19);
    }

    .title {
      font-size: rem(33);

      @media $toDesktop {
        font-size: rem(25);
      }
    }

    .secondRowWrapper {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;

      .closeAccountWrapper {
        display: flex;
        align-items: center;
        
        @media $desktop {
          transform: translateY(rem(4))
        }
      }

      .deleteIcon {
        margin-right: rem(12);
      }

      .text {
        font-size: rem(12);
        color: $c-ee-white-06;
      }
    }
  }
}
