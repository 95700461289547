@import 'import.pcss';
@import './_ShelfArrow.pcss';

/* Override styling for different themed tabs */
@import './Themes/RedTab.pcss';
@import './Themes/Transparent.pcss';

%gradient-overlay {
  position: absolute;
  top: 0;
  display: block;
  width: 100px;
  height: 50px;
  pointer-events: none;
  content: '';
}

:local {
  .shelf {
    margin-left: 0;

    @media $desktop {
      margin-bottom: 0;
    }

    // NOTE: required to increase specificity and overide Section.pcss
    &.shelfOverrides {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .tabsContainer {
    overflow: hidden;
  }

  .tabsButtonContainer {
    padding-left: $mobileComponentsHorizontalSpace;
    padding-right: $mobileComponentsHorizontalSpace;
    overflow: auto;
    white-space: nowrap;
    background: $c-black;

    @media $tablet {
      padding-left: $tabletComponentsHorizontalSpace;
      padding-right: $tabletComponentsHorizontalSpace;
    }

    @media $desktop {
      padding-left: $desktopComponentsHorizontalSpace;
      padding-right: $desktopComponentsHorizontalSpace;
    }
  }

  .title {
    display: none;
  }

  .tabs {
    display: block;
    justify-content: flex-start;
    margin-bottom: 0;
    text-align: left;
    background: $c-black;
  }

  .tab {
    position: relative;
    margin-right: $standardHorizontalSpaceBetweenTabs;
    display: inline-block;
    text-align: center;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &::after {
      @include pseudo;

      bottom: rem(0);
      left: 50%;
      width: 0;
      height: rem(2);
      background: $c-dt-red;
      transition: width 0.3s ease-in-out;
      transform: translateX(-50%);
    }

    &--isActive {
      &::after {
        width: 100%;
      }

      .Text {
        color: $c-white;
      }
    }
  }

  .externalLinkIcon {
    align-self: center;
    width: rem(14);
    height: rem(14);
    margin: 0 0 0 rem(5);
    opacity: 0.7;
    fill: $c-red-new;
    transition: fill 0.3s ease;

    &:hover,
    &:focus {
      opacity: 0.9;
    }
  }

  .tabText {
    display: flex;
    padding: rem(19) rem(10, 14);
    color: $c-dt-lightgrey;
    text-transform: uppercase;
    transition: opacity 0.2s ease;

    &:hover,
    &:focus {
      color: $c-white;

      .externalLinkIcon {
        opacity: 0.9;
      }
    }

    @media (min-width: 325px) {
      padding: rem(19) $standardHorizontalTabPadding;
    }
  }

  .anchor {
    text-decoration: none;
  }

  .tabPanelContainer {
    position: relative;
    transition: opacity 0.5s ease;
    min-height: rem(200);
  }

  .tabPanel {
    display: none;
    width: 100vw;
    max-width: 100%;
    padding-top: rem(17);
    overflow: hidden;
    vertical-align: top;
    transition: opacity 0.6s ease;

    @media $toMobile {
      padding-top: rem(15);
    }

    &--isActive {
      display: block;
    }
  }

  .tabPanel .tabPanel {
    padding-right: 0;
  }

  .tabPanelInner {
    margin-bottom: 0;
  }
}
