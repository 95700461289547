@import 'import.pcss';

:local {
  .input-container {
    @include align-center;

    padding-bottom: em(8);
    padding-left: em(6);
    margin-right: $mobileComponentsHorizontalSpace;
    border-bottom: em(1) solid $c-dt-darkgrey;

    @media $fromLargeTablet {
      margin-right: 0;
    }

    @media $desktopM {
      margin-right: 0;
    }
  }

  .form {
    width: 100%;
  }

  .icon-container {
    height: 100%;
    padding-right: em(11);
    @media $fromMobile {
      padding-right: em(20);
    }
  }

  .icon {
    width: em(21);
    @media $fromMobile {
      width: em(37);
    }
  }

  .input {
    $fz: 36;
    $fz-mobile: 24;
    font-size: em($fz);
    font-weight: $fw-700;
    color: $c-dt-lightestgrey;
    width: 100%;
    background: $c-app-bg;
    border: none;
    outline: none;
    @media $toMobile {
      font-size: em($fz-mobile);
    }

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

      &::placeholder {
      color: $c-dt-lightgrey;
    }

    &:-ms-input-placeholder {
      color: $c-dt-lightgrey;
    }

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
  }

  .content {
    margin-top: em(40);
    @media $fromMobile {
      margin-top: em(60);
    }
  }

  .error {
    padding-top: em(110);
    padding-bottom: em(70);
    margin-right: $mobileComponentsHorizontalSpace;
    text-align: center;
    color: $c-dt-error;

    @media $fromMobile {
      padding-top: em(112);
      padding-bottom: em(50);
    }
    @media $desktop {
      padding-top: em(150);
      padding-bottom: em(90);
    }
  }

  .error-subtitle {
      padding-top: em(11);
  }
}

