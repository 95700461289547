@import 'import.pcss';

:local {
  .browserLocation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2b2b2b;
    padding: rem(6);
    border-radius: rem(20);
    width: rem(97);
    height: rem(37);

    @media $tablet {
      width: rem(147);
    }

    &.browserLocationLight {
      background-color: $c-ee-white-01;
      margin-left: rem(24);
    }
  }
  .safariIcon {
    justify-self: flex-end;
    &.safariIconLight {
      min-width: rem(40);
      margin: 0 rem(18);
    }
  }
}
