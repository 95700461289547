@import 'import.pcss';

$fz: 10px;

.FormInput {
  &__label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(42);
    font-size: rem(14);
    color: $c-dt-lightgrey;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__placeholder {
    position: absolute;
    left: 0;
    transition: 0.3s;
    transform: translate3d(0, -50%, 0);
    transform-origin: left;
    color: $c-dt-lightgrey;

    &--placeholderTranslation {
      transform: translate3d(0, 0, 0);
    }

    &--withValue {
      transform: translateY(rem(-20));
      font-size: rem(10);
    }
  }

  &__showHide {
    @include resetBtn;

    position: absolute;
    top: 100%;
    right: 0;
    font-size: rem(12);
    font-weight: $fw-600;
    line-height: rem(49);
    color: $c-dt-lightestgrey;
    cursor: pointer;
    transform: translate3d(0, -100%, 0);
  }

  &__message {
    display: block;
    margin: rem(-22) 0 rem(15);
    font-size: rem(10);
    font-weight: $fw-500;
    line-height: (15 / $fz);
    text-align: left;
    color: $c-dt-lightestgrey;

    &--error,
    &--onTypingShownError {
      color: $c-new-error;
    }

    &--centered {
      text-align: center;
    }

    &--max-width-error {
      max-width: rem(183);
    }
  }

  &__hint-text {
    display: block;
    margin-bottom: rem(8);
    font-size: rem(10);
    font-weight: $fw-500;
    line-height: (15 / $fz);
    color: $c-ee-white-08;
  }

  &__no-message-text {
    display: block;
    margin: rem(-22) 0 rem(15);
    font-size: rem(10);
    font-weight: $fw-500;
    line-height: (15 / $fz);
    text-align: left;
    color: $c-ee-white-08;
  }

  &__no-message-text-connect {
    margin: 0 0 rem(8) 0;
  }
  &__error-connect {
    display: block;
    margin-bottom: rem(8);
    font-size: rem(10);
    font-weight: $fw-500;
    line-height: (15 / $fz);
    text-align: left;
    text-align: center;
    color: $c-new-error;
  }
  &__eyeIcon {
    margin-right: rem(5);
  }

  &__eyeIconWithMargin {
    transform: translateY(rem(-2));
  }

  &__input {
    @include resetInput;

    width: 100%;
    font-weight: $fw-500;
    color: $c-white;
    background-color: transparent;
    border: solid 1px transparent; /* solves the issue with border-top in mobile device */
    border-bottom: 1px solid $c-silver;
    border-radius: 0;
    transition: 0.3s;

    &:focus {
      color: $c-white;
      border-bottom: 1px solid $c-white;
      transition: 0.3s;
    }

    &--padding-right {
      padding-right: rem(36);
    }

    &--padding-right-double {
      padding-right: rem(72);
    }

    &--padding-right-double:not(:focus) {
      padding-right: rem(36);
    }

    &:focus + .Text {
      transform: translate3d(0, calc(-100% + rem(16)), 0) scale(0.8);
    }

    &:not(:focus) ~ .FormInput__showHide {
      display: none;
    }

    /* hides default icons in IE Edge and Safari */
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }

    &:focus::-webkit-textfield-decoration-container {
      visibility: hidden;
    }

    &[disabled] {
      color: white;
      opacity: 0.4;
      border-bottom: none;
    }

    &:focus + .FormInput__placeholder {
      transform: translateY(rem(-20));
      font-size: rem(10);
    }

    &:not(:focus) + .FormInput__placeholder {
      &--withValue {
        transform: translateY(rem(-20));
        font-size: rem(10);
      }
    }

    &--line-error {
      border-bottom: 1px solid $c-dt-border-error;

      &:focus {
        border-bottom: 1px solid $c-dt-border-error;
        transition: 0.3s;
      }
    }

    &--small-font {
      font-size: rem(14);
      font-weight: $fw-600;
      color: $c-white;
    }
  }
}
