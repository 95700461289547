/* Article Theme */
:local {
  .markdownPanelArticle {
    max-width: 892px;
    padding: em(32) em(26);
    background-color: $c-black;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0,0,0,0.05);

    @media $tablet {
      margin: em(24) auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: rem(20);
      font-size: em(23);
      font-weight: $fw-700;
      line-height: 32px;
      color: $c-dt-lightestgrey;
    }

    p {
      font-size: em(16);
      line-height: 28px;
      color: $c-dt-lightgrey;
      letter-spacing: 0.2px;
    }

    a:link, a:visited {
      color: $c-dt-lightestgrey;
    }

    blockquote {
      position: relative;

      &::before {
        position: absolute;
        left: -26px;
        font-size: 43px;
        color: $c-dt-lightgrey;
        vertical-align: top;
        content: open-quote;
      }
    }

    strong {
      width: inherit;
      margin-right: inherit;
      font-weight: $fw-600;
      color: $c-dt-lightestgrey;
      text-align: inherit;
      text-transform: inherit;
    }

    ul {
      margin-bottom: em(12);
      margin-left: em(32);
      font-size: em(17);
      color: $c-dt-mediumgrey;
      letter-spacing: 0.2px;
      padding-inline-start: em(20);
    }

    ol {
      margin-top: 0;
      margin-bottom: em(12);
      margin-left: em(32);
      font-size: em(17);
      color: $c-dt-mediumgrey;
      letter-spacing: 0.2px;
      padding-inline-start: em(20);
    }

    li {
      padding-top: em(10);
      padding-bottom: em(10);
      line-height: 1.8;
      list-style-type: inherit;

      p {
        margin-bottom: 0;
      }
    }
  }
}