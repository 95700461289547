
@mixin ellipsis($lines: 1) {
  // Fallback for non-webkit browsers.
  // Fallback does not render ellipsis.

  // Webkit solution for multiline ellipsis
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  text-overflow: ellipsis;

  // Solution for Opera
  text-overflow: -o-ellipsis-lastline;
}
