@import 'import.pcss';

:local {
  .link {
    text-decoration: none;
  }
  .vodChild {
    position: relative;
    @media (max-width: 767px) {
      width: rem(318);
    }

    .contentWrapper {
      height: rem(92);
      display: flex;
      background: $c-ee-lightblack;
      border-radius: rem(1);
      transition: background-color 0.2s;

      &:hover {
        background: $c-ee-darkergrey;
        .iconWrap{
          opacity: 0.8;
        }
      }

      .textWrapper {
        display: flex;
        flex-direction: column;
        width: calc(100% - 151px);
        padding: rem(12) rem(20);

        .title {
          line-height: rem(17);
          margin-bottom: rem(5);
        }
        .description {
          line-height: rem(13);
        }
      }

      .imageWrapper {
        min-width: rem(135);
        margin: rem(8);
        position: relative;

        .iconWrap {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: opacity 0.2s;
        }
      }
    }
  }
  .playPauseIcon {
    -webkit-filter: drop-shadow(0 1px 6px rgba(0, 0, 0, .4));
    filter: drop-shadow( 0 1px 6px rgba(0, 0, 0, .4));
  }
}
