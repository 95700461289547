@import 'import.pcss';

.Backdrop {
  position: absolute;
  z-index: $z-index-100; /* div.Header has z-index 99 */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);

  &--fullscreen {
    position: fixed;
  }
}
