@import 'import.pcss';

:local {
  .App__section-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-height: 100vh;
      background: $c-app-bg;
  }

  .blackBackgroundSection {
    background-color: $c-black;
  }

  .fallbackLayoutBackgroundImage {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
:global(.App) {
  overflow: hidden;

  @media (min-width: em(1920)) {
    max-width: em(1920);
    margin: 0 auto;
  }
}

:global(#widgetContainerMedal) {
  background: #171717;
  z-index: 99999;
  margin: 0 rem(-20);
  padding: 0 rem(20) rem(120);

  th {
    background: #171717 !important;
  }

  button {
    color: white !important;
  }
}

:global(#widgetContainerSchedule) {
  background: #171717;
  z-index: 99999;
  margin: 0 rem(-20);
  padding: 0 rem(20) rem(120);

  th {
    background: #171717 !important;
  }

  button {
    color: white !important;
  }

  input {
    background: #171717 !important;
  }
}