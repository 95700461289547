@import 'import.pcss';

:local {
  .accountManagementPageSection {
    padding: rem(41) rem(47) rem(47) rem(76);
    background-image: url('##/assets/img/account-management-page/account-management-page.png'); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: $c-ee-lightblack;
    position: relative;
    
    @media $toDesktop {
      padding: rem(36) rem(19) rem(43) rem(20);
      background-color: $c-black;
      margin-top: rem(14);
    }

    @media $desktop {
      border-left: rem(1.8) solid rgba(230, 0, 30, 0.3);
    }
  }

  .triangle {
    position: absolute;
    top: 0;
    left: -1.8px;
    border-style: solid;
    border-width: 70px 42px 0 0;
    border-color: rgba(230, 0, 30, 0.1) transparent transparent transparent;
    transform: rotate(0deg);

    @media $toDesktop {
      display: none;
    }
  }
}
