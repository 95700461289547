@import 'import.pcss';

:local {
  .geoRestrictedWrap {
    background: $c-black;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: $ff-content;
  }
  .container {
    display: flex;
    flex-direction: column;
    background: rgb(96, 96, 96);
    background: linear-gradient(
      180deg,
      rgba(96, 96, 96, 1) 0%,
      rgba(55, 55, 55, 1) 100%
    );
    z-index: 9999;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    font-family: $ff-content;
  }

  .title {
    font-size: rem(13);
    line-height: normal;
    font-weight: $fw-600;
    display: flex;
    gap: rem(6);
    align-items: center;
    margin-bottom: rem(24);

    img {
      height: rem(24);
    }
  }

  .message {
    display: none;
    font-size: rem(18);
    line-height: normal;
    font-weight: $fw-300;
    max-width: rem(635);
    text-align: left;
  }

  .error {
    display: none;
    font-size: rem(18);
    line-height: normal;
    font-weight: $fw-300;
    max-width: rem(635);
    text-align: left;
    color: $c-red-new;
  }

  .allowMessageWrap {
    display: flex;
    align-items: center;
    margin-bottom: rem(32);

    .geoPin {
      margin-right: rem(10);
      margin-left: rem(-5);
    }
    .allowMessage {
      font-size: rem(12);
      font-weight: $fw-300;
    }
  }

  .dontSeeWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: rem(2);
    .dontSeeText {
      font-size: rem(13);
      font-weight: $fw-600;
      margin-right: rem(18);
      max-width: rem(82);
    }

    .clickText {
      font-size: rem(11);
      font-weight: $fw-300;
      margin-right: rem(8);
      max-width: rem(109);
      flex: 1;

      &.clickTextSafari {
        margin-right: rem(30);
      }
    }
  }

  @media $tablet {
    .title {
      font-size: rem(27);
      margin-bottom: rem(32);

      img {
        height: rem(46);
      }
    }

    .message {
      display: block;
    }
    .allowMessageWrap {
      padding-top: rem(24);
      padding-bottom: rem(50);
      margin-bottom: 0;
      .geoPin {
        margin-right: rem(18);
      }
      .allowMessage {
        font-size: rem(20);
        line-height: rem(30);
      }
    }
    .dontSeeWrap {
      display: flex;
      align-items: center;

      .dontSeeText {
        font-size: rem(19);
        margin-right: rem(25);
        max-width: none;
      }

      .clickText {
        font-size: rem(15);
        font-weight: $fw-400;
        line-height: rem(17);
        margin-right: rem(20);
        max-width: rem(229);

        &.clickTextSafari {
          margin-right: rem(30);
          max-width: rem(340);
        }
      }
    }
  }

  @media $desktop {
    .container {
      width: 80%;
      padding: 0;
    }
    .wrap {
      width: 90%;
      max-width: rem(635);
    }
  }
}
