@import 'import.pcss';

$slide-max-width: 891;
$slide-space-between: 3px;

:local {
  .Carousel {
    position: relative;
    overflow: hidden;

    // NOTE: required to increase specificity and overide Section.pcss
    &.carousel-overrides {
      margin-right: 0;
      margin-left: 0;
    }

    &.first-component-in-page {
      margin-top: 0;
    }

    :global(.swiper-container) {
      @media $desktop {
        max-width: em($slide-max-width);
        overflow: visible;
      }
    }
  }

  .item {
    overflow: hidden;

    @media $desktop {
      padding: 0;
    }
  }

  .item-link {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 0;
    padding-top: 56.25%;
    text-decoration: none;
  }

  .img-container {
    position: absolute;
    top: 0;
    width: 100%;

    &::before {
      @include pseudo;

      top: 0;
      z-index: 21;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background-color: $c-darkgrey;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    }

    &::after {
      @include pseudo;

      top: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background:
        linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 0, 0, 0.4) 70%,
          rgba(0, 0, 0, 0.8) 100%
        );
    }
  }

  .img {
    display: block;
    width: 100%;
  }

  .item-details {
    position: relative;
    z-index: 15;
    padding: 0 em(12) em(11);

    @media $tablet {
      padding: 0 em(38) em(11);
    }
  }

  .lozenge-container {
    margin-bottom: em(9);
  }

  .title-container {
    margin-bottom: em(5);
  }

  .title {
    display: flex;
    align-items: center;
  }

  .title-icon {
    margin: 0 0 0 rem(8);
  }

  .sub-title {
    color: $c-linegrey;
  }

  .synopsis {
    line-height: 1.6;
  }

  .hasPagination .item-details {
    @media $tablet {
      padding-bottom: em(46);
    }
  }

  :global(.swiper-slide-active) {
    .img-container::before {
      opacity: 0;
    }

    .img-container::after {
      background: transparent;
    }
  }

  .overlayGradientTheme {
    :global(.swiper-slide-active) {
      .img-container::after {
        background:
          linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 0, 0, 0.4) 70%,
            rgba(0, 0, 0, 0.8) 100%
          );
      }
    }
  }
}
