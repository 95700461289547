@import 'import.pcss';

:local {
  .btybContainer {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 5;
    bottom: 0;
    right: 0;
    width: 100%;
    margin-right: 2.45%;

    .textDisplayAdWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .btybText {
        font-size: rem(10);
        margin-bottom: rem(7);
      }
    }

    @media $smallerThanTablet {
      .textDisplayAdWrapper {
        align-items: center;
      }
    }

    @media $smallerThanTablet and (orientation: portrait) {
      position: static;
      justify-content: center;
      margin-right: 0;
    }
  }

  .container {
    display: flex;
    align-items: center;

    @media $tablet {
      align-items: flex-end;
    }

    img {
      max-width: 120px;
      max-height: 31px;
    }

    > div {
      display: none;
    }
  }
}
