@import 'import.pcss';

:local {
  .personalInfoSectionContent {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;

    @media $toDesktop {
      flex-direction: column;
    }

    .titleWrapper {
      @media $desktop {
        padding-right: 7%;
        min-width: 24%;
        margin-top: rem(44);
      }

      @media (min-width: 1116px) {
        padding-right: 10%;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      @media $toDesktop {
        flex-direction: column-reverse;
        align-items: start;
      }
      &.notLogged {
        justify-content: end;
      }
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: end;

      @media $toDesktop {
        align-items: center;
        flex-direction: row;
        justify-content: start;
      }

      .whiteBorderedButtonWrapper {
        margin-bottom: rem(18);

        @media $toDesktop {
          margin: 0 rem(15) 0 0;
        }
      }
    }
  }

  .changePasswordButton {
    &:hover {
      color: $c-white;
      transition: all 0.3s ease-in-out;
      border: rem(1) solid $c-white;
    }
  }

  .editDetailsButton {
    &:not([disabled]):hover {
      transition: all 0.3s ease-in-out;
      background-color: $c-red-new;
      color: $c-white;
    }
  }
}
