@import 'import.pcss';

:local {
  .liveChannelSwitchContainer.liveChannelSwitchContainerOverride {
    margin-left: 0;

    @media $toTablet {
      margin-left: $mobileComponentsHorizontalSpace;
      overflow: hidden;
    }

    @media $tablet {
      margin-left: $tabletComponentsLargeHorizontalSpace;
      margin-right: $tabletComponentsLargeHorizontalSpace;
    }

    @media $desktop {
      margin-left: $desktopComponentsHorizontalSpace;
      margin-right: $desktopComponentsHorizontalSpace;
    }

    > section {
      @media $fromTablet {
        margin-right: 0;
      }
    }
  }
}
