@import 'import.pcss';

:local {
  .Header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: calc($z-index-100 - 1);
    width: 100%;
    height: em($height-header-mobile);
    background: $c-black;
    border-top: 2px solid $c-red;
    transition: background 0.2s ease-in-out;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.2s, transform 0.2s;

    @media $fromMobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: em($height-header);
    }

    @media $desktopUw {
      left: 50%;
      max-width: em($site-width);
      transform: translate3d(-50%, 0, 0);
    }

    &.isHidden {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
      @media $desktopUw {
        transform: translate3d(-50%, -100%, 0);
      }
    }
  }
}

/*----------  Children  ----------*/
:local {
  .Header {
    $shadow-width: 30px;

    // this negates shadow overflow/bleed-through
    &::after,
    &::before {
      position: absolute;
      top: 0;
      width: $shadow-width;
      height: 100%;
      content: '';
      background: $c-black;
    }

    &::before {
      left: -$shadow-width;
    }

    &::after {
      right: -$shadow-width;
    }

    &__inner {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__navigation {
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex: 1 0 auto;
      height: 100%;

      @media $desktop {
        padding-left: 0;
      }

      @media $toTablet {
        width: rem(10);
      }

      & > .navitem {
        display: none;
        position: relative;
        border: none;
        display: flex;
        font-size: rem(16);
        height: 100%;
        width: auto;
      }
    }

    &__site-logo {
      display: block;
      height: auto;
      width: rem(32);
      margin-left: rem(16);
      margin-right: rem(20);
      padding-top: rem(11);

      &:hover {
        opacity: 0.8;
      }

      @media $fromMobile {
        padding-top: rem(19);
        margin-left: rem(20);
      }

      @media $fromLargeTablet {
        width: rem(35);
      }
    }

    &__text {
      font-size: em($fz-h5);
    }

    &__controls-container {
      display: flex;
      align-items: center;
      height: 100%;

      margin-right: $mobileComponentsHorizontalSpace;

      @media $fromLargeTablet {
        margin-right: $headerContainerHorizontalSpace;
      }

      @media $desktopM {
        margin-right: $headerContainerHorizontalSpace;
      }
    }

    &__controls-container > :not(:last-child) {
      margin-right: rem(19);

      @media $fromLargeTablet {
        margin-right: rem(28);
      }
    }

    &__controls-dropdown-menu {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      right: 0;
      // subtracting the height of the progress bar
      top: rem($height-header - 2);
      width: 100%;
      background: $c-app-bg;

      @media $desktop {
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
      }

      &::before {
        @media $fromLargeTablet {
          position: absolute;
          content: '';
          top: -10px;
          right: $tabletComponentsHorizontalSpace;
          margin-right: rem(10);
          border-style: solid;
          border-width: 7px 7px 7px 0;
          border-color: transparent $c-app-bg transparent transparent;
          transform: rotate(90deg);
        }

        @media $desktopM {
          right: $headerContainerHorizontalSpace;
        }
      }

      &::after {
        @media $fromLargeTablet {
          display: none;
        }
      }
    }

    &__menuButton {
      width: rem(28);
      padding: 0;
      font-size: inherit;
      background: none;
      border: 0;
      outline: 0;
    }

    &__search-icon {
      height: auto;
      width: rem(14);
      // adding margin here so total space of
      // icon is the same as the watchlist icon
      margin: rem(4);

      @media $fromLargeTablet {
        width: rem(14);
        margin: rem(6);
      }
    }

    &__watched-list-icon {
      height: auto;
      width: rem(22);

      @media $fromLargeTablet {
        width: rem(26);
      }
    }

    &__menuButton,
    &__search-icon,
    &__watched-list-icon {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    &__icon-active {
      opacity: 1;
    }

    &__progress-container {
      position: absolute;
      top: -2px;
      width: 100%;
      height: em(2);
    }

    // see: https://www.npmjs.com/package/react-progress-2#styling
    // progress bar
    &__progress-container > :global(.loader .loaderProgress) {
      background: $c-red;
    }
    // see: https://www.npmjs.com/package/react-progress-2#styling
    // progress bar background
    &__progress-container > :global(.loader) {
      background: $c-app-bg;
    }

    &__progress-container.red > :global(.loader) {
      background: $c-red;
    }

    &__mobileSearchLink {
      padding: 0 rem(20) 0 rem(22);
    }

    &__mobileWatchlistLink {
      padding: 0 rem(20) 0 rem(18);
    }

    &__mobileSearchLink,
    &__mobileWatchlistLink {
      color: $c-ee-white-08;
      font-size: rem(12);
      font-weight: $fw-500;
      line-height: rem(15);
      background: #101010;
      justify-content: flex-start;
      min-height: rem(44);
    }

    &__mobileSearchIconContainer,
    &__mobileWatchlistIconContainer {
      padding-right: rem(12);
    }

    &__mobileSearchIcon {
      height: auto;
      width: rem(14);
      margin-right: rem(4);
    }

    &__mobileWatchlistIcon {
      height: auto;
      width: rem(22);
    }

    &__topOverlap {
      background: transparent;

      @media $desktop {
        &:hover {
          background: $c-black;
        }
      }
    }
  }
}
