@import 'import.pcss';

$closeButtonMarginPx: 19;

// adapted from https://tympanus.net/Development/SidebarTransitions/

:local {
  /*----------  Children  ----------*/
  .Sidebar {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    overflow-y: auto;
    z-index: 10001;
    transition: transform 0.5s ease;

    @media $fromMobile {
      width: 100vw;
    }

    @media $tablet {
      width: 100vw;
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 100%;
      width: 100%;
      background-color: $c-black;
      padding-bottom: rem(18);

      @media $fromMobile {
        width: 100%;
        max-width: 100%;
      }
    }

    &__blanket {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background-color: $c-app-bg;
      transition: opacity 0.5s ease;
      z-index: 10000;
    }

    &__nav {
      width: 100%;
      background-color: $c-app-bg;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      color: $c-dt-lightgrey;
    }

    &__link {
      position: relative;
      width: 100%;
      height: rem(50);
      display: flex;
      align-items: center;
      padding: 0 rem(15);
      border-bottom: solid $c-dt-darkgrey rem(1);
    }

    &__link.active:after {
      content: '';
      position: absolute;
      @include tbma;
      left: 0;
      width: rem(3);
      height: 100%;
      background: $c-dt-red;
    }

    &__closeButton {
      $closeImgSizeMobilePx: 16;
      $closeImgSizeDesktopPx: 14;
      $closeSizeMobilePx: 30;
      $closeSizeDesktopPx: 36;

      /* Older versions of Safari don't obey flex properties inside buttons... */
      $marginImageMobilePx: ($closeSizeMobilePx - $closeImgSizeMobilePx) / 2;
      $marginImageDesktopPx: ($closeSizeDesktopPx - $closeImgSizeDesktopPx) / 2;

      position: absolute;
      top: rem(18);
      width: rem(30);
      height: rem($closeSizeMobilePx);
      padding: 0;
      background: none;
      border: none;

      &left {
        left: rem(10);
      }

      &right{
        right: rem(10);
      }

      @media $fromMobile {
        width: rem($closeSizeDesktopPx);
        height: rem($closeSizeDesktopPx);
      }

      & > img {
        width: rem($closeImgSizeMobilePx) !important;
        height: rem($closeImgSizeMobilePx) !important;
        margin: rem($marginImageMobilePx) !important;

        @media $fromMobile {
          width: rem($closeImgSizeDesktopPx) !important;
          height: rem($closeImgSizeDesktopPx) !important;
          margin: rem($marginImageDesktopPx) !important;
        }
      }
    }
  }
  /*----------  Modifiers  ----------*/
  .Sidebar {
    &--left {
      left: 0;
      transform: translate3d(-115%, 0, 0);
    }

    &--right {
      right: 0;
      transform: translate3d(115%, 0, 0);
    }

    &--open {
      transform: translate3d(0, 0, 0);
    }

    &--MobileNav {
      display: block;

      @media $desktop{
        display: none;
      }
    }
  }

  .Sidebar__blanket {
    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}