// @import 'import.pcss';
/* prettier-ignore */
$fromMobile: (min-width: 568px);

:local {
  .card-in-grid {
    margin: 0 rem(2.5) rem(5) rem(2.5);

    @media $fromMobile {
      margin: 0 rem(2.5) rem(6) rem(2.5);
    }
  }

  .search-card {
    margin-bottom: rem(5);
    margin-right: rem(5);
  }

  .showTitleContainer {
    padding-left: em(4);
    padding-top: em(12);

    @media $fromMobile {
      padding-top: em(20);
    }
  }
}
.reset-link-interactive {
  text-decoration: none;
}
