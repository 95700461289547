@import 'import.pcss';

$linkFontSizeMobilePx: 12;
$linkFontSizeDesktopPx: 13;

:local {
  .signIn {
    width: 100%;
    padding-bottom: 0;
  }

  /* FIXME: fix this after converting BEM in FormInput to pcss */
  :global(.FormInput__label) {
    margin: 0 0 rem(16);
  }

  .footer {
    margin-top: rem(48);
    margin-bottom: rem(48);
  }

  .signInForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media $fromMobile {
      padding-right: rem(51.5);
      padding-left: rem(51.5);
    }
  }

  .button {
    @extend %submit-button;
    font-weight: $fw-600;
    height: rem(46);
    color: $c-white;
    margin-bottom: rem(30);
    border-radius: 1px;

    &[disabled] {
      background: $c-darkred;
      color: $c-ee-white-05;
    }

    &:not([disabled]):hover {
      background-color: $c-red-new;
      color: $c-white;
    }
  }

  .forgotPasswordContainer {
    margin-top: rem(-18);
    margin-bottom: rem(53);
    text-align: right;
    font-size: rem(10);
    font-weight: $fw-600;
    color: $c-white;
  }

  .link {
    @extend %link;

    margin-left: rem(5);
    font-size: rem(12);
    font-weight: $fw-500;
    line-height: rem(15);
    transition: color 0.1s ease-in-out;

    &:hover {
      color: $c-white;
    }
  }

  .ErrorMessage {
    max-width: rem(320);
    margin-top: rem(10);
    margin-bottom: rem(-27);
  }

  .signInFormInput {
    span {
      color: $c-silver;
      font-size: rem(14);
    }
  }

  .signInPasswordFormInput + span {
    color: $c-ee-white-08;
  }

  .signInFormInput {
    input {
      border-bottom: rem(1) solid $c-silver;

      &:focus {
        border-bottom: rem(1) solid $c-silver;
      }
    }
    
    &.errorInput {
      input {
        border-bottom: rem(1) solid $c-dt-border-error;
      }
    }
  }

  .errorMessageContainer {
    margin-bottom: rem(20);
  }
}
