@import 'import.pcss';

:local {
  .comingSoonChild {
    color: $c-white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: rem(-5) 0 rem(20);
    @media $tablet {
      margin: 0 0 0 rem(22);
      justify-content: flex-start;
    }
    @media $desktop {
      height: rem(92);
    }
    @media $desktopM {
      margin: 0 0 0 rem(48);
    }
  }
  .text {
    font-weight: $fw-300;
    opacity: 0.4;
    font-size: rem(25);
    line-height: rem(32);

    @media $tablet {
      font-size: rem(36);
      line-height: rem(46);
      
    }

    @media (min-width: 1200px) and (max-width: 1299px) {
      font-size: rem(32);
    }
  }
  .lighterText {
    opacity: 0.7;
    margin-right: rem(11);
    margin-left: rem(9);

     @media $tablet {
      margin-right: rem(17);
      margin-left: rem(12);
     }
  }
  .dateWrapper {
    border: 2px solid $c-dt-darkgrey-complementary;
    width: rem(48);
    height: rem(46);
    display: flex;
    align-items: center;
    justify-content: center;
    @media $tablet {
      width: rem(59);
      height: rem(59);
    }
  }
  .date {
    font-weight: $fw-600;
    font-size: rem(25);
    line-height: rem(32);
    opacity: 0.7;

    @media $tablet {
      font-size: rem(30);
      line-height: rem(38);

    }
  }
}
