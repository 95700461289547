@import 'import.pcss';

.vjs-ad-controls.vjs-ad-playing.vjs-user-active:not(.not-hover)
  .vjs-control-bar.vjs-ad-control-bar,
.vjs-controls-enabled.vjs-ad-controls.vjs-ima3-overlay.vjs-user-active:not(
    .not-hover
  )
  .vjs-control-bar {
  transform: translate3d(0, 0, 0) !important;
  transition: transform 0.4s ease 0s, opacity 0.4s ease !important;
}

.Player__video.video-js.vjs-has-started.vjs-playing.vjs-user-active,
.Player__video.video-js.vjs-has-started.vjs-paused.vjs-user-active {
  .vjs-ad-info-wrap {
    @media $fromMobile and $toDesktop and (orientation: landscape) {
      transform: translate3d(0, rem(-37), 0);
    }
  }
}

.Player__video {
  &.vjs-ad-loading {
    .vjs-control-bar,
    .vjs-top-bar {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.vjs-ad-playing {
    .Player__learn-more-gradient {
      display: block;
      z-index: $z-index-100;
    }

    .Player__ad-countdown {
      display: block;
      z-index: $z-index-100;
    }

    .vjs-play-wrapper {
      .vjs-playing > .vjs-icon-placeholder {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='37' viewBox='0 0 25 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.72656 0.375C2.62403 0.375 3.35156 1.10254 3.35156 2V34.75C3.35156 35.6475 2.62403 36.375 1.72656 36.375C0.8291 36.375 0.101562 35.6475 0.101562 34.75V2C0.101562 1.10254 0.8291 0.375 1.72656 0.375Z' fill='%23facc23'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.5703 0.375C23.4678 0.375 24.1953 1.10254 24.1953 2V34.75C24.1953 35.6475 23.4678 36.375 22.5703 36.375C21.6728 36.375 20.9453 35.6475 20.9453 34.75V2C20.9453 1.10254 21.6728 0.375 22.5703 0.375Z' fill='%23facc23'/%3E%3C/svg%3E%0A");
        background-size: rem(25) rem(37);
        @media $toDesktop and (orientation: portrait) {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='29' viewBox='0 0 20 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.96924 0.441406C2.72891 0.441406 3.34474 1.05724 3.34474 1.8169V27.6259C3.34474 28.3856 2.72891 29.0014 1.96924 29.0014C1.20958 29.0014 0.59375 28.3856 0.59375 27.6259V1.8169C0.59375 1.05724 1.20958 0.441406 1.96924 0.441406Z' fill='%23facc23'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.6138 0.441406C19.3734 0.441406 19.9893 1.05724 19.9893 1.8169V27.6259C19.9893 28.3856 19.3734 29.0014 18.6138 29.0014C17.8541 29.0014 17.2383 28.3856 17.2383 27.6259V1.8169C17.2383 1.05724 17.8541 0.441406 18.6138 0.441406Z' fill='%23facc23'/%3E%3C/svg%3E%0A");
          background-size: rem(20) rem(29);
        }
      }

      .vjs-paused > .vjs-icon-placeholder {
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='37' viewBox='0 0 25 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.35156 4.923V33.0274L20.5768 18.9752L3.35156 4.923ZM0.101562 3.60653C0.101562 1.39378 2.6713 0.173766 4.38589 1.57251L23.2249 16.9412C24.5125 17.9916 24.5125 19.9588 23.2249 21.0092L4.3859 36.3779C2.67129 37.7766 0.101562 36.5566 0.101562 34.3439V3.60653Z' fill='%23facc23'/%3E%3C/svg%3E%0A");
        background-size: rem(25) rem(37);
        @media $toDesktop and (orientation: portrait) {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='29' viewBox='0 0 20 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.96875 3.5697V25.792L16.644 14.6809L2.96875 3.5697ZM0.21875 2.78183C0.21875 0.782006 2.53931 -0.322517 4.09141 0.938563L18.7364 12.8376C19.9062 13.788 19.9062 15.5737 18.7364 16.5241L4.09141 28.4232C2.53931 29.6842 0.21875 28.5797 0.21875 26.5799V2.78183Z' fill='%23facc23'/%3E%3C/svg%3E%0A");
          background-size: rem(20) rem(29);
        }
      }
      .vjs-backward-button,
      .vjs-forward-button {
        display: none;
      }
    }
    .vjs-top-bar .vjs-quality-menu-wrapper {
      display: none !important;
    }

    .vjs-ad-info-circle-gradient {
      opacity: 1;
    }
    .vjs-ad-info-wrap {
      display: block;
      position: absolute;
      left: rem(11);
      top: calc(56.25vw + rem(65) + rem(12));
      transform: translate3d(0, 0, 0);
      transition: transform 0.4s ease 0s;

      @media $tablet and $toDesktop and (orientation: portrait) {
        top: calc(56.25vw + rem(65) + rem(6));
        left: rem(16);
      }
      @media $toDesktop and (orientation: landscape) {
        left: rem(14);
        bottom: rem(26);
        top: auto;
      }

      @media $desktop {
        left: rem(26);
        bottom: rem(15);
        top: auto;
      }
    }
    .vjs-control-bar {
      .vjs-volume-panel {
        transform: translate3d(0, 0, 0);
        transition: transform 0.4s ease 0s;
        margin-left: rem(72);

        @media $fromMobile and $toDesktop and (orientation: landscape) {
          margin-left: rem(118);
          transform: translate3d(0, rem(-48), 0);
          .vjs-volume-control.vjs-volume-horizontal {
            display: none;
          }
        }

        @media $tablet and $toDesktop and (orientation: portrait) {
          margin-left: rem(136);
        }

        .vjs-mute-control .vjs-icon-placeholder {
          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 22.6 18' style='enable-background:new 0 0 22.6 18;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FACC23;%7D%0A%3C/style%3E%3Cpath class='st0' d='M9.9,0.5c1-1.1,2.8-0.4,2.8,1.1v14.7c0,1.5-1.8,2.2-2.8,1.1l-3.2-3.5H1.6c-0.9,0-1.6-0.7-1.6-1.6V5.6 C0,4.7,0.7,4,1.6,4h5.1L9.9,0.5z M10.5,3.2L8.2,5.7C7.9,6,7.4,6.2,7,6.2H2.2v5.5h4.7c0.5,0,0.9,0.2,1.2,0.5l2.3,2.5L10.5,3.2 L10.5,3.2z'/%3E%3Cpath class='st0' d='M14.4,2.6c0-0.6,0.5-1.1,1.1-1.1c4,0,7.1,3.4,7.1,7.5s-3.1,7.5-7.1,7.5c-0.6,0-1.1-0.5-1.1-1.1 c0-0.6,0.5-1.1,1.1-1.1c2.6,0,4.9-2.3,4.9-5.3s-2.3-5.3-4.9-5.3C14.9,3.7,14.4,3.2,14.4,2.6z'/%3E%3Cpath class='st0' d='M14.4,7.1c0-0.6,0.5-1.1,1.1-1.1c1.7,0,2.6,1.6,2.6,3s-1,3-2.6,3c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1 c0,0,0.1,0,0.2-0.1s0.2-0.3,0.2-0.6s-0.1-0.5-0.2-0.6s-0.2-0.1-0.2-0.1C14.9,8.2,14.4,7.7,14.4,7.1z'/%3E%3C/svg%3E");
        }
        .vjs-mute-control.vjs-vol-0 > .vjs-icon-placeholder {
          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 22.6 18' style='enable-background:new 0 0 22.6 18;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FACC23;%7D%0A%3C/style%3E%3Cpath class='st0' d='M9.9,0.5c1-1.1,2.8-0.4,2.8,1.1v14.7c0,1.5-1.8,2.2-2.8,1.1l-3.2-3.5H1.6c-0.9,0-1.6-0.7-1.6-1.6V5.6 C0,4.7,0.7,4,1.6,4h5.1L9.9,0.5z M10.5,3.2L8.2,5.7C7.9,6,7.4,6.2,7,6.2H2.2v5.5h4.7c0.5,0,0.9,0.2,1.2,0.5l2.3,2.5L10.5,3.2 L10.5,3.2z'/%3E%3Cg%3E%3Cpath class='st0' d='M14.7,5.2c0.4-0.4,1.2-0.4,1.6,0l6,6c0.4,0.4,0.4,1.2,0,1.6c-0.4,0.4-1.2,0.4-1.6,0l-6-6 C14.3,6.3,14.3,5.6,14.7,5.2z'/%3E%3Cpath class='st0' d='M22.3,5.2c0.4,0.4,0.4,1.2,0,1.6l-6,6c-0.4,0.4-1.2,0.4-1.6,0s-0.4-1.2,0-1.6l6-6C21.2,4.8,21.9,4.8,22.3,5.2z '/%3E%3C/g%3E%3C/svg%3E");
        }

        @media $desktop {
          margin-top: rem(-18);
          margin-left: rem(154);
          .vjs-mute-control .vjs-icon-placeholder {
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='21' viewBox='0 0 26 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7776 0.627693C12.7827 -0.454153 14.5931 0.257035 14.5931 1.73374V18.4779C14.5931 19.9546 12.7827 20.6658 11.7776 19.584L8.03391 15.5544H2.22656C1.3291 15.5544 0.601562 14.8268 0.601562 13.9294V6.28226C0.601562 5.3848 1.3291 4.65726 2.22656 4.65726H8.03391L11.7776 0.627693ZM12.3431 3.32471L9.49685 6.38831C9.1894 6.71924 8.75807 6.90726 8.30635 6.90726H2.85156V13.3044H8.30635C8.75807 13.3044 9.1894 13.4924 9.49685 13.8233L12.3431 16.8869V3.32471Z' fill='%23FACC23'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7109 2.97656C16.7109 2.35524 17.2146 1.85156 17.8359 1.85156C22.2261 1.85156 25.6704 5.61214 25.6704 10.1054C25.6704 14.5986 22.2261 18.3592 17.8359 18.3592C17.2146 18.3592 16.7109 17.8555 16.7109 17.2342C16.7109 16.6129 17.2146 16.1092 17.8359 16.1092C20.8569 16.1092 23.4204 13.4864 23.4204 10.1054C23.4204 6.72433 20.8569 4.10156 17.8359 4.10156C17.2146 4.10156 16.7109 3.59788 16.7109 2.97656Z' fill='%23FACC23'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.7109 8.00781C16.7109 7.38649 17.2146 6.88281 17.8359 6.88281C19.6049 6.88281 20.6383 8.57246 20.6383 10.1045C20.6383 11.6366 19.6049 13.3262 17.8359 13.3262C17.2146 13.3262 16.7109 12.8225 16.7109 12.2012C16.7109 11.5799 17.2146 11.0762 17.8359 11.0762C17.8962 11.0762 18.0121 11.0487 18.1435 10.8843C18.2767 10.7179 18.3883 10.446 18.3883 10.1045C18.3883 9.76307 18.2767 9.49111 18.1435 9.32471C18.0121 9.16037 17.8962 9.13281 17.8359 9.13281C17.2146 9.13281 16.7109 8.62913 16.7109 8.00781Z' fill='%23FACC23'/%3E%3C/svg%3E%0A");
          }
          .vjs-mute-control.vjs-vol-0 > .vjs-icon-placeholder {
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='21' viewBox='0 0 26 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.5589 0.627693C12.564 -0.454153 14.3744 0.257035 14.3744 1.73374V18.4779C14.3744 19.9546 12.564 20.6658 11.5589 19.584L7.81516 15.5544H2.00781C1.11035 15.5544 0.382812 14.8268 0.382812 13.9294V6.28226C0.382812 5.3848 1.11035 4.65726 2.00781 4.65726H7.81516L11.5589 0.627693ZM12.1244 3.32471L9.2781 6.38831C8.97065 6.71924 8.53932 6.90726 8.0876 6.90726H2.63281V13.3044H8.0876C8.53932 13.3044 8.97065 13.4924 9.2781 13.8233L12.1244 16.8869V3.32471Z' fill='%23FACC23'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.8412 5.99357C17.2806 5.55423 17.9929 5.55423 18.4322 5.99357L25.1185 12.6798C25.5578 13.1192 25.5578 13.8315 25.1185 14.2708C24.6791 14.7102 23.9668 14.7102 23.5275 14.2708L16.8412 7.58456C16.4019 7.14522 16.4019 6.43291 16.8412 5.99357Z' fill='%23FACC23'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25.1197 5.99357C25.5591 6.43291 25.5591 7.14522 25.1197 7.58456L18.4335 14.2708C17.9941 14.7102 17.2818 14.7102 16.8425 14.2708C16.4031 13.8315 16.4031 13.1192 16.8425 12.6798L23.5287 5.99357C23.9681 5.55423 24.6804 5.55423 25.1197 5.99357Z' fill='%23FACC23'/%3E%3C/svg%3E%0A");
          }
        }

        .vjs-volume-control.vjs-volume-horizontal {
          .vjs-volume-bar.vjs-slider-horizontal.vjs-slider .vjs-volume-level,
          .vjs-mouse-display .vjs-volume-mouse-display-child {
            background-color: $c-ad-yellow !important;
            border-radius: rem(4);
            &::before {
              background-color: $c-ad-yellow !important;
            }
          }
        }
      }
      .vjs-progress-control,
      .vjs-time-wrap {
        display: none;
      }
    }

    .vjs-title-wrap {
      @media $toDesktop and (orientation: landscape) {
        left: rem(18);
        bottom: rem(25);
      }
    }

    .skipRecapOverlayContainer.canBeHidden {
      opacity: 0;
    }
  }

  &.not-hover,
  &.vjs-user-inactive {
    .Player__ad-countdown {
      transition: 1s;
      transform: translate3d(0, 0, 0);
    }
  }
}

.vjs-ima3-not-playing-yet
  .vjs-ad-control-bar
  ~ .vjs-top-bar
  > .vjs-top-bar-news-upgrade-cc-cast-wrapper
  > .vjs-top-bar-cc-cast-wrapper
  > .vjs-subs-caps-button,
.vjs-ad-playing
  .vjs-ad-control-bar
  ~ .vjs-top-bar
  > .vjs-top-bar-news-upgrade-cc-cast-wrapper
  > .vjs-top-bar-cc-cast-wrapper
  > .vjs-subs-caps-button {
  display: none;
}

.vjs-ad-info-circle-gradient {
  width: rem(910);
  height: rem(910);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(rem(-455), rem(455));
  border-radius: 910px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0.77) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in;

  @media $toDesktop {
    display: none;
  }
}

.vjs-ima3-not-playing-yet.vjs-ad-playing .vjs-top-bar {
  display: flex;
  opacity: 1;
}

.video-js:not(.vjs-native).vjs-ad-playing {
  .vjs-progress-control {
    height: 0.3em !important;
    top: -0.3em !important;
  }
}

/*----------  yospace ad overlay  ----------*/
.Player__video:not(.vjs-error):not(.swm-error) {
  .yospace-ad-overlay {
    display: block;
    @include wh100;
    position: absolute;
    left: 0;
    top: 0;
  }
}

// Fix for yospace ad being over the control bar
.vjs-live .vjs-control-bar {
  z-index: 1;
}

.Player__video.ys-ad-playing {
  video {
    cursor: pointer;
  }

  .Player__learn-more-gradient {
    display: block;
  }
}
/*----------  End of yospace ad overlay  ----------*/

/*----------  SSAI ad overlay  ----------*/

.Player__video.vjs-ad-playing .Player__learn-more-gradient {
  display: none;
}

.vjs-ssai-overlay {
  display: none;
}

.vjs-ima3-html5 {
  &.Player__video.vjs-ad-playing .Player__learn-more-gradient {
    display: block;
  }
}

/*----------  End SSAI ad overlay  ----------*/

.vjs-ad-control-bar .vjs-progress-control .vjs-progress-holder {
  background-color: $c-dark-yellow;
}
