@import 'import.pcss';

:local {
  .freezeFrameContainer {
    display: none;
  }
  .activeFreezeFrameContainer {
    display: none;
    @media $tablet {
      display: flex;
      width: 100%;
      height: 56.65vw;
      position: absolute;
      top: rem(65);
      left: 0;
      z-index: 1;
      @media (orientation: landscape) {
        top: 0;
      }
    }
    @media $desktop {
      height: 100%;
      top: 0;
    }
  }

  .activeFreezeFrameContainer > :global(iframe) {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
    display: block;
  }

  .activeFreezeFrameContainer.freezeFrameVersion1 {
    align-items: center;
    justify-content: center;

    > :global(iframe) {
      z-index: 1;
      width: 81.7592592592593%;
    }
  }

  :global(.video-js.vjs-fullscreen) {
    .activeFreezeFrameContainer.freezeFrameVersion1 > :global(iframe) {
      width: 100%;
      height: calc(100vw * 1080 / 1920);
    }
  }

  :global(video.vjs-tech).transitionFreezeFrame {
    @media $tablet {
      transition: opacity 0.5s ease-in 0.3s;
    }
  }

  .freezeFrameVersion1 ~ :global(.vjs-control-bar) {
    @media $tablet {
      z-index: 2;
    }
  }

  @mixin ffSizePosition {
    @media $tablet {
      top: calc(100% - 150px - 190px);
      left: 0;
      width: 262px;
      height: 150px;
    }

    @media $desktop {
      top: calc(100% - 121px - 190px);
      width: 210px;
      height: 121px;
    }

    @media (min-width: 1200px) {
      top: calc(100% - 143px - 190px);
      width: 250px;
      height: 143px;
    }

    @media (min-width: 1700px) {
      top: calc(100% - 192px - 190px);
      width: 337px;
      height: 192px;
    }
  }

  @mixin ffSizePositionFs {
    width: calc(30% - 18px) !important;
    height: calc(30% - 6px) !important;
    top: calc(50% - 190px);
  }

  :global(video.vjs-tech).showFreezeFrame {
    @media $tablet {
      z-index: 10;
      border: 3px solid #2f2f2f;

      :global(.video-js.vjs-fullscreen) & {
        // because global brightcove style is using an !important specific to width
        // and height the most convenient work around is to include here as well...
        border: none;
        @include ffSizePositionFs;
      }
    }

    @include ffSizePosition;
  }

  :global(button).closeButton {
    @include resetBtn;
    position: absolute;
    top: rem(65);
    right: rem(0);
    display: flex;
    align-items: center;
    width: rem(59);
    height: rem(36);
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px 0 0 20px;
    padding: 0 0 0 rem(20);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &.closeButtonVersion1 {
      z-index: 2;
    }

    &.closeButtonAutoplayAd {
      display: none;
    }

    > img {
      display: block;
      width: rem(15);
      height: rem(16);
    }
  }

  .transitionFreezeFrame {
    @media $tablet {
      .playButtonContainer {
        transition: opacity 0.5s ease-in 0.3s;
      }
    }
  }

  .playButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    @media $tablet {
      position: absolute;

      :global(.video-js.vjs-fullscreen) & {
        // because global brightcove style is using an !important specific to width
        // and height the most convenient work around is to include here as well...
        @include ffSizePositionFs;
      }
    }

    @include ffSizePosition;
  }

  :global(button).playButton {
    @include resetBtn;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media $tablet {
      position: relative;
      z-index: 20;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      width: rem(42);
      height: rem(42);

      :global(.video-js.vjs-fullscreen) & {
        width: calc(46 / 286 * 100%);
        padding-top: calc(46 / 286 * 100%);
        height: auto;
      }

      svg {
        width: rem(36);
        height: rem(36);
        cursor: pointer;

        @media $desktop {
          width: rem(30);
          height: rem(30);
        }

        @media (min-width: 1200px) {
          width: rem(35);
          height: rem(35);
        }

        @media (min-width: 1700px) {
          width: rem(44);
          height: rem(44);
        }

        :global(.video-js.vjs-fullscreen) & {
          position: absolute;
          width: 90%;
          height: 90%;
          top: 5%;
        }
      }
    }

    @media $desktop {
      width: rem(34);
      height: rem(34);
    }

    @media (min-width: 1200px) {
      width: rem(40);
      height: rem(40);
    }

    @media (min-width: 1700px) {
      width: rem(54);
      height: rem(54);
    }

    &--show {
      opacity: 1;
      transition: opacity 0.5s ease-in 0.3s;
    }
  }
}
