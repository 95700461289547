@import 'import.pcss';

:local {
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 95px;
    background: linear-gradient(180deg, $c-ee-lightblack 0%, $c-black 100%);
    border-radius: 1px;
    z-index: 0;
    min-width: rem(317);
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #1f1f1f;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover::before {
      opacity: 1;
    }

    @media $toTablet {
      min-width: rem(303);
      height: 67px;
    }
  }

  .firstRowWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: rem(11) rem(13) 0 rem(16);

    @media $toTablet {
      padding: rem(8) rem(13) 0 rem(15);
    }
  }
  .secondRowWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 rem(16) rem(10) rem(16);

    @media $toTablet {
      padding: 0 rem(13) rem(8) rem(15);
    }
  }
  .textWrapper {
    display: flex;
  }
  .dateAndTitle {
    color: $c-white;
    max-width: rem(200);
    font-size: rem(13);
    font-weight: $fw-600;
    @include ellipsis(3);

    @media $toTablet {
      font-size: rem(12);
    }
  }
  .highlightedText {
    padding-right: rem(5);
    font-size: rem(13);
    font-weight: $fw-600;

    @media $toTablet {
      font-size: rem(12);
    }
  }

  .logoWrapper {
    padding-top: rem(3);
  }

  .button {
    display: flex;
    align-items: center;
    border-radius: rem(7);
    font-size: rem(13);
    font-weight: 600;
    color: $c-white;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    height: rem(22);

    img {
      width: rem(11);
      height: rem(10);
      margin-right: rem(8);
      margin-left: rem(3);
    }

    @media $toTablet {
      font-size: rem(10);
      height: rem(19);
    }
  }
}
