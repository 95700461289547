@import 'import.pcss';

$smallerThanTablet: (max-width: 767px);

:local {
  .listItemsWrapper {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    align-self: flex-start;

    &.listItemsWrapperLive {
      margin-bottom: rem(18);
    }
    &.listItemsWrapperTextLong {
      flex-wrap: wrap;
    }

    &.listItemsWrapperCentered {
      @media $tablet and $toDesktop {
        align-self: center;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media $smallerThanTablet and (orientation: portrait) {
      align-self: center;
      text-align: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .listItem {
    color: rgb(255, 255, 255);
    font-size: rem(10);
    line-height: rem(13);
    opacity: 0.8;
    padding-right: rem(9);
    font-weight: $fw-500;

    &.boldItem {
      font-weight: $fw-700;
    }
    &.colorItem {
      opacity: 1;
      color: $c-ee-red;
    }
    &.colorItemTextLong {
      opacity: 1;
      color: $c-ee-red;
      margin-top: rem(11);
    }
    &.classification {
      font-size: rem(9);
      padding: rem(3) rem(6);
      margin-right: rem(10);
      border-radius: 1.5px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.07) 100%);
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .bullet {
    padding-right: rem(5);
  }
  .imageWrapper {
    display: flex;
  }
  .iconStyle {
    height: 16px;
    vertical-align: middle;
    margin-top: rem(-2);
  }
  .separator {
    padding-left: rem(9);
    opacity: 0.5;
  }
  .separatorIcon {
    padding-left: rem(9);
    padding-right: rem(9);
    opacity: 0.5;
    color: rgb(255, 255, 255);
    font-size: rem(10);
    line-height: rem(13);
    font-weight: $fw-500;
  }
}
