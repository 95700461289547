@import 'import.pcss';

:local {
  $icon-size: rem(15);

  .CloseButton {
    padding: 0;
    border: none;
  
    :global {
      .CloseButton:not(.Overlay__button) {
        background: none;
      }
    }
  }

  .CloseButton > img {
    display: block;
    width: $icon-size;
    height: $icon-size;
  }
}
