@import 'import.pcss';

:local {
  .adBlockDetectedOverlay {
    @extend %flex-center;

    position: absolute;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: $ff-content;
    font-weight: $fw-600;
    color: $c-dt-lightestgrey;
    background-color: rgba(0, 0, 0, 0.5);
  }

  :global(.vjs-error) .adBlockDetectedOverlay {
    @include hidden;
  }

  .image {
    width: auto;
    max-height: rem(135);
    margin-bottom: rem(30);

    @media $toMobile {
      display: none;
    }

    @media $desktop {
      max-height: rem(151);
      margin-bottom: rem(22);
    }
  }

  .title,
  .message {
    text-align: center;
  }

  .title {
    margin-bottom: rem(30);
    font-size: rem(16);
    font-weight: $fw-700;
    line-height: rem(20);

    @media $tablet {
      margin-bottom: rem(8);
    }

    @media $desktop {
      font-size: rem(18);
      line-height: rem(23);
    }
  }

  .message {
    width: rem(386);
    margin-bottom: rem(18);
    font-size: rem(13);
    font-weight: $fw-500;
    line-height: rem(19);
    opacity: 0.8;

    @media $toTablet {
      display: none;
    }

    @media $desktop {
      width: rem(484);
      margin-bottom: rem(22);
      font-size: rem(14);
      line-height: rem(22);
    }
  }
  //  :global required to override .video-js defaults
  :global(.video-js) .button,
  .link {
    width: rem(220);
    height: rem(26);
    font-size: rem(13);
    border-radius: 3px;
    text-decoration: none;

    @media $fromMobile {
      font-size: rem(14);
    }

    @media $desktop {
      width: rem(243);
      height: rem(29);
    }
  }

  :global(.video-js) .button {
    margin-bottom: rem(10);
    color: $c-white;
    background: $c-dt-red;
    width: 41.56%;
    height: 10.67%;

    @media $desktop {
      width: rem(374);
      height: rem(54);
      margin-top: rem(38);
      margin-bottom: rem(24);
    }

    &:focus {
      background: $c-dt-red;
    }

  }

  .link {
    @extend %flex-center;

    background: $c-transparent;
  }

  :global(.video-js) .buttonLink {
    @include resetBtn;

    background: $c-transparent;
    color: $c-dt-lightestgrey;
    font-size: rem(13);
  }

  .hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
  }
}
