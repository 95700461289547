@import 'import.pcss';

:local {
  .footer {
    background: $c-black;
    margin-top: em(37);
    @media $fromMobile {
      margin-top: em(47);
    }
    @media $desktop {
      margin-top: em(22);
    }

    // NOTE: required to increase specificity and overide Section.pcss
    &.footer-overrides {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    &.noMarginTopFooter {
      margin-top: 0 !important;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    padding-top: em(19);
    padding-bottom: em(19);
    @media $fromMobile {
      padding: 0 em(48);
      padding-top: em(19);
      padding-bottom: em(18);
    }
    @media $desktop {
      padding-top: em(18);
      padding-bottom: em(20);
    }
  }

  .link {
    padding: em(19);
    line-height: em(11);
    letter-spacing: em(0.3);
    cursor: pointer;
    @media $toMobile {
      width: 100%;
      text-align: center;
      padding-bottom: em(19);
      padding-top: em(20);
    }
    @media $fromMobile {
      padding: em(19) em(27);
      padding-top: em(20);
    }
    @media $desktop {
      padding-bottom: em(18);
    }
  }

  .link a,
  .link span {
    display: block;
    &:hover {
      color: $c-dt-red;
    }
  }

  .content {
    padding: em(19);
    line-height: em(11);
    letter-spacing: em(0.3);
    @media $toMobile {
      width: 100%;
      text-align: center;
      padding-bottom: em(19);
      padding-top: em(20);
    }
    @media $fromMobile {
      padding: em(19) em(27);
    }
  }
}
