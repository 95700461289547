:local {
  .componentErrorContainer {
    width: 100%;
    color: #fff;
    background-color: red;
    font-weight: bold;
  }

  .componentError {
    white-space: pre-wrap;
  }
}
