@import 'import.pcss';

:local {
  .ImageShelf {
    :global {
      .swiper-container {
        overflow: visible;
      }
    }
  }

  .shelfItem {
    border-bottom: em(1) solid $c-dt-linegrey;

    &:last-child {
      border-bottom: none;
    }

    @media $fromMobile {
      border-bottom: none;
    }
  }

  .carousel {
    position: relative;
    padding: 0;

    @media $toTablet {
      &:first-of-type {
        padding-right: $mobileComponentsHorizontalSpace;
      }
    }
  }

  .carousel-pagination {
    position: absolute;
    top: em(-45);
    right: 0;
    display: none;
    width: auto;

    @media $desktop {
      display: block;
    }
  }

  .carousel-pagination-page {
    margin-right: em(7);
    background-color: $c-lightergrey;
    transition: background-color 0.3s ease-in-out;

    // we have to increase the specificity here since our production build changes the order of how
    // css files are applied (due to performance optimizations) which makes .swiper-pagination-bullet
    // overrides .MediaShelfcarousel-pagination-page--active in this case

    &--active:global(.swiper-pagination-bullet) {
      background-color: $c-dt-red;
    }
  }

  .shelfItem--ImageCard {
    @media $toMobile {
      &:nth-child(2n-1) {
        padding-right: 0;
      }
    }

    @media $fromMobile {
      .pure-g.pure-gutter & {
        /* Using this selector to override pure grids weight */
        padding: 0;
      }
    }
  }

  .shelfItem--ImageCard-carousel {
    &:nth-child(1n) {
      padding: 0;
    }

    .ImageCard {
      padding: 0;
    }

    // Overriding the pure grid widths at this size so we will have the 8 column width a custom breakpoint
    @media (min-width: 1528px) {
      width: auto;
    }
  }

  .gridWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: rem(5);

    @media $tablet {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media $desktop {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @media (min-width: 1528px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
}

:global {
  .tabUniqueLevel {
    :local {
      .ImageShelf {
        margin-left: 0;
        padding-right: rem(0);
        :global {
          .swiper-container {
            overflow: hidden;
          }
        }
      }
    }
  }
}
