@import 'import.pcss';

:local {
  .geoConnectModal.preConnectModalDialog,
  .geoConnectModal.onboardingModalDialog {
    @media $fromMobile and $toLargeTabletLandscape {
      padding: 0;
      > div:last-child {
        width: 100vw;
        height: 100vh;
        padding: 0;
      }
    }
  }
  .preConnectModalDialog {
    > div:last-child {
      padding: rem(10) rem(10);
      background: rgba(0, 0, 0, 0.7);
      > button {
        color: $c-white;
        top: rem(10);
        right: rem(10);
        z-index: 1;
      }
      > div {
        margin-top: 0;
        background-size: cover;
      }
    }
  }
  .onboardingModalDialog {
    > div:last-child {
      padding: 0;
      > button {
        color: $c-white;
        top: rem(10);
        right: rem(10);
        z-index: 1;
        border-color: transparent;
      }
      > div {
        margin-top: 0;
        background: black;
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
          ),
          linear-gradient(180deg, #a60016 0%, #000000 100%);
      }
    }
  }
}
