@import 'import.pcss';

:local {
  .media-section {
    display: flex;
    flex-direction: column;

    @media $desktop {
      flex-direction: row;
    }
  }
}
