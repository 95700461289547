@import 'import.pcss';

.TextWithLink {
  font-size: rem(14);
  line-height: rem(15);
  font-weight: $fw-600;
  color: $c-silver;

  @media $desktopM {
    font-size: rem(13);
    line-height: rem(17);
  }

  a {
    @extend %link;
    margin-left: rem(9);
    font-weight: $fw-600;
  }
}
