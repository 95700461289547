@import 'import.pcss';

$linkFontSizeMobilePx: 12;
$linkFontSizeDesktopPx: 13;

:local {
  .footerTerms {
    width: 100%;

    p.footerTermsText  {
      font-size: rem(10);
      font-weight: $fw-400;
      line-height: rem(15);
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  & a {
    font-size: inherit;
  }

  .link {
    @extend %link;
    font-size: rem(10);
    font-weight: $fw-600;
    text-decoration: none;
    transition: color 0.1s ease-in-out;

    &:hover {
      color: $c-white;
    }
    
    @media $fromMobile {
      line-height: 16 / $linkFontSizeDesktopPx;
    }
  }
}
