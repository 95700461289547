@import 'import.pcss';

:local {
  .liveChild {
    display: flex;
    height: rem(92);
    background: $c-ee-lightblack;
    position: relative;
    text-decoration: none;
    transition: background-color 0.2s;

    &:hover {
      background: $c-ee-darkergrey;
    }

    .logoWrap {
      min-width: rem(164);
      display: flex;
      position: relative;
      align-items: center;

      @media $tablet {
        width: rem(194);
      }
    }
    .logoBackground {
      position: absolute;
      top: 0;
      left: 0;
    }
    .logo {
      margin-top: rem(-7);
      margin-left: rem(25);
      max-width: rem(83);
      max-height: rem(35);
      @media $tablet {
        max-width: rem(103);
      }
    }
    .separator {
      position: absolute;
      right: rem(-2);
      bottom: 0;
      width: rem(52);
    }
    .info {
      display: flex;
      flex-direction: column;
      padding-left: rem(6);
      font-weight: $fw-600;
      font-size: rem(10);
      line-height: rem(13);
      justify-content: center;
      color: $c-white;
    }
    .titleWrap {
      margin-bottom: rem(2);
      display: flex;
      align-items: center;
    }
    .rating {
      margin: 0 rem(10);
      opacity: 0.8;
    }
    .time {
      display: flex;
      opacity: 0.8;
    }
    .highlighted {
      color: #ed1c24;
      margin-right: rem(8);
    }
    .progress {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
