@import 'import.pcss';

:local {
  .link {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  .card-in-grid {
    padding: 0;
    min-width: 100%;

    .rowWrapper {
      align-items: stretch;

      @media $fromMobile {
        display: block;
      }
    }
  }

  .card-in-grid:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }

}
