@import 'import.pcss';

/* transparent styling */
:local {
  .tabsButtonContainerTransparent {
    position: absolute;
    z-index: $z-index-100;
    width: 100%;
    background: $c-darkgrey;
    border: none;
    opacity: 0.5;

    .title {
      display: none;
    }

    .tabText {
      font-weight: $fw-500;
      color: $c-lightergrey;
      fill: $c-lightergrey;

      &:hover,
      &:focus {
        color: $c-white;
        fill: $c-white;
      }
    }

    .externalLinkIcon {
      fill: inherit;
    }

    .tab {
      &::after {
        @include tbma;

        height: rem(34);
        background: $c-white;
        border-radius: rem(4);
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
        opacity: 0.15;
      }

      &--isActive {
        .tabText {
          color: $c-white;
        }
      }
    }

    @media (min-width: 380px) {
      margin-right: rem(7);
    }

    @media $fromMobile {
      margin-right: rem(20);
    }

    @media $tablet {
      margin-right: rem(28);
    }
  }

  .tabPanelTransparent {
    padding-top: 0;
    padding-left: 0;
  }
}
