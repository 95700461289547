@import 'import.pcss';

$ratioTablet: 59%;
$ratioDesktop: 38%;
$ratioDesktopWithBottomOverlap: 41%;
$ratioDesktopWithTopOverlap: 46.5%;
$ratioDesktopWithBothOverlap: 46.5%;
/* prettier-ignore */
$toSmallerMobile: (max-width: 334px);
/* prettier-ignore */
$smallerThanTablet: (max-width: 767px);
/* prettier-ignore */
$fromMMobile: (min-width: 415px);
/* prettier-ignore */
$toMMobile: (max-width: 414px);

:local {
  .topFeature {
    position: relative;
    background: $c-black;

    // NOTE: required to increase specificity and overide Section.pcss
    &.top-feature-overrides {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    &.first-component-in-page {
      position: relative;
      margin-top: 0 !important;
    }

    &.bottom-overlap {
      @media $desktop {
        margin-bottom: rem(-112) !important;
      }
      @media $tablet and $toDesktop {
        margin-bottom: rem(-80) !important;
      }

      @media $smallerThanTablet and (orientation: portrait) {
        margin-bottom: rem(-128) !important;
      }

      @media $smallerThanTablet and (orientation: landscape) {
        margin-bottom: 0 !important;
      }

      @media $toSmallerMobile {
        margin-bottom: rem(-84) !important;
      }
    }

    &.top-overlap {
      @media $toMobile {
        margin-top: em(-$height-header-mobile) !important;
      }
      @media $fromMobile {
        margin-top: em(-$height-header) !important;
      }
    }
  }

  .backgroundImage {
    width: 100%;
    background-size: cover;

    @media $smallerThanTablet and (orientation: landscape) {
      padding-top: 57%;
    }
    @media $smallerThanTablet and (orientation: portrait) {
      height: 94vh;
      transition: height 999999s;
    }
    @media $tablet {
      padding-top: $ratioTablet;
    }
    @media $desktop {
      padding-top: $ratioDesktop;
    }

    &.backImgBothOverlap {
      @media $desktop {
        padding-top: $ratioDesktopWithBothOverlap;
      }
      @media $smallerThanTablet and (orientation: landscape) {
        padding-top: 57%;
      }
      @media $smallerThanTablet and (orientation: portrait) {
        height: 94vh;
        transition: height 999999s;
      }
    }

    &.backImgBottomOverlap {
      @media $desktop {
        padding-top: $ratioDesktopWithBottomOverlap;
      }
    }

    &.backImgTopOverlap {
      @media $desktop {
        padding-top: $ratioDesktopWithTopOverlap;
      }
      @media $smallerThanTablet and (orientation: landscape) {
        padding-top: 57%;
      }
      @media $smallerThanTablet and (orientation: portrait) {
        height: 94vh;
        transition: height 999999s;
      }
    }

    &.transitionNone {
      @media $smallerThanTablet and (orientation: portrait) {
        height: 94vh;
        transition: none;
      }
    }
  }
  .overlayBackgroundImage {
    position: absolute;
    height: 100%;
    width: 60%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    @media $smallerThanTablet and (orientation: portrait) {
      bottom: 0;
      height: calc(100% - 105px);
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.71) 66.67%,
        rgba(0, 0, 0, 0.91) 100%
      );
    }
  }

  .contentContainer {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media $tablet {
      padding: 0 rem(34);
    }
    @media $desktop {
      padding: 0 rem(75);
    }
    @media $toTablet and (orientation: landscape) {
      padding: 0 rem(14);
    }
    @media $smallerThanTablet and (orientation: portrait) {
      align-items: center;
      justify-content: flex-end;
    }
  }

  .content {
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: rem(360);

    @media $tablet {
      margin-bottom: rem(56);
      align-items: flex-start;
    }
    @media $smallerThanTablet and (orientation: portrait) {
      margin-bottom: rem(128);
    }
    @media $toSmallerMobile {
      width: rem(300);
      margin-bottom: rem(84);
    }

    &.marginBottomWithBothOverlap {
      @media $desktop {
        margin-bottom: rem(48);
      }
      @media $tablet and $toDesktop {
        margin-bottom: rem(16);
      }
    }
    &.marginBottomWithBottomOverlap {
      @media $desktop {
        margin-bottom: rem(105.6);
      }
      @media $tablet and $toDesktop {
        margin-bottom: rem(105.6);
      }
    }

    &.marginBottomWithTopOverlap {
      @media $desktop {
        margin-bottom: 0;
      }
      @media $tablet and $toDesktop {
        margin-bottom: 0;
      }
    }
  }

  .topFeatureBtybContainer {
    margin-bottom: rem(65);

    @media $smallerThanTablet and (orientation: portrait) {
      margin-bottom: rem(18);
      margin-top: rem(31);
    }
  }

  .overlayBottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.67) 100%
    );
    height: rem(99);

    @media $smallerThanTablet {
      height: rem(125);
    }
  }
}
