@import 'import.pcss';

:local {
  .autoPlayOverlayContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    font-family: $ff-content;
    font-weight: $fw-600;
    color: $c-white;
    left: 0;
    bottom: rem(150);
    z-index: 3;
    transition: bottom 0.3s ease, transform 0.4s ease;

    @media $toDesktop and (orientation: portrait) {
      padding: 0 rem(14);
      @media $fromTablet {
        padding: 0 rem(24);
      }
    }
    @media $toDesktop and (orientation: landscape) {
      padding: 0 rem(17);
      bottom: rem(97);
      left: auto;
      right: 0;
    }

    @media $tablet {
      z-index: 1;
    }

    @media $desktop {
      padding: 0 rem(26);
      bottom: rem(97);
      left: auto;
      right: 0;
    }
  }

  .autoPlayControlBar {
    z-index: 2;
  }

  .nextEpisodeContainer {
    position: relative;
    border-radius: rem(5);
    width: 100%;

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: rem(12);
    }

    .buttonsWrapper {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .nextEpisodeProgressBarWrapper {
        display: flex;
        align-items: flex-end;
        position: relative;
        justify-content: flex-end;
        width: auto;
        margin-left: rem(9);

        progress {
          position: absolute;
          bottom: 0;
          height: 3px;
          width: rem(120);
          border-radius: 1px;
          right: 0;
          left: auto;
        }

        progress::-webkit-progress-bar {
          background-color: $c-light-red;
        }

        progress::-webkit-progress-value {
          background-color: $c-white;
        }
      }

      button {
        padding: 0;
        font-size: rem(12);
      }

      .redButton {
        width: rem(120);
        background: $c-red-new;
        height: rem(36);
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: $c-dt-red;
        }
      }

      .grayButton {
        display: inline;
        width: rem(118);
        background: $c-lightgrayishred-06;
        height: rem(36);
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:hover {
          background-color: $c-white;
          color: $c-red-new;
        }
      }
    }

    .title {
      @media $toTablet and (orientation: portrait) {
        display: none;
      }
    }
  }
}

.video-js:not(.vjs-has-started) .autoPlayNextGradient,
.video-js.not-hover .autoPlayNextGradient,
.video-js.vjs-user-inactive .autoPlayNextGradient,
.video-js.not-hover.vjs-chromecast-loaded .autoPlayNextGradient {
  opacity: 1;
}
