@import 'import.pcss';

.ImageResponsive {
  padding-top: 56.25%;
  height: 0;
  position: relative;
}

/*----------  Modifiers  ----------*/
.ImageResponsive {
  &--gradient {
    &:after {
      content: '';
      position: absolute;
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.25) 100%
      );
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      height: 100%;
    }
  }
  &--animation &__img {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: 0.3s;
  }

  &--square {
    padding-top: 100%;
  }
}

/*----------  Children  ----------*/
.ImageResponsive {
  &__img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}
