
@mixin resetBtn {
  padding: 0;
  margin: 0;
  font-size: inherit;
  text-decoration: none;
  background: none;
  border: 0;
  outline: 0;
}
