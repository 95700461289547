@import 'import.pcss';

$spacing: rem(12);

.Notification {
  // since we are changing the '.Section--page' to use width: 100%
  // then we have to centralise this component here using the values
  // that are being used in '.Section--page' in order to match the page alignment
  // that's why we have the sectionPageAlignment mixin
  @include sectionPageAlignment;

  display: flex;
  align-items: center;

  & img {
    margin: $spacing;
    margin-left: 0;
  }

  &__text-wrapper {
    margin: $spacing 0;
  }

  &__heading {
    display: inline;
    margin-right: rem(5);
    font-weight: $fw-700;
  }

  &__paragraph {
    display: inline;
    font-size: $fz-body1;
  }

  &--link {
    cursor: pointer;
  }
}

.Notification.notification-margin {
  margin-top: 0;
}