@import 'import.pcss';

:local {
  .categoryWrapper {
    display: flex;
    align-items: center;
    margin-right: rem(3);
    background: $c-darkgrey-1;
    border-radius: rem(1);
    .category {
      padding: rem(10) rem(22) rem(10) rem(24);
      background: transparent;
      border: none;
      color: $c-white;
      opacity: 0.6;
      font-weight: 600;
      font-size: rem(13);
      text-decoration: none;
      display: flex;
      align-items: center;
      text-align: center;
      transition: background-color .2s ease-in-out;
      user-select: none;
    }

    &.active {
      background: $c-darkerred;

      .category {
        opacity: 1;
      }
    }

    &.highlightCategory {
      .category {
        background-color: $c-darkerred; 
        opacity: 1;
      }
    }

    .liveCircle {
      height: rem(5);
      padding-left: rem(6);
      padding-top: rem(2);
    }
  }

  .carousel {
    position: relative;
    padding: 0;

    &:first-of-type {
      @media $toTablet {
        margin-left: $mobileComponentsHorizontalSpace;
        padding-right: $mobileComponentsHorizontalSpace;
      }
    }
  }
}
