@import 'import.pcss';

:local {
  .comingSoonContainer {
    display: flex;
    flex-direction: column;

    @media $tablet {
      flex-direction: row-reverse;
      > div {
        width: 50%;
      }
    }
    @media (min-width: 1200px) {
      flex-direction: row;
    }
  }
  .comingSoonVodChild {
    min-width: 100%;
  }

  @media $tablet and (max-width: 1199px) {
    .comingSoonContainer {
      flex-direction: column;
      > div {
        width: 100%;
        > div {
          justify-content: center;
          height: rem(92);
        }
      }
    }
  }
}