@import 'import.pcss';

:local {
  .PromoShelf {
    padding-right: $mobileComponentsHorizontalSpace;

    @media $tablet {
      padding-right: $tabletComponentsHorizontalSpace;
    }
    @media $desktop {
      padding: 0;
    }

    :global(.swiper-container) {
      overflow: visible;
    }

    :global(.swiper-wrapper) {
      align-items: stretch;
    }

    :global(.swiper-slide) {
      height: auto;
      min-height: 100%;
      align-self: stretch;

      a {
        display: block;
        height: 100%;
      }
    }
  }

  .PromoShelf {
    &__carousel {
      position: relative;
      padding: 0;
    }
  }
}
