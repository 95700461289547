@import 'import.pcss';

:local {
  .imageCardDetailsContainer {
    display: inline-block;
    color: $c-ee-white-06;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 86%;
    padding-left: rem(12);
    padding-bottom: rem(11);
  }
}
