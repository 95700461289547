@import 'import.pcss';

:local {
  .connectNotSharedContainer {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      180deg,
      rgba(166, 0, 22, 0.4) 0px,
      rgba(0, 0, 0, 0.4) 191px
    );
    margin: rem(-37) rem(-30) rem(24);
    padding: rem(21) rem(13) rem(18);
    font-size: rem(14);
    line-height: rem(17);
    font-weight: $fw-500;
    border-bottom: 1px solid $c-red-new-05;

    .aflLogo {
      margin-left: rem(57);
      margin-bottom: rem(8);
    }

    .providedContainer {
      display: flex;
    }

    .dangerWrap {
      margin-right: rem(28);

      .danger {
        height: rem(28);
        min-width: rem(28);
        width: rem(28);
      }
    }

    .browserContainer {
      display: flex;
      margin-top: rem(16);

      .browserIconWrap {
        margin-right: rem(16);
        .browserIcon {
          min-width: rem(40);
        }
        &.browserIconWrapAndroid {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: $c-ee-white-02;
          min-width: rem(40);
          .browserIcon {
            min-width: rem(36);
          }
        }
      }
    }

    .buttonWrap {
      padding-left: rem(56);
    }

    .button {
      min-height: rem(34);
      min-width: rem(121);
      padding: rem(8) rem(12);
      border: 1px solid $c-white;
      border-radius: rem(2);
      font-weight: $fw-600;
      font-size: rem(14);
      line-height: rem(18);
      color: $c-white;
      background-color: transparent;
      margin-top: rem(16);
    }

    @media $fromMobile {
      margin: rem(-37) rem(-84) rem(36);
      padding: rem(34) rem(38) rem(36) rem(40);
      border-bottom: 2px solid $c-red-new-05;

      .checkWrap {
        margin-right: rem(52);
      }
      .browserContainer .browserIconWrap {
        margin-left: rem(-6);
        margin-right: rem(22);
      }
    }
  }
}
