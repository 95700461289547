
@mixin v-align($position: absolute) {
  position: $position;
  top: 50%;
  -ms-transform: translateY(-50%); /* IE */
  -moz-transform: translateY(-50%); /* Firefox */
  -webkit-transform: translateY(-50%); /* Safari and Chrome */
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
