.loader-60devs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  opacity: 1;
  background: $c-lightergrey;
  transition: opacity 250ms 250ms;
}

.loader-60devs .loader-60devs-progress {
  height: 100%;
  width: 100%;
  background-color: $c-red;
  transition-property: transform, opacity;
  transition-duration: 12s;
  transition-timing-function: cubic-bezier(0.04, 0.9, 0.11, 0.9);
  transform: translateX(-100%);
}

.loader-60devs[data-state='hidden'] {
  display: none;
  transition: none;
}

.loader-60devs[data-state='hidden'] .loader-60devs-progress {
  transition: none;
}

.loader-60devs[data-state='running']::before {
  content: '';
  position: absolute;
  z-index: 99999999;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader-60devs[data-state='running'] .loader-60devs-progress {
  transform: translateX(0);
}

.loader-60devs[data-state='finishing'] {
  opacity: 0;
}

.loader-60devs[data-state='finishing'] .loader-60devs-progress {
  transform: scaleX(100) translateX(0);
}
