// ==================
// Resets/base styles
// ==================

/* FIXME: These reset styles seem haphazard and random, use a well-thought-out
and up to date way to normalise browser styles */

/* TODO: Refactor out the use of placeholders from this file */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

%base-font {
  font-size: em(16);
}

html,
body {
  width: 100%;
  margin: 0;
  font-size: 100%;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
a,
b,
i,
span,
article,
figure,
header,
footer,
section,
hgroup,
fieldset {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  // font-size: 16px;
}

li {
  list-style-type: none;
}

ol li {
  list-style-type: decimal;
}

.ul-default,
.ul-default li {
  list-style-type: disc;
}

fieldset {
  border: none;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a img {
  border: 0;
  outline: none;
}

object,
embed {
  outline: 0;
}

img {
  max-width: 100%;
}

// =========
// Extenders
// =========

%resetBtn {
  padding: 0;
  margin: 0;
  font-size: inherit;
  background: none;
  border: 0;
  outline: 0;
}

.resetBtn {
  padding: 0;
  margin: 0;
  font-size: inherit;
  background: none;
  border: 0;
  outline: 0;
}

//================
// Utility Classes
//================

.vac {
  display: table;
  height: 100%;
  margin: 0 auto;
}

.vac-inner {
  display: table-cell;
  vertical-align: middle;
}

// Floats
// ======

%u-fl,
%fl,
.fl {
  float: left;
}

.fr {
  float: right;
}

.fn {
  float: none;
}

// Display/visibility
// ==================

.cell {
  display: table-cell;
  vertical-align: middle;
}

%db,
.db {
  display: block;
}

.dib {
  display: inline-block;
}

.hide {
  display: none;
}

/* FIXME: Don't be cheeky with naming things, be descriptive */
%ninja,
.ninja {
  visibility: hidden;
}

.table {
  display: table;
  height: 100%;
}

// Width/height
// ============

.h-100 {
  height: 100%;
}

//Scrolling
// ======

.noScroll {
  overflow-y: hidden;
}

// Margin
// ======

%m0,
.m0 {
  margin: 0;
}

%ma,
.m0a {
  margin: 0 auto;
}

%maa,
.ma {
  margin: auto;
}

.mh-100 {
  min-height: 100%;
}

// Padding
// =======

.noPad {
  padding: 0 !important;
}

// Position
// ========

.inner,
.pos-r,
.rel {
  position: relative;
}

%pos-a,
.pos-a {
  position: absolute;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

// Text
// ====

.nowrap,
.no-wrap {
  white-space: nowrap;
}

.ttu {
  text-transform: uppercase;
}

%tac,
.tac {
  text-align: center;
}

%tar,
.tar {
  text-align: right;
}

// http://blogs.adobe.com/webplatform/2014/08/13/one-weird-trick-to-baseline-align-text/
.strut {
  display: inline-block;
  height: 1em;
}
