@import 'import.pcss';

:local {
  .container {
    display: flex;
    flex-direction: column;
    margin: rem(37) 0 0;
    font-weight: $fw-500;
  }

  .intro {
    font-size: rem(18);
    line-height: rem(22);
    color: $c-dt-lightestgrey;
    text-align: center;
    margin: 0 0 rem(43);
    font-weight: $fw-700;
  }

  .description {
    font-size: rem(14);
    line-height: rem(20);
    color: $c-dt-lightgrey;
    text-align: center;
    margin: 0 0 rem(53);
  }

  .learnWhy {
    margin: 0 0 rem(72);
    font-size: rem(14);
    line-height: rem(18);
    color: $c-dt-lightestgrey;
    text-align: center;
    text-decoration: none;
    background: none;
    border: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .continue {
    @extend %submit-button;

    text-decoration: none;
  }
}