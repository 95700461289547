@import 'import.pcss';

:local {
  .connectSharedContainer {
    display: flex;
    padding: rem(24) rem(32) rem(16);
    margin: rem(-37) rem(-30) rem(16);
    color: #dedede;
    font-size: rem(14);
    line-height: rem(17);
    font-weight: $fw-500;
    position: relative;

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.4) 0%,
        #000000 100%
      );
      opacity: 0.4;
    }

    .checkWrap {
      margin-right: rem(36);
      margin-top: rem(14);
      .check {
        min-width: rem(28);
      }
    }
    @media $fromMobile {
      height: rem(129);
      margin: rem(-37) rem(-84) rem(48);
      padding: rem(34) rem(38) rem(36) rem(46);

      .checkWrap {
        margin-right: rem(52);
      }
    }
  }
}
