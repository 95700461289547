@import 'import.pcss';

:local {
  .upcomingLiveModalDialog {
    padding: rem(26);
    display: flex;
    > div:nth-child(2) {
      width: rem(340);
      max-width: 100%;
      > button {
        display: none;
      }
    }

    :global(.close-dialog.close-dialog.close-dialog) {
      max-width: rem(120);
      height: rem(36);
      padding: rem(5);
      font-size: rem(12);
      font-style: normal;
      font-weight: $fw-600;
      border-radius: rem(1);
      background-color: $c-red-new;
    }
  }
  .upcomingLiveModalContainer {
    margin: rem(-18) rem(-18) 0 rem(-18);

    .image {
      height: rem(175);
      width: 100%;

      img {
        object-fit: cover;
        height: 100%;
      }
    }

    .logo {
      height: rem(27);
      margin-top: rem(26);

      img {
        height: 100%;
      }
    }

    .arrivalDate {
      font-size: rem(15);
      line-height: rem(18);
      font-weight: $fw-600;
      margin-top: rem(16);
    }

    .title {
      margin-top: rem(10);
      color: $c-white;
      text-align: center;
      font-size: rem(13);
      font-weight: $fw-600;
      @include ellipsis(2);
    }

    .liveText {
      color: $c-white;
      font-size: rem(12);
      line-height: rem(18);
      text-align: center;
      font-weight: $fw-600;
      margin-top: rem(10);
    }
  }
  .backdrop {
    position: absolute;
  }

  @media $fromTablet {
    .upcomingLiveModalDialog {
      > div:nth-child(2) {
        width: rem(546);
        > button {
          display: flex;
          top: rem(2);
          right: rem(3);
          z-index: 1;
          img {
            top: rem(12);
          }
          &:focus-visible {
            outline: 1px solid $c-red-new;
          }
        }
      }

      :global(.close-dialog.close-dialog.close-dialog) {
        max-width: rem(377);
        height: rem(46);
        font-size: rem(14);
      }
    }
    .upcomingLiveModalContainer {
      margin: rem(-32) rem(-32) 0 rem(-32);
      .image {
        height: rem(308);
      }
      .logo {
        margin-top: rem(32);
        height: rem(36);
      }
      .arrivalDate,
      .title {
        margin-top: rem(12);
      }
      .liveText {
        margin-top: rem(15);
      }
    }
  }
}
