@import 'import.pcss';

:local {
  .prompt {
    z-index: 100000;
    width: rem(502);
    height: rem(69);
    position: fixed;
    top: rem(58);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      270.26deg,
      rgba(114, 0, 18, 0.75) 18.47%,
      rgba(114, 0, 18, 0) 99.82%
    );
    opacity: 0;
    transition: opacity 1s ease-in-out;
    pointer-events: none;

    &.middlePromptError {
      transition: opacity 0.5s ease-in-out;
      height: rem(90);
      background: linear-gradient(270.26deg, rgba(0, 0, 0, 0.75) 18.47%, rgba(0, 0, 0, 0.64) 56.18%, rgba(0, 0, 0, 0) 99.82%);

      @media $toMobile {
        height: rem(81);
      }
    }

   &.middlePromptSuccess {
    height: rem(90);
    background: linear-gradient(270.26deg, rgba(114, 0, 18, 0.75) 18.47%, rgba(114, 0, 18, 0.64) 56.18%, rgba(114, 0, 18, 0) 99.82%);

    @media $toMobile {
      height: rem(81);
    }
   }

    &.bigPrompt {
      height: rem(107);
      background: linear-gradient(270.26deg, rgba(114, 0, 18, 0.75) 18.47%, rgba(114, 0, 18, 0.64) 56.18%, rgba(114, 0, 18, 0) 99.82%);
    }

    &.promptFadeIn {
      opacity: 1;
      pointer-events: auto;
    }

    @media $toMobile {
      padding-left: 50%;
      top: rem(44);
      width: 150%;
      height: rem(66);
    }

    @media $fromMobile {
      padding-left: 10%;
    }

    @media $desktop {
      padding-left: 6%;
    }
    
    .promptTriangle {
      position: absolute;
      height: rem(50);
      top: rem(6);
      right: rem(6);
      background: transparent;
      border-style: solid;
      border-width: 0 rem(50) rem(50) 0;
      border-color: transparent rgba(190, 0, 30, 0.1);
      border-bottom-right-radius: rem(1);
      border-top-left-radius: rem(1);
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $c-white;
      font-size: rem($fz-body2);
      font-weight: $fw-600;
  
      .firstRowText {
        padding-bottom: rem(3);
      }
    }
  }
}