@import 'import.pcss';

:local {
  .geoPlayerErrorModalWrap {
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: $ff-content;

    &.geoPlayerErrorWrongMarketModalWrap {
      background: $c-black;
    }
  }
  .geoPlayerErrorModalDialog {
    display: flex;
    flex-direction: column;
    font-family: $ff-content;
    position: relative;

    @media $toDesktop {
      width: 100%;
      height: 100%;
    }

    & > div:first-child {
      position: relative;

      > button {
        background: transparent;
        border-style: solid;
        border-width: 0 rem(50) rem(50) 0;
        border-color: transparent rgba(230, 0, 30, 0.1);
        border-bottom-right-radius: rem(1);
        border-top-left-radius: rem(1);
        color: $c-red-new;
      }

      > div {
        margin-top: rem(12);
      }
    }
  }
  .geoWrongMarketContainer {
    width: rem(818);
    height: rem(390);
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $c-app-bg;
    padding: rem(32);

    .geoWrongMarketContainerTitle {
      font-size: rem(32);
      line-height: rem(40);
      color: $c-white;
      font-weight: $fw-600;
      max-width: rem(634);
      margin-bottom: rem(75);
    }

    .geoWrongMarketContainerButton {
      font-size: rem(28);
      color: $c-white;
      font-weight: $fw-600;
      line-height: rem(28);
      background-color: $c-dt-red;
      padding: rem(11) rem(38) rem(12);
      border-radius: rem(2);
      text-decoration: none;
    }
  }
  .geoPlayerErrorWrongMarketModalDialog {
    width: rem(818);
    height: rem(390);
    padding: 0;
    max-width: 100%;
    max-height: 100%;
    & > div:first-child {
      width: 100%;
      padding: 0;
      > div {
        margin-top: 0;
      }
    }
  }
}
