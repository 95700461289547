@import 'import.pcss';

:local {
  .link {
    text-decoration: none;
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: -1;
    transition: opacity .2s, background-color .2s;

    &.active {
      opacity: .25;
    }
  }

  .card {
    position: relative;
    padding: rem(15) rem(10) rem(8);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: rem(121);
    height: rem(104);
    box-sizing: border-box;

    &:hover {
      .background {
        opacity: 0;

        &.active {
          opacity: .25;
        }
      }
    }
  }

  .logoWrapper {
    display: flex;
    max-width: rem(100);
  }

  .titleWrapper {
    display: flex;
    align-items: flex-end;
    height: rem(24);
    margin-top: rem(10);
  }

  .title {
    font-size: rem(10);
    line-height: rem(12);
    margin-bottom: 0;
    text-align: center;
  }

  .progress {
    margin-top: rem(8)
  }

  .schedule {
    margin-top: rem(7);
    font-size: rem(10);
    line-height: rem(13);
    opacity: 0.8;
  }

  .arrow {
    width: 0; 
    height: 0; 
    border-left: rem(8) solid transparent;
    border-right: rem(8) solid transparent;
    position: absolute;
    bottom: rem(-8);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    opacity: .25;
  }
}