@import 'import.pcss';

$connectDeviceErrorMarginTop: 6;
$connectDeviceContinueButtonMarginTop: 60;

%link-styled-as-large-button {
  @extend %link-as-button;
  padding-top: rem(20);
  padding-bottom: rem(20);
  width: 100%;
  font-size: rem(14);
  font-weight: $fw-600;
}

%ConnectDeviceInputPlaceholder {
  &::placeholder {
    color: $c-dt-lightergrey;
    text-transform: none;
  }
  // the below is required to fix IE. It won't work if you do have both rules with the same
  // content as "&::placeholder, &:-ms-input-placeholder {" instead of two separate queries
  &:-ms-input-placeholder {
    color: $c-dt-lightergrey;
    text-transform: none;
  }
}

.ConnectDevice {
  background: none;

  &__enter-code-wrapper {
    display: flex;
    flex-direction: column;
  }

  // creation of BEM classes using ConnectDevice as Block
  &__enter-code-title,
  &__sign-in-register-title {
    margin-bottom: rem(37);
    font-size: rem(16);
    font-weight: $fw-600;
    text-align: center;
  }

  &__image-line-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--line {
      width: rem(141.52);
      height: rem(3.57);
      background-color: $c-gray;
      margin-top: rem(14.9);
      margin-bottom: rem(55);
    }
  }

  &__image-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
      &:first-of-type {
        border-radius: rem(3.57);
        border: rem(3.57) solid $c-gray;
      }

      &:last-of-type {
        position: absolute;
      }
    }
  }

  &__form-input-wrapper {
    margin-bottom: rem(100);

    @media $toMobile {
      margin-bottom: rem(60);
    }
  }

  &__buttons-wrapper {
    display: flex;
    margin-bottom: rem(29);
    width: 100%;

    a {
      width: 50%;
      border-radius: 1px;
      background-color: $c-dt-red;
      font-size: rem(14);
      color: $c-ee-white-08;
      height: rem(46);
      font-weight: 600;
      transition: all 0.3s ease-in-out;
      border: 1px solid transparent;
      border-radius: 1px;
      margin-bottom: 0;

      &:not([disabled]):hover {
        background-color: $c-red-new;
        color: $c-white;
      }

      &:first-of-type {
        margin-right: rem(8.5);
      }

      &:last-of-type {
        margin-left: rem(8.5);
        color: rgba(190, 0, 30, 0.8);
        background-color: $c-white;

        &:not([disabled]):hover {
          color: $c-red-new;
          background-color: $c-white;
        }
      }
    }
  }

  &__download7plusMessage {
    text-align: center;
    font-size: rem(13);
    padding: 0 rem(10);
    padding-bottom: rem(56);
    margin-top: rem(21);
  }

  //----content-copy -----//

  &__enter-code-message,
  &__sign-in-register-message {
    text-align: center;
    font-size: rem(14);
    max-width: 100%;
    line-height: 16.51px;
    color: $c-silver;

    &.Text {
      color: $c-silver;
    }
  }

  &__enter-code-message {
    margin-bottom: rem(9);

    @media $fromMobile {
      margin-top: rem(47);
      margin-bottom: rem(13);
    }
  }

  &__sign-in-register-message {
    &--primary {
      margin-bottom: rem(22);
    }

    &--secondary {
      margin-bottom: rem(32);
    }
  }

  &__go-back-home-page {
    color: $c-white;
    text-decoration: none;
    font-size: rem(12);
    font-weight: $fw-600;
  }

  &__create-account,
  &__sign-in {
    margin-top: rem(20);
    margin-bottom: rem(40);
    text-align: center;
  }

  &__continue-button {
    width: 100%;
    font-weight: $fw-600;
    font-size: rem(14);
    background-color: $c-dt-red;
    height: rem(46);
    border-radius: rem(1);
    border: none;

    &[disabled] {
      background: $c-darkred;
      color: $c-ee-white-05;
    }

    &:not([disabled]):hover {
      background-color: $c-red-new;
      color: $c-white;
    }
  }

  &__register-button {
    @extend %link-styled-as-large-button;
    background-color: $c-dt-red;
    color: $c-white;

    &:hover {
      background-color: $c-dt-red;
    }

    @media $fromMobile {
      margin-bottom: rem(7);
    }
  }

  &__sign-in-button {
    @extend %link-styled-as-large-button;
    margin-bottom: rem(14);
    border: 1px solid $c-dt-linegrey;
    background-color: $c-transparent;
    color: $c-white;

    &:hover {
      background-color: $c-transparent;
    }
  }

  //-----buttons--------//

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &__spinner {
    position: absolute;
    width: rem(26);
    height: rem(26);
    margin-top: -13px;
    margin-left: -13px;
    border-radius: 50%;
    border: solid rem(4) #90a8f9;
    border-top-color: $c-white;
    animation: spinner 0.6s linear infinite;
  }
}

.ConnectDevice {
  // TODO: revisit those 'adjustment' rules below since some of them might not be needed anymore
  // adjustment of elements that are inside of ConnectDevice
  a.Button {
    // TODO: remove this once we don't have a.Button structure anymore, only <a> or <button>
    width: 100%;
  }

  .FormInput {
    &__input {
      @extend %ConnectDeviceInputPlaceholder;
      text-align: center;
      font-size: rem(45);
      font-weight: $fw-600;
      text-transform: uppercase;

      &::placeholder {
        font-size: rem(45);
        font-weight: $fw-600;
        color: $c-ee-white-04;
      }
    }

    &__label {
      min-height: rem(66);
      margin-bottom: rem(8);
    }

    &__message {
      margin: 0 0 rem(8) 0;
      height: auto;
      color: $c-dt-red;
      font-size: rem(10);
      font-weight: 500;
    }
  }

  & .ButtonLink {
    margin-bottom: rem(41);
  }

  & .RedirectSuccessMessage__success-tick {
    margin-top: rem(15);
  }

  & .Button__element {
    width: 100%;
    height: rem(59);
    font-size: rem(14);
  }
}
:local {
  .panel {
    margin-top: rem(10) !important;
    & > div > div {
      padding-bottom: rem(50);
    }
    :global(.ConnectDevice__enter-code-title) {
      margin-bottom: rem(22);
    }
  }
}
