@import 'import.pcss';

/* TODO - these blues should match with the
global colours */
$c-navigation-text: #0099ff;
$c-navigation-bg: #007aff;
$c-navigation-hover: #0086ff;

:local {
  .navigation {
    color: $c-dt-lightgrey;
    font-size: rem(14);
    width: 100%;
    transition: background 0.5s ease-in-out;
  }

  .itemIcon {
    display: none;

    @media $fromTablet {
      display: block;
      max-height: rem(18);
      padding-right: rem(5);
      opacity: 1;
    }
    &:hover {
      opacity: 1 !important;
    }
  }

  // handles positioning and spacing of navItems in sidebar
  .navItemSidebar {
    padding: 0 rem(20) 0 rem(22);
    width: 100%;
    background-color: $c-dt-darkgrey-complementary;
  }

  // handles shared styles for navItems in sidebar and header
  .navItemHeader,
  .navItemSidebar {
    position: relative;
    height: rem(44);
    display: flex;
    align-items: center;
    border-bottom: rem(1) solid $c-black;
    color: $c-ee-white-08;

    &:hover {
      background-color: $c-dt-darkgrey;
    }

    @media $desktop {
      width: auto;
      height: 100%;
      justify-content: center;
      border: none;

      &:hover {
        background-color: transparent;
      }
    }
  }

  // handles positioning and spacing of navItems in header
  .navItemHeader {
    white-space: nowrap;
    z-index: 1;
    margin-top: rem(11);
    width: auto;
    height: calc(100% - rem(11));
    justify-content: center;
    border: none;
    color: $c-white;
    align-items: flex-start;

    &:hover {
      background-color: transparent;
      opacity: 1;
    }

    &:first-child .link {
      padding: 0 rem(11) 0 0;
    }

    .navItemArrow {
      @media $toDesktop {
        display: none;
      }
    }

    @media $fromMobile {
      margin-top: rem(19);
      height: calc(100% - rem(19));
    }
  }

  @media (max-width: 550px) {
    .navItemHeader:nth-child(n + 3) {
      display: none;
    }
  }

  @media (max-width: 650px) {
    .navItemHeader:nth-child(n + 4) {
      display: none;
    }
  }

  @media (max-width: 750px) {
    .navItemHeader:nth-child(n + 5) {
      display: none;
    }
  }

  @media (max-width: 850px) {
    .navItemHeader:nth-child(n + 6) {
      display: none;
    }
  }

  @media (max-width: 950px) {
    .navItemHeader:nth-child(n + 7) {
      display: none;
    }
  }

  @media (max-width: 1205px) {
    .navItemHeader:nth-child(n + 8) {
      display: none;
    }
  }

  .openSubNav {
    border-bottom: rem(1) solid $c-dt-darkgrey-complementary;
  }

  .link {
    display: flex;
    align-items: center;
    font-size: rem(15);
    font-weight: $fw-600;
    opacity: 0.8;
    padding: 0 rem(11);
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    @media $toTablet {
      font-size: rem(13);
      line-height: rem(17);
      justify-content: space-evenly;
      margin-bottom: rem(3);
    }

    @media $fromTablet {
      line-height: rem(18);
    }
  }

  .link.linkSelected {
    opacity: 1;
  }

  .linkSidebar {
    display: flex;
    align-items: center;
    font-size: rem(14);
    font-weight: $fw-600;
  }

  .navItemArrow {
    width: rem(10);
    height: rem(10);
    cursor: pointer;
    transition: transform 0.2s ease;
    top: 100%;
    right: 100%;
    margin-left: rem(6);
  }

  .mobileNavItemWithItems {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .navItemSidebar {
      &:hover {
        background-color: $c-dt-darkgrey-complementary;
      }

      .rightSide {
        margin-left: rem(6);
      }
    }

    .arrowContainer {
      min-width: 20%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border: none;
      border-bottom: rem(1) solid $c-black;
      background-color: $c-dt-darkgrey-complementary;
      padding: 0 rem(20) 0 0;
      outline: 0;
      -webkit-tap-highlight-color: transparent;

      &:focus {
        outline: 0;
      }

      &.openSubNav {
        border-bottom: rem(2) solid $c-dt-darkgrey-complementary;
      }
    }
    &.mobileNavItemWithSubNav .navItemSidebar {
      width: auto;
    }
  }

  .arrowContainer {
    @media $desktop {
      order: 3;
      min-width: rem(15);
    }
  }

  .tintable {
    z-index: 1;
    opacity: 1;
    color: $c-dt-lightestgrey;
    @media $fromLargeTablet {
      color: $c-white;
    }
  }

  .faded {
    opacity: 0.6;
  }

  .disabled {
    pointer-events: none;
    opacity: 1;
  }

  .rightSide {
    order: 2;
    height: rem(5);
    padding-right: 0;
    margin-top: rem(2);
    display: block;
    margin-left: rem(6);
    @media $fromTablet {
      margin-left: rem(1);
      margin-top: rem(3);
    }
    @media $desktop {
      margin-left: rem(6);
    }
    ~ .arrowContainer {
      @media $fromTablet {
        margin-left: rem(5);
      }
    }
  }

  .subLink {
  }
}

.NavigationPanel {
  background: $c-navigation-bg;
  padding: em(15);
  color: $c-white;
  position: relative;
}

/*----------  Children  ----------*/
.NavigationPanel {
  &__chevron {
    $fz: 25;
    font-size: em($fz);
    height: em($fz, $fz);
    @include tbma;
    top: em(1, $fz);
    right: em(20, $fz);
  }
}

.NavigationList {
  width: 100%;
  padding: em(10) 0;
}

/*----------  Children  ----------*/
.NavigationList {
  $root: &;

  &__item {
    display: block;
    position: relative;
    background: $c-black;
    color: $c-dt-lightestgrey;
    text-decoration: none;
    padding: em(10) em(20);
    border-bottom: 1px solid $c-linegrey;
    font-weight: $fw-500;
    &:hover {
      background: $c-navigation-hover;
      color: $c-white;
      .fa,
      #{$root}__txt {
        transition: transform 0.3s;
        transform: translate3d(5px, 0, 0);
      }
    }
  }

  &__icon {
    width: em(30);
  }
  &__iconChevron {
  }
  &__txt {
    display: inline-block;
  }
}
