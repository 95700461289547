@import 'import.pcss';

:local {
  .progress {
    height: rem(2);
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    @media $desktop {
      height: rem(3);
    }
  }

  .line {
    height: 100%;
    transition: width 0.3s ease-in-out;
  }

  .left {
    opacity: 0.5;
    height: 100%;
    transition: width 0.3s ease-in-out;
  }
}


