@import 'import.pcss';

:local {
  .loader {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
    opacity: 1;
    background: $c-lightergrey;
    transition: opacity 250ms 250ms;

    .loaderProgress {
      height: 100%;
      width: 100%;
      background-color: $c-red;
      transition-property: transform, opacity;
      transition-duration: 12s;
      transition-timing-function: cubic-bezier(0.04, 0.9, 0.11, 0.9);
      transform: translateX(-100%);
    }

    &.hidden {
      .loaderProgress {
        transition: none;
      }
    }

    &.running {
      .loaderProgress {
        transform: translateX(0);
      }

      &::before {
        content: '';
        position: absolute;
        z-index: 99999999;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &.finishing {
      opacity: 0;

      .loaderProgress {
        transform: scaleX(100) translateX(0);
      }
    }
  }
}
