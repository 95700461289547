@import 'import.pcss';

:local {
  .Lozenge {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .solid {
    line-height: em(23);
    border-radius: em(12);
  }

  .squareBorderRadius {
    line-height: em(23);
    border-radius: em(6);
  }

  .smallWidth {
    display: inline-flex;
    padding-right: em(6);
    padding-left: em(6);
  }

  .fixedWidth {
    min-width: em(73);
  }

  .fluidWidth {
    display: inline-flex;
    padding-right: rem(11);
    padding-left: rem(11);
  }

  .new {
    display: inline-flex;
  }

  .red {
    background: $c-dt-red;
  }

  .lightRed {
    background: $c-red-new;
  }

  .lightGrey {
    background: $c-lightgrey;
  }

  .dtDarkGrey {
    background: $c-dt-darkgrey;
    border-radius: 18px;
    padding: em(4) em(13);
    line-height: em(15);

    span {
      font-weight: $fw-600;
      color: $c-dt-lightgrey;
      font-size: em(12);
    }
  }

  .blackOpaque {
    background: rgba(0, 0, 0, 0.3);
  }

  .borderLightgrey {
    border: 1.5px solid #b2b2b2;
  }

  .borderRounded {
    border-radius: em(100);
  }

  .inline {
    display: inline;
  }

  .light {
    position: relative;
    width: auto;
    padding: 0 em(16);
    padding-right: em(11);
    line-height: em(20);
    background: rgba(0, 0, 0, 0.3);
    border: em(1.5) solid #b2b2b2;
  }

  .lightPadded {
    @extend .Lozenge--light;

    padding: em(2) em(10);
  }

  .dark {
    position: relative;
    width: auto;
    padding: 0 em(13) 0 em(21);
    line-height: em(20);
    background: rgba(0, 0, 0, 0.5);
    border-radius: em(14);
  }

  .sideCut {
    position: absolute;
    height: rem(20);
    padding: rem(5) rem(5) rem(4) rem(8);
    white-space: nowrap;
    border-radius: 1px 0 0 1px;

    span {
      z-index: 1;
      font-weight: $fw-600;
      font-style: normal;
      font-size: rem(9);
      line-height: rem(11);
      text-align: left;
      letter-spacing: 0;
      text-transform: lowercase;
      &::first-letter {
        text-transform: capitalize;
      }
    }

    &:after {
      border-top: rem(20) solid $c-red-new;
      border-right: rem(11) solid transparent;
      border-left: rem(1) solid $c-red-new;
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .paddingRight {
    padding-right: rem(11);
  }

  .morePaddingRight {
    padding-right: rem(16);
  }

  .rectangle {
    height: rem(20);
    display: flex;
    align-items: center;
    padding-right: rem(11);
    padding-left: rem(11);
    border-top-right-radius: 4px;
  }

  .bigDot {

    &:after {
      @include pseudo;
      @include tbma;
      left: em(10);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $c-dt-red;
    }
  }

  .dot {
    padding: 0 em(10) 0 em(16);

    &:after {
      @include pseudo;
      @include tbma;
      left: em(10);
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background: #ed0103;
    }
  }
}
