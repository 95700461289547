@import 'import.pcss';

:local {
  .adIndexLabelWrap {
    color: rgba(250, 204, 35, 0.5);
    min-width: rem(40);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: rem(13);
    font-weight: $fw-600;
    .index {
      color: $c-ad-yellow;
      padding-right: rem(3);
    }
    @media (max-width: 767px) and (orientation: portrait) {
      position: absolute;
      bottom: rem(-57);
      right: calc(-100vw + rem(75));
    }
    @media $toDesktop and (orientation: landscape) {
      margin-top: rem(1);
    }
    @media $desktop {
      font-size: rem(15);
      margin-top: rem(-1);
    }
  }
}
