@import 'import.pcss';

:local {
  $sidebar-content-width: 384;

  .profileCompletion {
    padding-bottom: 0;
  }

  .profileCompletionContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    min-height: rem(564);
  }

  .title {
    margin: 0;
  }

  .lead {
    margin-top: rem(37);
    font-weight: $fw-500;
    color: $c-dt-lightestgrey;
  }

  .mandatory {
    display: block;
    max-width: rem($sidebar-content-width);
    padding: 0 rem(19);
    margin: rem(102) 0 rem(32);
    font-weight: $fw-500;
    color: $c-dt-lightestgrey;

    @media $toMobile {
      margin: rem(84) 0 rem(39);
    }

    .text {
      font-size: rem(14);
    }

    button {
      color: $c-dt-lightestgrey;

      &:hover {
        color: $c-dt-lightestgrey;
      }
    }
  }
}
