@import 'import.pcss';

:local {
  .card {
    position: relative;
    height: 100%;
    z-index: 0;
    box-sizing: border-box;
    border-radius: 1px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: $c-ee-lightblack;
      transition: background-color 0.2s;
    }

    &:hover::before {
      background-color: $c-nero;
    }

    .title {
      margin-bottom: rem(5);

      @media $toDesktop {
        font-size: rem(11);
      }

      @media $toMobile {
        font-size: rem(12);
      }
    }

    .subtitle {
      @media $toDesktop {
        font-size: rem(10);
      }

      @media $toMobile {
        font-size: rem(12);
      }
    }

    .cardWrapper {
      display: flex;
      flex-direction: column;
      pointer-events: auto;

      .imageWrapper {
        display: flex;
        position: relative;

        .logoNotifyMeContainer {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.95) 100%
          );
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;
          height: 43%;

          .logoNotifyMeWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: rem(11);
          }

          .logoWrapper {
            display: flex;
            align-items: center;
            margin-left: rem(11);
          }

          .button {
            display: flex;
            align-items: center;
            border-radius: rem(7);
            font-size: rem(13);
            margin-right: rem(10);
            font-weight: 600;
            color: $c-white;
            background-color: transparent;
            border: none;
            height: rem(25);
            white-space: nowrap;

            img {
              width: 9px;
              height: 9px;
              margin-right: 9px;

              @media $toDesktop {
                margin-right: rem(7);
              }
            }

            @media $toDesktop {
              margin-right: rem(8);
              margin-bottom: rem(4);
              font-size: rem(10);
              height: rem(21);
            }

            @media $toTablet {
              margin-right: rem(4);
              margin-bottom: rem(4);
              font-size: rem(11);
              height: rem(21);
            }
          }
        }
      }

      .dayDateText {
        display: flex;
        font-size: rem(18);
        margin-left: rem(10);
        margin-bottom: rem(7);
        font-weight: $fw-900;
        color: $c-white;
        border-bottom: 1px solid;

        .dateText {
          font-size: rem(18);
          font-weight: $fw-600;
          color: $c-white;
          padding-left: rem(5);

          @media $toTablet {
            font-size: rem(14);
          }
        }

        @media $toDesktop {
          margin-left: rem(9);
          margin-bottom: rem(6);
        }

        @media $toTablet {
          font-size: rem(14);
          margin-left: rem(7);
          margin-bottom: rem(5);
        }
      }

      .contentWrapper {
        height: rem(73);
        width: 100%;
        position: relative;

        .textWrapper {
          width: 100%;
          padding: rem(11) rem(11) rem(6) rem(11);

          .textElementWrapper {
            position: absolute;
            bottom: 6px;
          }

          .dateAndTitle {
            color: $c-white;
            font-size: rem(13);
            font-weight: $fw-600;
            @include ellipsis(2);
            width: 100%;

            @media $toTablet {
              font-size: rem(12);
            }
          }

          .highlightedText {
            padding-right: rem(5);
            font-size: rem(13);
            font-weight: $fw-600;

            @media $toTablet {
              font-size: rem(12);
            }
          }
        }
      }
    }
  }
}
