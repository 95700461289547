@import 'import.pcss';

:local {
  .container {
    display: flex;
    flex-direction: column;
    background: $c-app-bg-90;
    padding: rem(33);
    top: rem(65);
    position: absolute;
    left: 0;
    z-index: 9999;
    width: 100%;
    align-items: center;
    text-align: center;
    font-family: $ff-content;

    @media $tablet {
      max-width: rem(375);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .title {
    font-size: rem(13);
    line-height: normal;
    font-weight: 600;
    display: flex;
    gap: rem(6);
    align-items: center;

    img {
      height: rem(24);
    }
  }

  .message {
    font-size: rem(12);
    line-height: normal;
    font-weight: 300;
    margin-top: rem(24);
  }

  .error {
    font-size: rem(12);
    line-height: normal;
    font-weight: 300;
    margin-top: rem(24);
    color: $c-red-new;
  }

  .buttonContainer {
    display: flex;
    gap: rem(9);
    margin-top: rem(36);

    .button {
      font-size: rem(12);
      line-height: normal;
      font-weight: 600;
      min-width: rem(120);
      padding: rem(10);
      background: $c-red-new;

      &.secondary {
        background: $c-ee-white-04;
      }
    }
  }
}
