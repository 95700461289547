@import 'import.pcss';

:local {
  .separator {
    margin: 0 rem(7);
  }

  .textWithBullet {
    &::before {
      content: '• ';
      margin-right: rem(3);
    }
  }

  .boldText {
    color: $c-ee-white-08;
    font-weight: 700;
    font-size: rem(10);
  }

  .classificationLabel {
    color: $c-ee-white-08;
    font-size: rem(9);
    font-weight: 700;
    border-radius: 1px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.07) 100%
    );
    padding: rem(3) rem(6);
  }

  .yearLabel {
    margin-left: rem(8);
  }
}
