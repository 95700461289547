@import 'import.pcss';

:local {
  .skipRecapOverlayContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    font-family: $ff-content;
    font-weight: $fw-600;
    color: $c-white;
    left: 0;
    bottom: rem(150);
    z-index: 3;
    transition: bottom 0.3s ease, transform 0.4s ease, opacity 0.4s ease;
    opacity: 1;

    &.hidden {
      opacity: 0;
    }

    @media $toDesktop and (orientation: portrait) {
      padding: 0 rem(14);
    }
    @media $fromTablet and $toDesktop and (orientation: portrait) {
      padding: 0 rem(24);
    }
    @media $toDesktop and (orientation: landscape) {
      padding: 0 rem(17);
      bottom: rem(97);
      left: auto;
      right: 0;
    }

    @media $tablet {
      z-index: 1;
    }

    @media $desktop {
      padding: 0 rem(26);
      bottom: rem(97);
      left: auto;
      right: 0;
    }
  }

  .autoPlayControlBar {
    z-index: 2;
  }

  .skipRecapContainer {
    border-radius: rem(5);
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .button {
      padding: 0;
      font-size: rem(12);
      width: rem(120);
      background: $c-red-new;
      height: rem(36);
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: $c-dt-red;
      }
    }
  }
}
