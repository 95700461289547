@import 'import.pcss';

$easingCurve: cubic-bezier(0.25, 0.46, 0.45, 0.94);

:local {
  .tick {
    display: block;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20);
    height: rem(20);
    margin: 0 rem(9) 0 0;
    background: $c-dt-mediumgrey;
    border: solid rem(1) $c-dt-lightgrey;
    transition: border-color 0.2s $easingCurve;

    .tick {
      transition: transform 0.2s $easingCurve;
      transform: scale(0);

      g {
        fill: $c-dt-lightergrey;
      }
    }
  }

  .option {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: rem(14);
    font-weight: $fw-400;
    background: none;
    border: none;
    outline: none;

    &:hover:not([disabled]) .checkbox,
    &:focus:not([disabled]) .checkbox {
      border: solid rem(1) $c-dt-lightestgrey;
    }
  }

  .option.active .tick {
    transform: scale(0.8);
  }

  .option[disabled] .tick g {
    fill: $c-dt-darkgrey;
  }
}