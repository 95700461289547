@import 'import.pcss';
/*----------  Error overlay  ----------*/
///// Resets /////
.video-js.swm-error {
  .vjs-close-button,
  .vjs-errors-ok-button-container,
  .vjs-error-display:before,
  .vjs-errors-content-container > * {
    display: none;
  }

  .vjs-errors-dialog {
  }

  .vjs-errors-details {
    font-size: 0;
  }

  .vjs-errors-headline,
  .vjs-errors-details {
    display: block;
  }

  .vjs-errors-headline {
    padding: 0;
  }

  .vjs-errors-details {
    margin-top: 0;
  }

  .vjs-errors-message {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    display: block;
  }

  .vjs-errors-content-container {
    position: initial;
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
    padding: initial;
    overflow: initial;
  }

  .vjs-errors-dialog {
    // IE11 doesn't support initial
    background: none;
    border: none;
    bottom: 0;
    bottom: initial;
    left: 0;
    left: initial;
    overflow: initial;
    overflow: visible;
    padding: 0;
    padding: initial;
    position: initial;
    right: 0;
    right: initial;
    top: 0;
    top: initial;
  }

  /* .error-app-icon {
     width: 66px;
     border-radius: 15px;
     cursor: pointer;
     } */
}

.vjs-error-display {
  z-index: 2;
}

///// Custom styles /////
.video-js.swm-error {
  $fz: $fz-body2;

  .vjs-errors-content-container {
    font-family: $ff-content;
  }

  .vjs-errors-headline {
    @media $toMobile {
      font-size: em($fz-h5-mobile, $fz);
      max-width: 80%;
      margin: 0 auto;
      line-height: 1.3;
      padding-top: em(2, $fz);
    }

    font-size: em($fz-h4, $fz);
    font-weight: $fw-bold;
    padding-bottom: em(7, $fz-h4);
  }

  /** As we are setting font-size: 0 to
   .vjs-errors-details (the parent) to hide text in that container we need to
   explicitly set the font-size using rem() or px as em() will not calculate size
   correctly.
  */

  .vjs-errors-message {
    font-size: rem($fz);
  }

  .vjs-modal-dialog-content {
    @extend %flex-center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .vjs-modal-dialog {
    background-image: url('../../assets/img/Blur_1200.jpg');
    background-size: cover;
    background-position: center;
  }

  .player-error__link {
    padding-top: em(14, $fz);
    display: inline-block;
    font-size: em(11, $fz);
    color: $c-white;
    text-decoration-skip: ink;

    @media $fromMobile {
      font-size: em(14, $fz);
      padding-top: em(12, $fz);
      color: #aaaaaa;
    }
  }

  .player-error__button-link {
    display: block;
    margin-top: em(16, $fz);
    margin-left: auto;
    margin-right: auto;

    @media $fromMobile {
      margin-top: em(41, $fz);
    }
  }

  .player-error__button {
    font-size: em(14, $fz);
    background: linear-gradient(180deg, #ff0000 0%, #df0000 100%);
    color: $c-white;
    padding: em(4, $fz) em(20, $fz);
    min-width: em(94, $fz);
    background: linear-gradient(180deg, #ff0000 0%, #df0000 100%);
    font-weight: $fw-bold;
    outline: 0;
    border-radius: em(6, $fz);

    &:active {
      background: #df0000;
      box-shadow: inset em(0, $fz) em(0, $fz) em(2, $fz) darken(#df0000, 10);
    }

    @media $fromMobile {
      padding: em(11.5, $fz) em(37, $fz);
      min-width: em(128, $fz);
    }
  }

  .player-error__image-button {
    width: 66px;
    border-radius: 15px;
    cursor: pointer;
  }

  .vjs-errors-dialog {
    text-align: center;
    position: relative;
  }

  .player-error__detail {
    font-size: em(11, $fz);
    line-height: em(21, $fz);
    display: block;
    width: 90%;
    margin: 0 auto;

    @media $fromMobile {
      font-size: em(14, $fz);
    }
  }

  .vjs-errors-content-container {
    position: relative;

    @media $fromMobile {
      padding-top: em(32, $fz);
    }
  }
}
/*----------  End of Error overlay  ----------*/
