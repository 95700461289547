/* Default/About Theme */
:local {
  .markdownPanel {
    padding-right: rem(20);
    color: $c-darkgrey;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: rem(22);
      font-size: rem(20);
      font-weight: $fw-700;
      color: $c-dt-lightestgrey;

      @media $fromMobile {
        font-size: rem(24);
      }
    }

    strong {
      font-size: rem(14);
      font-weight: $fw-700;
      text-transform: uppercase;
    }

    hr {
      display: block;
      height: rem(1);
      margin-bottom: rem(16);
      background: $c-dt-darkgrey;
      border: 0;
      outline: 0;
    }

    p {
      margin-bottom: rem(12);
      font-size: rem(15);
      line-height: 1.8;
      color: $c-dt-lightgrey;

      @media $tablet {
        font-size: rem(16);
        line-height: 2.188;
      }
    }

    a:link, a:visited {
      color: $c-dt-lightestgrey;
    }
  }
}