@import 'import.pcss';

/* FIXME: Don't override global variables inside components */
$fz: 13;

:local {
  .background {
    padding-top: 20.8%; // Aspect ratio 78/375
    background-size: cover;
    background-color: white;
    background-position: 50% 0;

    @media $fromMobile {
      padding-top: 20.83333333%; // Aspect ratio  160/768
      background-position: 50% 0;
    }

    /*
      Using explicit 1024px breakpoint instead of variable below to show
      shorter banner on landscape tablets as the next break point is at 1025px
    */
    @media (min-width: 1024px) {
      padding-top: 12.152777777%; // Aspect ratio  175/1440
      background-position: center;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-image: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );

      @media $toMobile {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .detailsContainer {
    position: absolute;
    bottom: 0;
    z-index: 1;
    padding-left: em(15);

    @media $toMobile {
      @include tbma;
      @include align-center;
    }

    @media $fromMobile {
      padding-bottom: em(12);
      padding-left: em(28);
    }

    @media $desktop {
      padding-bottom: em(16);
      padding-left: pc(48, $site-width-desktop);
    }
  }

  .details {
    @include align-center;
  }

  .seriesThumbnailContainer {
    width: em(58);
    height: em(58);
    margin-right: em(12);
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

    @media $fromMobile {
      width: em(75);
      height: em(75);
      margin-right: em(16);
    }

    @media $desktop {
      width: em(83);
      height: em(83);
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .textContainer {
    @include align-center;
  }

  .showInfoContainer {
    @media $toMobile {
      margin-top: em(2);
    }
  }

  .titleContainer {
    margin-bottom: em(3);

    @media $fromMobile {
      margin-bottom: em(4);
    }

    @media $desktop {
      margin-bottom: em(2);
    }
  }

  .airDate {
    @include align-center;

    font-size: em($fz);
    font-weight: $fw-600;
    color: $c-dt-lightestgrey;

    @media $fromMobile {
      font-size: em($fz-h6);
    }
  }

  .seperator {
    @include align-center;

    padding: 0 em(10, $fz);
    font-size: em($fz);
    font-weight: $fw-600;
    color: $c-dt-lightgrey;

    @media $fromMobile {
      padding: 0 em(10, $fz-h6);
      font-size: em($fz-h6);
    }

    @media $desktop {
      padding: 0 em(14, $fz-h6);
    }
  }

  .circleContainer {
    display: flex;
  }

  .bannerContainer {
    position: relative;
    z-index: 2;

    // NOTE: required to increase specificity and overide Section.pcss
    &.banner-overrides {
      margin-right: 0;
      margin-left: 0;
    }

    &.first-component-in-page {
      margin-top: 0;
    }
  }

  .marginBottom {
    margin-bottom: em(10);

    @media $fromMobile {
      align-items: flex-end;
      margin-bottom: em(20);
    }

    @media $desktop {
      margin-bottom: 0;
    }
  }

  .detailsContainerCentered {
    @include align-center;

    top: 0;
    bottom: 0;
    justify-content: center;
    width: 100%;
    padding-bottom: 0;
    padding-left: 0;
  }

  .adContainer {
    position: absolute;
    bottom: rem(16);
    right: 3%;
  }
}
