@import 'import.pcss';

:local {
  .displayAd {
    text-align: center;
    padding: 0 rem(15);

    &Infopanel {
      @media $desktop {
        padding-left: em(30);
      }
    }
  }
}
