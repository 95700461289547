@import 'import.pcss';

:local {
  .showDetailsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: rem(10);
    line-height: 1.8;

    .separator {
      color: $c-ee-white-03;
      margin: 0 rem(9);
    }
    .separatorExpires {
      color: $c-ee-white-03;
      margin: 0 rem(9) 0 rem(5);
    }

    .bullet {
      margin-right: rem(5);
      color: $c-ee-white-08;
    }

    .ccImage {

      margin-right: rem(9);
    }

    .detailWrapper {
      &.classification {
        line-height: normal;
        padding: rem(3) rem(6);
        margin-right: rem(10);
        border-radius: 1.5px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.07) 100%);
      }
    }

    .detail {
      line-height: 0;
      font-weight: $fw-500;

      &.classification {
        font-weight: $fw-700;
        font-size: rem(9);
      }
    }

    .expiresOn {
      color: $c-red-1;
    }
  }
}
