@import 'import.pcss';

:local {
  .watchlistContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: rem(5);
    cursor: pointer;
  }

  .overrideMarginTop {
    margin-top: 0;
  }

  .title {
    height: rem(21);
    padding-left: rem(6);
    font-family: $ff-content;
    font-size: rem(13);
    font-weight: $fw-500;
    line-height: rem(21);
    color: $c-dt-lightestgrey;
    transition: color .2s ease-in-out;

    @media $fromTablet {
      height: rem(28);
      font-size: rem(14);
      line-height: rem(28);
    }
  }

  .watchListIcon {
    width: rem(26);
    height: rem(26);
    transition: .2s ease-in-out;
  }

  .added {
    background: no-repeat url('./icons/addedNotHovered.svg');

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: no-repeat url('./icons/addedHovered.svg');
      }
    }
  }

  .notAdded {
    background: no-repeat url('./icons/notAddedNotHovered.svg');

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: no-repeat url('./icons/notAddedHovered.svg');
      }
    }
  }

  .addedEe {
    background: no-repeat url('./icons/addedNotHoveredEE.svg');
  }

  .watchlistContainer:hover .watchListIcon.addedEe  {
    background: no-repeat url('./icons/addedHoveredEE.svg');
  }

  .notAddedEe {
    background: no-repeat url('./icons/notAddedNotHoveredEE.svg');
  }
  
  .watchlistContainer:hover .watchListIcon.notAddedEe  {
    background: no-repeat url('./icons/notAddedHoveredEE.svg');
  }

  .watchlistContainer:hover .title  {
    color: $c-white;
  }

}

