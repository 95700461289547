@import 'import.pcss';

:local {
  .SignInWidget {
    display: flex;
    background-color: transparent;
    max-width: rem(260);
    margin: rem(12) rem(12) rem(15) rem(12);
    margin-right: $mobileComponentsHorizontalSpace;

    @media $desktop {
      margin: rem(10) 0;
      margin-right: $headerContainerHorizontalSpace;
    }

    @media $desktopM {
      margin-right: $headerContainerHorizontalSpace;
    }

    &__dropDownArrow {
      width: rem(10);
      height: rem(10);
      margin-right: rem(15);
      cursor: pointer;
      transition: transform 0.2s ease;

      &--down {
        transform: rotate(90deg);
      }

      &--up {
        transform: rotate(270deg);
      }

      @media $fromMobile {
        margin-right: rem(30);
      }
    }

    &__buttonContainer {
      display: flex;

      @media $desktop {
        margin: auto;
        flex-direction: row-reverse;
      }

      & a,
      button {
        font-size: rem(12);
        font-weight: $fw-600;
        flex: 1 0 auto;
        line-height: rem(14);
        padding: rem(12);

        // IE11 specific media query
        @media all and (-ms-high-contrast: none) {
          flex: 1 0 auto;
        }
      }

      & a:not(:last-of-type),
      button:not(:last-of-type) {
        margin-right: rem(5);

        @media $desktop {
          // flipping the logic here as the button container is
          // using { flex-direction: row-reverse } in for desktop
          margin-right: 0;
          margin-left: rem(5);
        }
      }

      .SignInWidget__signOutButton {
        color: rgba(255, 255, 255, 0.8);
        transition: color 0.3s ease-in-out;
        font-size: rem(12);

        &:hover {
          color: $c-white;
        }
      }
    }
    &__signInButton {
      transition: background-color 0.3s ease-in-out;
      &:hover {
        background-color: $c-red-new;
      }
    }
    &__registerButton {
      color: rgba(190, 0, 30, 0.8);
      transition: color 0.3s ease-in-out;
      &:hover {
        color: $c-red-new;
      }
    }
    &__connectButton {
      border: rem(1) solid rgba(255, 255, 255, 0.3);
      color: rgba(255, 255, 255, 0.8);
      transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
      margin-left: rem(5);

      &:hover {
        color: $c-white;
        border: rem(1) solid $c-white;
      }
    }

    &__accountButton {
      color: $c-ee-white-08;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $c-white;
        background-color: $c-red-new;

        .redImageButtonContent {
          img {
            opacity: 1;
          }
        }
      }

      .redImageButtonContent {
        display: flex;
        align-items: center;

        img {
          margin-right: rem(8.66);
          opacity: 0.8;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }
  }

  .signOutWrapper {
    display: flex;
    flex-direction: column;
    margin-left: rem(21);

    a {
      padding: rem(7) 0 0 0;
      text-align: end;
    }

    .firstName {
      color: $c-white;
      opacity: 0.8;
      font-size: rem(10);
      font-weight: 300;
      line-height: rem(13);
      text-align: end;
      white-space: nowrap;
      max-width: rem(45);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media $toDesktop {
      margin-right: rem(14);
      margin-left: rem(10);

      a {
        padding: rem(2) 0 0 0;
        text-align: start;
      }

      .firstName {
        padding-top: rem(6);
        text-align: start;
      }
    }
  }

  .SignInWidget__buttonContainer.SignInWidget__loggedIn > * {
    flex: none;
    &:last-child {
      @media $desktop {
        margin-right: rem(15);
      }
    }
  }
}
