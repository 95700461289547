@import 'import.pcss';

:local {
  .titleWithLogo {
    margin-bottom: rem(40);
  }

  .title {
    @extend %flex-center;
    color: $c-dt-lightestgrey;
    font-size: unset;
    font-weight: $fw-500;
  }

  .logo {
    width: rem(45);
    padding: 0 em(8);
  }
}
