@import 'import.pcss';

:local {
  .basicEditProfileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media $toMobile {
      width: 100%;
    }

    @media $toDesktop {
      align-items: start;
    }

    .title {
      padding-bottom: rem(30);
      text-align: center;

      @media $toDesktop {
        padding-top: rem(30);
      }
    }

    .genderTitle {
      margin: 0 0 rem(3) 0;
    }

    .genderErrorMessage {
      color: $c-dt-red-1;
      font-size: rem(10);
      padding-top: rem(6);
      text-align: start;
      font-weight: $fw-500;
    }
    .editProfileForm {
      width: 100%;
      max-width: rem(567);
    }
    .formWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: rem(10);
    }

    .editProfileInput {
      &.hintTextInput input {
        border-bottom: rem(1) solid $c-silver;
        &:focus {
          border-bottom: rem(1) solid $c-white;
        }
      }
    }

    .hintTextInput + span {
      color: $c-ee-white-08;
    }

    .errorInput {
      input {
        border-bottom: rem(1) solid $c-dt-border-error;
      }
    }

    .btnWrapper {
      display: flex;
      margin-top: rem(35);

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(46);
        width: 100%;

        @media $desktop {
          min-width: rem(180);
        }
      }
      .cancelWrapper {
        width: 100%;
        margin-right: rem(18);

        @media $toMobile {
          margin-right: rem(15);
        }
      }
      .submitWrapper {
        width: 100%;
        &.isSubmittingWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .saveDetailsButton {
        transition: all 0.3s ease-in-out;
        color: $c-ee-white-08;

        &[disabled] {
          border: 1px solid transparent;
          opacity: 0.4;
        }

        &:not([disabled]):hover {
          background-color: $c-red-new;
          color: $c-white;
        }
      }

      .cancelButton {
        &:hover {
          color: $c-red;
          transition: color 0.3s ease-in-out;
        }
      }
    }
  }
}
