@import 'import.pcss';

:local {
  .preConnectModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding-top: rem(40);
    background: rgba(0, 0, 0, 0.3);
    .logo {
      background-color: $c-red-new;
      width: rem(120);
      height: rem(120);
      border-radius: rem(12);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: rem(79);
      }
      margin-bottom: rem(40);
    }
    .title {
      font-size: rem(18);
      line-height: rem(21);
      color: $c-white;
      font-weight: $fw-500;
      margin-bottom: rem(40);
    }
    .description {
      font-size: rem(16);
      line-height: rem(20);
      color: $c-white;
      font-weight: $fw-300;
      margin-bottom: rem(50);
      max-width: 295px;
    }
    .appIcon {
      height: rem(60);
    }
    .continueWrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      padding-bottom: rem(30);
      .continueLink {
        color: $c-white;
        border-radius: rem(2);
        border: 1px solid $c-ee-white-06;
        font-size: rem(14);
        line-height: rem(18);
        font-weight: $fw-600;
        padding: rem(8) rem(12);
        text-decoration: none;
      }
      .continueDescription {
        font-size: rem(14);
        line-height: rem(17);
        color: $c-silver;
        margin-bottom: rem(20);
      }
    }
    @media $fromMobile and $toLargeTabletLandscape {
      padding-top: rem(137);
      justify-content: flex-start;

      .logo {
        margin-bottom: rem(60);
      }

      .description {
        max-width: rem(427);
        padding: rem(10) 0;
        margin-bottom: rem(35);
      }

      .continueWrap {
        flex: none;
        margin-top: rem(130);
      }
      @media (orientation: landscape) {
        padding-top: rem(60);
      }
    }
  }
}
