@import 'import.pcss';

:local {
  .subNavigation {
    position: fixed;
    top: rem(55);
    left: 0;
    z-index: 10;
    width: 100%;
    max-width: 100%;
    padding: rem(25) rem(78) rem(40);
    color: $c-dt-lightestgrey;
    background: $c-app-bg;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);

    @media $toDesktop {
      display: none;
    }

    @media $fromTablet {
      padding: rem(25) rem(75) rem(40);
    }
  }

  .subNavigationSection {
    + .subNavigationSection {
      margin-top: rem(23);
    }
  }

  .gridContainer {
    @media $toDesktop {
      overflow-x: auto;
      display: flex;
      scroll-behavior: smooth;
      margin: 0 0 0 rem(10);
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }

  .sectionItemsLiveWrapper {
    display: grid;
    grid-template-columns: repeat(4, 75%);
    grid-auto-flow: row;
    scroll-snap-type: x mandatory;
    grid-gap: rem(5);
    width: 100%;

    @media $desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @media $desktopM {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .sectionTitle {
    margin-bottom: rem(16);
    font-size: rem(13);
    font-weight: $fw-600;
    display: flex;
    justify-content: space-between;
    opacity: 0.6;
    color: $c-dt-lightestgrey;

    @media $toDesktop {
      line-height: rem(17);
      margin: rem(17) rem(11) rem(19);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .seeAllLink {
    color: $c-dt-lightgrey;
    font-weight: $fw-500;
    font-size: rem(13);
  }

  .seeAllArrow {
    width: rem(10);
    height: rem(10);
    cursor: pointer;
    margin-right: rem(15);
    transition: transform 0.2s ease;
    right: 100%;

    @media $fromMobile {
      margin-right: rem(30);
      margin-left: rem(4);
    }

    &--right {
      transform: rotate(45deg);
    }
  }

  .subNavigationItem {
    background: $c-dt-darkergrey;
    display: flex;
    align-items: center;
    text-align: center;
    padding: rem(9) rem(14);
    margin: 0 rem(5) rem(5) 0;
    height: rem(36);
    font-size: rem(11);
    font-weight: 500;
    border-radius: 1px;
    transition: background-color 0.2s;

    &:hover {
      background-color: $c-black;
      color: $c-white;

      .subNavigationTitle {
        opacity: 1;
      }
    }
  }

  .subNavigationTitle {
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
  }

  .container {
    margin-right: rem(11);
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    width: rem(32);
    margin-right: rem(10);
  }

  .icon {
    max-height: rem(18);
    height: rem(18);
    font-size: rem(13);

    @media $fromLargeTablet {
      margin-right: rem(14);
    }
  }

  .subNavigationDropdown > div {
    border-bottom: rem(1) solid $c-black;
    padding: rem(11);
    background-color: $c-dt-darkgrey-complementary;
    @media $toDesktop {
      padding-right: 0;
    }
  }

  .subNavigationDropdownSectionItems {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .dropdownItem {
    display: flex;
    padding: rem(16) rem(24);
    font-size: rem(14);
    font-weight: 500;
    line-height: rem(18);
    letter-spacing: rem(0.19);
    cursor: pointer;
    background-color: $c-dt-darkgrey;
    border-bottom: rem(1) solid $c-dt-darkgrey-complementary;
  }

  .dropdownItem:hover {
    background-color: $c-app-bg;
  }

  .sidebarTitle {
    font-size: rem(11);
    color: $c-dt-lightestgrey;
    line-height: rem(14);
  }

  .subLinkContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @media $toDesktop {
      padding: rem(5) rem(20) rem(20) rem(10);
    }
  }

  .subLink {
    font-size: rem(13);
    line-height: rem(16.51);
    margin: rem(20) 0 0;
    color: white;
    font-weight: 600;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    span {
      padding-right: rem(8);
    }

    img {
      transform: rotate(180deg);
      width: rem(10);
    }

    &:hover {
      opacity: 1;
    }
  }
}
