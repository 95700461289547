@import 'import.pcss';

:local {
  .helpSectionContent {
    display: flex;
    justify-content: space-between;

    @media $toDesktop {
      padding: rem(36) rem(19) rem(43) rem(20);
      flex-direction: column;
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media $toDesktop {
        align-items: center;
        flex-direction: row;
        justify-content: start;
      }

      .whiteBorderedButtonWrapper {
        @media $desktop {
          margin-bottom: rem(18);
        }

        @media $toDesktop {
          margin-right: rem(15);
        }
      }

      .contactUsButton {
        &:hover {
          color: $c-white;
          border: rem(1) solid $c-white;
        }
      }

      .visitKnowledgebaseButton {
        transition: all 0.3s ease-in-out;
        color: $c-ee-white-08;

        @media $toDesktop {
         padding-left: rem(12);
          padding-right: rem(12);
        }
        &:not([disabled]):hover {
          background-color: $c-red-new;
          color: $c-white;
        }
      }
    }

    .helpIcon {
      width: rem(41.42);
      height: rem(41.44);

      @media $toDesktop {
        width: rem(27.93);
        height: rem(27.93);
      }
    }
  }
}
