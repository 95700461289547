@import 'import.pcss';

:local {
  .headerTopOverlapLogoShadowWrap {
    position: absolute;
    width: 100%;
    height: rem(105);
    z-index: 1;
  }

  .headerTopOverlapLogoShadow {
    position: absolute;
    width: rem(207);
    height: rem(95);
    left: rem(-90);
    top: rem(-40);
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0.42) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    transform: matrix(-0.91, 0.45, -0.41, -0.9, 0, 0);
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }

  .headerTopOverlapShadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: rem(102);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.72) 100%
    );
    pointer-events: none;

    @media $fromTablet {
      height: rem(96);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.66) 100%
      );
    }
  }
}
