@import 'import.pcss';

:local {
  .card {
    position: relative;
    height: 100%;
    z-index: 0;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    &:hover > .contentWrapper {
      &::before {
      opacity: 0.6;
      background-color: $c-dt-darkergrey;
      }
      .button {
        .add {
          g {
            opacity: 1;
          }
        }
        .remove {
          path {
            opacity: 1;
          }
        }
      }
    }
  }

  .imageWrapper {
    position: relative;
    border-radius: 1px;
    overflow: hidden;
    pointer-events: auto;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $c-black;
      opacity: 0;

      &.overlayRemoved {
        opacity: 0.7;
      }
    }
  }

  .contentWrapper:has(.button:hover) {
    &:hover::before {
      opacity: 1;
      background-color: $c-ee-lightblack;
    } 
  }


  .contentWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: rem(40);
    border-radius: rem(1);

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: $c-ee-lightblack;
      transition: opacity 0.2s, background-color 0.2s;
    }

    @media $toMobile {
      height: rem(32);
    }
    
    .button {
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: rem(40);
      padding: 0;
      background: transparent;
      border: none;
      pointer-events: auto;

      @media $toMobile {
        width: rem(30);
        .add {
          width: rem(11);
        }
        .remove {
          width: rem(11);
        }
      }

      &:hover {
        .add {
          g {
            opacity: 1;
          }
        }
        .remove {
          path {
            opacity: 1;
          }
        }
      }
    }

    .titleWrapper {
      z-index: 1;
      width: 85%;
      padding-left: rem(18);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $c-ee-white-06;
      font-size: rem(12);
      font-weight: $fw-500;


      .genreWithBullet:before {
        content: "";
        width: rem(4);
        height: rem(4);
        border-radius: 50%;
        margin: 0 rem(8) rem(2) rem(8);
        display: inline-block;
        background-color: $c-ee-white-06;
    }

      @media $toMobile {
        padding-left: rem(12);
      }

      .bullet {
        padding: 0 rem(6) 0 rem(9);

        @media $toMobile {
          padding: 0 rem(4) 0 rem(7);
        }
      }

      .title {
        opacity: 0.6;
        font-size: rem(12);
      }
    }
  }
}
