@import 'import.pcss';

:local {
  .card-in-grid {
    margin-bottom: em(6);

    @media

    $fromMobile {
      padding: 0 em(3);
      margin-bottom: rem(6);
    }
  }

  .cardWrapper {
    padding: rem(0);
    padding-left: rem(3);

    &.firstItem {
      padding-left: rem(0);
    }

    .card {
      color: rgba(255,255,255,0.6);
      background: $c-slate2;
      font-weight: 600;
      font-size: rem(13);
      line-height: rem(36);
      text-decoration: none;
      display: flex;
      align-items: center;
      text-align: center;
      padding: rem(0) rem(24);
      border-radius: rem(1);
      transition: .2s background-color, .2s color;
    }
  
    .card:hover {
      background-color: $rosewood;
      color: rgba(255,255,255,1);
    }
  }
}
