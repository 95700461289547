@import 'import.pcss';

:local {
  .wrapperAllLogo {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: rem(142);

    @media $toDesktop {
      width: rem(91);
    }
    .logoLink {
      z-index: 2;
      cursor: pointer;
      .logoWrapper {
        height: rem(95);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(6);
        img {
          position: absolute;
          max-width: rem(90);
          max-height: rem(45);

          @media $toDesktop {
            max-width: rem(64);
            max-height: rem(32);
          }
        }
        @media $toDesktop {
          height: rem(67);
        }
      }
    }
  }
  .channelItemSwiper {
    width: calc(100% - 142px);
    @media $toDesktop {
      width: calc(100% - 91px);
    }
    @media $toTablet {
      width: auto;
    }
    &.channelItemSwiperInSwitch {
      @media $tablet {
        width: 100%;
      }
    }
  }
  .channelItemWrapper {
    margin-left: rem(142);
    display: flex;
    flex-direction: row;
    width: 100%;

    @media $toDesktop {
      margin-left: rem(91);
    }
    @media $toTablet {
      width: rem(780);
    }
    @media $toMobile {
      width: rem(646);
    }

    .channelCardWrapper {
      width: 100%;
      height: auto;
      margin-bottom: rem(6);
      padding-right: rem(5);
    }
    .channelCardWrapper:first-child {
      width: 126%;
      @media $toDesktop {
        width: 100%;
      }
    }
    .channelCardWrapper:last-child {
      padding-right: 0;
    }

    &.channelItemWrapperInSwitch {
      @media $toMobile {
        width: 30rem;
      }
      .channelCardWrapper {
        width: 50%;

        &:first-child {
          @media $tablet {
            width: calc(50% - rem(94));
          }
          @media $desktop {
            width: calc(50% - rem(145));
          }
        }
      }
    }
  }
}



