@import 'import.pcss';

:local {
  .container {
    display: flex;
    flex-direction: column;
  }
  .title {
    text-align: center;
    padding-bottom: rem(46);
    padding-top: rem(9);

    @media $toMobile {
      padding-bottom: rem(61);
      padding-top: rem(14);
    }
  }

  .footer {
    margin-top: rem(26);
    margin-bottom: rem(49);
    a {
      font-size: rem(14);
      color: $c-dt-lightestgrey;
      transition: color 0.1s ease-in-out;

      &:hover {
        color: $c-white;
      }
    }
  }
}
