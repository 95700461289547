@import 'import.pcss';

:local {
  .arrivingDateWrapper {
    color: $c-white;
    font-size: rem(18);
    font-weight: $fw-300;

    .arrivingText {
      opacity: 0.6;
    }
  }
}
