@import 'import.pcss';

:local {
    .UpcomingShelf {
        padding: 0;
    
        :global {
          .swiper-container {
            overflow: visible;
          }
        }
      }
    
      .carousel {
        position: relative;
        padding: 0;
    
        @media $toTablet {
          &:first-of-type {
            padding-right: $mobileComponentsHorizontalSpace;
          }
        }
      }
}
