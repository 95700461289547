@import 'import.pcss';

/* Override styling for different themed tabs */
@import './Themes/RedTab.pcss';

:local {
  .scrollerContainer {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .scroller {
    -ms-overflow-style: none;
    display: flex;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    & > * {
      overflow-x: unset !important;
      padding: 0;
      background: none;
    }
  }

  .arrowContainer {
    position: absolute;
    z-index: 1;
    cursor: pointer;

    width: rem(52);

    &.disabled {
      pointer-events: none;
      background: none;
      opacity: 0;
    }

    &Left {
      top: 0;
      bottom: 0;
      left: -1.6px;
    }

    &Right {
      top: 0;
      right: -1.6px;
      bottom: 0;
    }

    @media $toMobile {
      display: none;
    }

    :global {
      .swiperButtonContainerNext {
        right: 0;
      }
      .swiperButtonContainerPrevious {
        left: 0;
      }
    }

    .arrowRight,
    .arrowLeft {
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      background: transparent;
    }
  }

  .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    width: auto;
    height: 100%;
    padding-left: 0.75rem;
    background: $c-black;

    &Left {
      left: 0;

      .arrowImage {
        transform: rotate(180deg);
      }
    }

    &Right {
      right: 0;

      .arrowImage {
        transform: rotate(0deg);
      }
    }

    @media (pointer: fine) {
      display: flex;
      align-items: center;
    }
  }
}
